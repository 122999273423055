import { UserManager, WebStorageStateStore } from 'oidc-client';
import {
    OAUTH_AUTHORITY_URL,
    OAUTH_CLIENT_ID, OAUTH_REDIRECT_URL, VUE_BASE_URL,
} from "@/environment/environment";

export default class OpenAuthService {
    userManager;

    constructor() {
        const settings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: OAUTH_AUTHORITY_URL,
            client_id: OAUTH_CLIENT_ID,
            redirect_uri: OAUTH_REDIRECT_URL,
            automaticSilentRenew: true,
            response_type: 'code', //id_token token
            scope: 'openid offline',
            post_logout_redirect_uri: VUE_BASE_URL,
            filterProtocolClaims: true,
        };

        this.userManager = new UserManager(settings);
    }

    getUser() {
        return this.userManager.getUser();
    }

    login() {
        return this.userManager.signinRedirect();
    }

    logout() {
        return this.userManager.signoutRedirect();
    }

    getAccessToken() {
        return this.userManager.getUser().then((data) => {
            return data.access_token;
        });
    }
}
