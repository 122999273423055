<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <div class="flex gap-4 flex-wrap my-7" v-if="downXl">
      <content-wrapper v-for="(type, key) in participantTypes" :key="key">
        <div class="flex flex-col">
          <div class="text-2xl font-semibold truncate text-gray-500 mb-6">
            <span v-if="getLang === 'TR'">
              {{ type.participantType }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ type.participantTypeEn }}
            </span>
          </div>
          <div class="flex flex-col mb-4">
            <label v-for="item in type.items" :key="item" :for="`participant-${item.id}`"
              class="relative border-2 border-blue-600 rounded-xl p-4 lg:p-6 flex flex-col lg:flex-row items-center justify-between mb-4 last:mb-0">
              <div class="absolute lg:relative left-3 lg:left-0 top-0 bottom-0 flex items-center">
                <div class="flex items-center">
                  <input :id="`participant-${item.id}`" type="radio" :value="item.id" name="list"
                    @change="changePackage($event, item)"
                    class="radio-package w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                </div>
              </div>
              <div class="flex flex-col flex-1 ml-4 font-normal truncate text-gray-500">
                <span class="text-base lg:text-lg" v-if="getLang === 'TR'">
                  {{ item.name }}
                </span>
                <span class="text-base lg:text-lg" v-if="getLang === 'EN'">
                  {{ item.nameEn }}
                </span>
                <div class="text-base" v-if="getLang === 'TR'" v-html="item.description"></div>
                <div class="text-base" v-if="getLang === 'EN'" v-html="item.descriptionEn"></div>
                <div class="text-xl font-extrabold text-gray-900">
                  {{
                    toCurrency(
                      item.price,
                      "tr-TR",
                      "currency",
                      getCongressProductCurrency(congress.productCurrency)
                    )
                  }}
                </div>
                <div class="text-xs font-semibold text-gray-900" v-if="item.vatRate != 0">
                  ({{ $t("user.congress.vatRate", { value: item.vatRate }) }})
                </div>
                <span class="text-xs lg:text-sm">
                  {{ $t("user.congress.lastRegistrationDate") }} :
                  {{ moment(item.lastRegistrationDate).format("DD.MM.YYYY") }}
                </span>
              </div>
              <div class="flex flex-col items-end">
                <Popper :hover="true" :arrow="true" placement="top">
                  <button>
                    <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                  </button>
                  <template #content>
                    <p class="text-xs lg:text-sm">
                      {{ $t("user.congress.checkInDate") }} :
                      {{ moment(item.startDate).format("DD.MM.YYYY") }}
                    </p>
                    <p class="text-xs lg:text-sm">
                      {{ $t("user.congress.checkOutDate") }} :
                      {{ moment(item.endDate).format("DD.MM.YYYY") }}
                    </p>
                  </template>
                </Popper>
              </div>
            </label>
          </div>
        </div>
        <div>

          <div v-if="this.selectedId == undefined">
            <button-with-loader :disabled="true" @click="checkPricePerDay()" :isLoading="isLoading" type="button"
              class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-gray-200">
              <PlusIcon class="h-5 w-5 text-white font-bold" />
              {{ $t('shared.choosePlan') }}
            </button-with-loader>
          </div>
          <div v-else>
            <button-with-loader :disabled="!(type.items.some(s => s.id == this.selectedId))" @click="checkPricePerDay()"
              :isLoading="isLoading" type="button"
              class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-yellow-400 hover:bg-yellow-700 focus:ring-blue-300 disabled:bg-gray-200">
              <PencilIcon class="h-5 w-5 text-white font-bold" />
              {{ $t('shared.changePlan') }}
            </button-with-loader>
          </div>

        </div>
      </content-wrapper>
    </div>
    <div class="my-7 w-full" v-else>
      <carousel :items-to-show="1" :wrapAround="true">
        <slide v-for="(type, key) in participantTypes" :key="key">
          <div
            class="plan-group p-8 h-full flex bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
              <div class="text-2xl font-semibold text-gray-500 mb-6">
                <span v-if="getLang === 'TR'">
                  {{ type.participantType }}
                </span>
                <span v-if="getLang === 'EN'">
                  {{ type.participantTypeEn }}
                </span>
              </div>
              <div class="flex flex-col mb-4">
                <label v-for="item in type.items" :key="item" :for="`participant-${item.id}`"
                  class="relative border-2 border-blue-600 rounded-xl p-4 lg:p-6 flex flex-col lg:flex-row items-center justify-between mb-4 last:mb-0">
                  <div class="absolute lg:relative left-3 lg:left-0 top-0 bottom-0 flex items-center">
                    <div class="flex items-center">
                      <input :id="`participant-${item.id}`" type="radio" :value="item.id" name="list" @change="
                        changePackage($event, item)
                        "
                        class="radio-package w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    </div>
                  </div>
                  <div class="flex flex-col flex-1 ml-4 font-normal text-gray-500">
                    <span class="text-base lg:text-lg" v-if="getLang === 'TR'">
                      {{ item.name }}
                    </span>
                    <span class="text-base lg:text-lg" v-if="getLang === 'EN'">
                      {{ item.nameEn }}
                    </span>
                    <div class="text-base" v-if="getLang === 'TR'" v-html="item.description"></div>
                    <div class="text-base" v-if="getLang === 'EN'" v-html="item.descriptionEn"></div>
                    <div class="text-xl font-extrabold text-gray-900">
                      {{
                        toCurrency(
                          item.price,
                          "tr-TR",
                          "currency",
                          getCongressProductCurrency(congress.productCurrency)
                        )
                      }}
                    </div>
                    <div class="text-xs font-semibold text-gray-900" v-if="item.vatRate != 0">
                      ({{
                        $t("user.congress.vatRate", { value: item.vatRate })
                      }})
                    </div>
                    <span class="text-xs lg:text-sm">
                      {{ $t("user.congress.lastRegistrationDate") }} :
                      {{
                        moment(item.lastRegistrationDate).format("DD.MM.YYYY")
                      }}
                    </span>
                  </div>
                  <div class="flex flex-col items-end">
                    <Popper :hover="true" :arrow="true" placement="top">
                      <button>
                        <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                      </button>
                      <template #content>
                        <p class="text-xs lg:text-sm">
                          {{ $t("user.congress.checkInDate") }} :
                          {{ moment(item.startDate).format("DD.MM.YYYY") }}
                        </p>
                        <p class="text-xs lg:text-sm">
                          {{ $t("user.congress.checkOutDate") }} :
                          {{ moment(item.endDate).format("DD.MM.YYYY") }}
                        </p>
                      </template>
                    </Popper>
                  </div>
                </label>
              </div>

              <div v-if="this.selectedId == undefined">
                <button-with-loader :disabled="true" @click="checkPricePerDay()" :isLoading="isLoading" type="button"
                  class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 disabled:bg-gray-200">
                  <PlusIcon class="h-5 w-5 text-white font-bold" />
                  {{ $t('shared.choosePlan') }}
                </button-with-loader>
              </div>
              <div v-else>
                <button-with-loader :disabled="!(type.items.some(s => s.id == this.selectedId))"
                  @click="checkPricePerDay()" :isLoading="isLoading" type="button"
                  class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-yellow-400 hover:bg-yellow-700 focus:ring-blue-300 disabled:bg-gray-200">
                  <PencilIcon class="h-5 w-5 text-white font-bold" />
                  {{ $t('shared.changePlan') }}
                </button-with-loader>
              </div>

            </div>
          </div>
        </slide>

        <template #addons>
          <pagination />
        </template>
      </carousel>
    </div>
    <div class="flex flex-col p-4">
      <div v-if="getLang === 'TR'" v-html="congress.accommodationDesc"></div>
      <div v-if="getLang === 'EN'" v-html="congress.accommodationDescEn"></div>
    </div>
  </div>

  <div id="edit-congress-detail-modal" tabindex="-1" aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <!-- Modal content -->
      <div class="modal-scroll relative bg-white rounded-lg shadow h-full overflow-y-auto dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
            {{ $t("admin.congressDetail.selectBookingDates") }}
          </h3>
          <button type="button" @click="closeModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
            <XMarkIcon class="w-5 h-5" />
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6">

          <div v-if="item != null">
            <div class="lg:w-full lg:flex-grow space-y-6 lg:space-y-0 pb-2 pr-2">
              <label for="accommodationNote" class="block mb-2 text-sm font-medium text-gray-900">
                {{ $t('shared.notes') }}
              </label>
              <input type="text" v-model="item.accommodationNote"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
            </div>

            <hr class="mt-2 mb-4">
          </div>


          <div v-if="this.item && this.item.pricePerDay != null"
            class="flex flex-col lg:flex-row space-y-6 lg:space-y-0">
            <div class="lg:w-1/2 lg:flex-grow">
              <DatePicker name="startDate" v-model="bookPickedDates" format="yyyy-MM-dd" locale="tr" min-range="1"
                :enableTimePicker="false" :allowed-dates="availDates()" :partial-range="false" autoApply inline range
                noToday @internal-model-change="handleInternal" @update:model-value="handleDateChanges" />
            </div>

            <div class="lg:w-1/2 lg:flex-grow">
              <p class="text-xl font-extrabold text-gray-900 mb-6">
              <div class="text-xs font-semibold text-gray-900">
                <p class="text-xs font-extrabold text-gray-900">
                  {{ $t("user.congress.startDate") }}
                  <span v-if="this.firstDate">
                    {{ moment(this.firstDate).format("DD.MM.YYYY") }}
                  </span>
                </p>
                <p class="text-xs font-extrabold text-gray-900" v-if="this.item.mandatoryStartDate">
                  {{ $t("user.congress.mandatoryStartDate") }}
                  <span>
                    {{ moment(this.item.mandatoryStartDate).format("DD.MM.YYYY") }}
                  </span>
                </p>
                <p class="text-xs font-extrabold text-gray-900" v-if="this.item.mandatoryEndDate">
                  {{ $t("user.congress.mandatoryEndDate") }}
                  <span>
                    {{ moment(this.item.mandatoryEndDate).format("DD.MM.YYYY") }}
                  </span>
                </p>
                <p class="text-xs font-extrabold text-gray-900">
                  {{ $t("user.congress.endDate") }}
                  <span v-if="this.lastDate">
                    {{ moment(this.lastDate).format("DD.MM.YYYY") }}
                  </span>
                </p>
              </div>
              </p>
              <p class="text-xs font-extrabold text-gray-900">
                {{ $t("admin.congressDetail.pricePerDay") }}
              </p>
              <p class="text-xl font-extrabold text-gray-900 mb-6">
                {{
                  toCurrency(
                    item.pricePerDay,
                    "tr-TR",
                    "currency",
                    getCongressProductCurrency(congress.productCurrency)
                  )
                }}
              <div class="text-xs font-semibold text-gray-900" v-if="item.vatRate != 0">
                ({{ $t("user.congress.vatRate", { value: item.vatRate }) }})
              </div>
              </p>
              <p class="text-xs font-extrabold text-gray-900">
                {{ $t("user.basket.totalPrice") }}
              </p>
              <p class="text-xl font-extrabold text-gray-900 mb-6">
                {{
                  toCurrency(
                    pickedPrice,
                    "tr-TR",
                    "currency",
                    getCongressProductCurrency(congress.productCurrency)
                  )
                }}
              <div class="text-xs font-semibold text-gray-900" v-if="item.vatRate != 0">
                ({{ $t("user.congress.vatRate", { value: item.vatRate }) }})
              </div>
              </p>


              <button v-if="bookingValid" @click="dispatchBasketWithBookDates()"
                class="inline-flex gap-1 items-center justify-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                <span role="status" class="flex justify-center">
                  {{ $t("user.congress.addToCart") }}
                </span>
              </button>
            </div>
          </div>
          <div v-else>
            <button @click="dispatchBasketModal()"
              class="inline-flex gap-1 items-center justify-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              <span role="status" class="flex justify-center">
                {{ $t("user.congress.addToCart") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ButtonWithLoader, ContentWrapper } from "@/components";
import { useMediaQuery } from "@/common/hooks";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { mapGetters } from "vuex";
import { PlusIcon, PencilIcon, InformationCircleIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import Popper from "vue3-popper";
import { toCurrency } from "@/common/utils";
import DatePicker from "@vuepic/vue-datepicker";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export default {
  name: "AccommodationTab",
  components: {
    ButtonWithLoader,
    ContentWrapper,
    Carousel,
    DatePicker,
    Slide,
    Pagination,
    PlusIcon,
    PencilIcon,
    XMarkIcon,
    InformationCircleIcon,
    Popper,
  },
  setup() {
    const { downXl } = useMediaQuery();
    const bookPickedDates = ref();
    const toast = useToast();
    return { downXl, bookPickedDates, toast };
  },
  data() {
    return {
      moment: moment,
      isLoading: false,
      isChecked: false,
      congressDetailId: null,
      item: null,
      modal: null,
      bookDates: null,
      pickedPrice: null,
      bookingValid: false,
      firstDate: null,
      lastDate: null,
      mandatoryStartDate: null,
      mandatoryEndDate: null,
      selectedId: null,
    };
  },
  props: {
    participantTypes: Array,
    dispatchBasket: Function,
    congress: {},
    selectedAccount: Object,
  },
  methods: {
    getDatesInRange(startDate, endDate) {
      let datesArray = [];
      let currentDate = new Date(startDate);
      let currentEndDate = new Date(endDate);
      while (currentDate <= currentEndDate) {
        datesArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return datesArray;
    },
    initBookDates() {
      const modalEl = document.getElementById("edit-congress-detail-modal");
      const modalOptions = {
        placement: "center-center",
        backdropClasses:
          "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        onShow: () => {
          document.querySelector(
            "#edit-congress-detail-modal .modal-scroll"
          ).scrollTop = 0;
        },
      };
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions);
      }
    },
    closeModal() {
      this.modal.hide();
    },
    availDates() {
      var startDate = this.item.startDate;
      var endDate = this.item.endDate;
      var dateRanges = this.getDatesInRange(startDate, endDate);
      return dateRanges;
    },
    handleInternal(dates) {
      this.bookingValid = dates != null && dates.length == 2;
      if (!this.bookingValid) this.pickedPrice = 0;
      //console.log(`Current selection - ${dates} ${this.bookingValid}`);
    },
    handleDateChanges(modelData) {
      //console.log(modelData);
      this.firstDate = modelData[0];
      this.lastDate = modelData[1];
      var range = this.getDatesInRange(this.firstDate, this.lastDate);
      var _availDatesLength = this.availDates().length;
      if (range.length == _availDatesLength) {
        this.pickedPrice = this.item.price;
      }
      else {
        this.pickedPrice = this.item.pricePerDay * (range.length - 1);
      }
      this.bookDates = range;

      //console.log(this.bookDates);
      //console.log(range);
    },
    checkPricePerDay() {
      this.modal.show();
      return false;
    },
    dispatchBasketWithBookDates() {

      //console.log(this.firstDate);
      //console.log(this.lastDate );
      //console.log(this.bookDates);

      var mandatoryStatus = this.checkMandatoryDates();
      if (!mandatoryStatus) {
        return;
      }

      this.dispatchBasket(this.item.id, this.item.accommodationNote, this.bookDates);
      this.modal.hide();

      return;
    },
    dispatchBasketModal() {
      this.dispatchBasket(this.item.id, this.item.accommodationNote, null);
      this.modal.hide();
    },
    checkMandatoryDates() {
      if (this.item.mandatoryStartDate != null && this.item.mandatoryEndDate != null) {
        let startFound = false;
        let endFound = false;

        let mandatoryStartDate = new Date(this.item.mandatoryStartDate);
        let mandatoryEndDate = new Date(this.item.mandatoryEndDate);

        for (var i = 0; i < this.bookDates.length; i++) {
          if (this.bookDates[i].getDate() == mandatoryStartDate.getDate()) {
            startFound = true;
          }
          if (this.bookDates[i].getDate() == mandatoryEndDate.getDate()) {
            endFound = true;
          }
        }

        if (!startFound) {
          this.toast.error(this.$t("user.congress.mandatoryStartDateError"));
          return false;
        }

        if (!endFound) {
          this.toast.error(this.$t("user.congress.mandatoryEndDateError"));
          return false;
        }
      }

      return true;
    },
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    changePackage($event, item) {
      Array.from(document.querySelectorAll(".radio-package")).forEach((el) =>
        el.closest("label").classList.remove("bg-blue-100")
      );
      Array.from(document.querySelectorAll("button.plan-selector")).forEach((el) => {
        el.disabled = true;
      }
      );
      $event.target.closest("label").classList.add("bg-blue-100");
      $event.target.closest('.plan-group').querySelector('button.plan-selector').disabled = false;
      if (item) {
        this.congressDetailId = item.id;
        this.item = item;
        this.bookPickedDates = [this.item.startDate, this.item.endDate];
        this.handleDateChanges(this.bookPickedDates);
      }
    },
    changePackageFromBasket() {
      let accountEmail = this.selectedAccount.email;
      let found = false;

      this.getBasket.items.some(item => {
        if (item.productType === 3 && item.email === accountEmail) {
          this.selectedId = item.productId;
          return true;
        }
        return false;
      });

      if (this.selectedId !== undefined) {
        found = this.participantTypes.some(type =>
          type.items.some(item => item.id === this.selectedId)
        );
      }

      if (!found) {
        return;
      }

      document.querySelectorAll(".radio-package").forEach(el =>
        el.closest("label").classList.remove("bg-blue-100")
      );

      document.querySelectorAll("button.plan-selector").forEach(el => {
        el.disabled = true;
      });

      const radioBtn = document.querySelector(`#participant-${this.selectedId}`);
      if (radioBtn) {
        const label = radioBtn.closest("label");
        const planGroup = radioBtn.closest(".plan-group");
        const button = planGroup.querySelector("button.plan-selector");

        if (label) label.classList.add("bg-blue-100");
        if (button) button.disabled = false;
        radioBtn.checked = true;
      }

      this.congressDetailId = this.selectedId;
    },
    toCurrency,
  },
  mounted() {
    this.initBookDates();
    this.changePackageFromBasket();
  },
  computed: {
    ...mapGetters(['getLang', 'getBasket']),
  },
};
</script>

<style>
.carousel__pagination-button {
  @apply bg-blue-100 !important;
}

.carousel__pagination-button--active {
  @apply bg-blue-500 !important;
}
</style>
