<template>
  <div class="order-completed">
    <wrapper-with-loader :isLoading="isLoading">
      <template v-if="order.items.length">
        <step-wizard :step="Number(3)" :stepList="stepList" />
        <div class="flex flex-col gap-2 items-center mb-12">
          <CheckCircleIcon class="w-16 h-16 text-green-500" />
          <h2 v-if="order.paymentType === 1" class="text-2xl">
            {{ $t('user.orderCompleted.successMessage') }}
          </h2>
          <h2 v-else class="text-2xl">
            {{ $t('user.orderCompleted.successMessageTransfer') }}
          </h2>
          <p v-if="order.paymentType === 2">{{ $t('user.orderCompleted.transferPaymentInformationForSuccessPage')}}</p>
          <router-link :to="{ name: 'userOrder' }" class="underline">
            {{ $t('user.orderCompleted.goToOrder') }}
          </router-link>
        </div>
        <div class="flex flex-col">
          <content-wrapper>
            <div v-if="downXl" class="w-full">
              <order-completed-table-list :order="order" />
            </div>
            <div v-else>
              <order-completed-accordion-list :order="order" />
            </div>
          </content-wrapper>
          <order-completed-summary :order="order" />
        </div>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import {
  useResponseToast,
  useToast,
  useMediaQuery,
  useMeta,
} from '@/common/hooks'
import { WrapperWithLoader, ContentWrapper, StepWizard } from '@/components'
import OrderCompletedAccordionList from './OrderCompletedAccordionList.vue'
import OrderCompletedSummary from './OrderCompletedSummary.vue'
import OrderCompletedTableList from './OrderCompletedTableList.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { service } from '@/common/services'

export default {
  name: 'OrderCompletedPage',
  components: {
    WrapperWithLoader,
    ContentWrapper,
    StepWizard,
    CheckCircleIcon,
    OrderCompletedAccordionList,
    OrderCompletedSummary,
    OrderCompletedTableList,
  },
  data() {
    return {
      isLoading: true,
      stepList: [
        {
          key: 1,
          value: this.$t('shared.basket'),
        },
        {
          key: 2,
          value: this.$t('shared.payment'),
        },
        {
          key: 3,
          value: this.$t('shared.finish'),
        },
      ],
      order: null,
      orderId: this.$route.params.id,
    }
  },
  setup() {
    const { downXl } = useMediaQuery()
    const toast = useToast()
    return { toast, downXl }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.orderCompleted'),
      })),
    )
  },
  mounted() {
    this.fetchOrderForSuccess()
  },
  watch: {
    order: function (data) {
      return data
    },
  },
  methods: {
    fetchOrderForSuccess() {
      service
        .fetch(`order/${this.orderId}/for-success`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.order = response
            },
            errorCallback: () => {},
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.order-completed {
  @apply h-full;
}
</style>
