<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead
      class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
    >
      <tr>
        <th scope="col" class="p-4">
          {{ $t('user.basket.productType') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('user.basket.name') }} -  {{ $t('user.basket.participantType') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('user.order.orderNote') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('user.basket.price') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('user.basket.vat') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('user.basket.vatRatio') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('user.basket.startDate') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('user.basket.endDate') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in orderDetail.items"
        :key="item.id"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td class="p-4 font-medium text-gray-900 dark:text-white">
          <p class="truncate">
            {{ $t(`shared.${item.productType.toLowerCase()}`) }} -
            {{ item.accountName }}
          </p>
        </td>
        <td class="p-4 font-medium text-gray-900 dark:text-white">
          <p class="truncate"  :title="`${getLang === 'TR' ? `${item.congressDetailName} - ${item.participantType}`:`${item.congressDetailNameEn} - ${item.participantTypeEn}`}`">
            <span v-if="getLang === 'TR'">
              {{ item.congressDetailName }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.congressDetailNameEn }}
            </span>
            <span> - </span>
            <span v-if="getLang === 'TR'">
              {{ item.participantType }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.participantTypeEn }}
            </span>
          </p>
        </td>

        <th class="p-4">{{ item.notes }}</th>
        <th class="p-4">{{ toCurrency(item.price) }}</th>
        <th class="p-4">{{ toCurrency(item.vat) }}</th>
        <th class="p-4">%{{ item.vatRatio }}</th>
        <th class="p-4 whitespace-nowrap">
          {{ moment(item.startDate).format('DD.MM.YYYY') }}
        </th>
        <th class="p-4 whitespace-nowrap">
          {{ moment(item.endDate).format('DD.MM.YYYY') }}
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { toCurrency } from '@/common/utils'

export default {
  name: 'OrderDetailTableList',
  data() {
    return {
      moment: moment,
    }
  },
  props: {
    orderDetail: Object,
  },
  methods:{
    toCurrency
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>
