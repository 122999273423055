<template>
  <div class="congress-edit">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list
        :breadcrumbList="breadcrumbList"
        :breadcrumbBack="breadcrumbBack"
      />
      <page-title :pageTitle="pageTitle" />
      <content-wrapper>
        <div class="text-xl font-bold mb-6">
          {{ $t('admin.congressEdit.congressAdd') }}
        </div>
        <congress-contents-form :propFormData="formData" />
      </content-wrapper>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { mapGetters } from 'vuex'
import { service } from '@/common/services'
import { useResponseToast,useMeta } from '@/common/hooks'
import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  ContentWrapper,
} from '@/components'
import CongressContentsForm from './CongressContentsForm.vue'

export default {
  name: 'CongressContents',
  components: {
    BreadcrumbList,
    PageTitle,
    CongressContentsForm,
    WrapperWithLoader,
    ContentWrapper,
  },
  data() {
    return {
      formData: {
        startDate: null,
        endDate: null,
      },
      countryList: [],
      breadcrumbList: [{ name: this.$t('sidebar.congress'), url: 'congress' }],
      breadcrumbBack: true,
      pageTitle: this.$t('sidebar.congress'),
      isLoading: true,
      congressId: this.$route.params.id,
    }
  },
  beforeMount() {
    if (this.congressId) {
      this.fetchCongressById()
    } else {
      this.isLoading = false
    }
  },
  created() {
    useMeta(
      computed(() => ({
        title: `${this.$t('metaTitle.congressContents')} - ${
          this.getLang === 'TR'
            ? this.formData?.name
            : this.formData?.nameEn
        }`,
      })),
    )
  },
  methods: {
    fetchCongressById() {
      service
        .fetch(`congress/${this.congressId}`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.formData = response
              this.breadcrumbList.push({ name: response.name })
            },
            errorCallback: () => {},
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.congress-edit {
  @apply h-full;
}
</style>
