import axios from "axios";
import { useToast } from "vue-toastification";
import {authService} from "@/common/services";
import router from "@/router";

const setupInterceptors = () => {
  const toast = useToast();
  axios.interceptors.request.use(
    (config) => {
      if (authService.accountCurrentValue()?.token) {
        config.headers.Authorization = "Bearer ".concat(
          authService.accountCurrentValue().token
        );
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(null, (res) => {
    if (res.response.status === 401) {
      localStorage.clear();
      toast.error("Unauthorized 401");
      router.push("/login");
      //  setTimeout(() => window.location = "/login" ,500)
    }
    console.log("ERR INTERCEPTOR: ", res);
  });
};
export default setupInterceptors;
