<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{
        content ? `${content} | Online Payment System` : `Online Payment System`
      }}
    </template>
  </metainfo>
  <app-layout>
    <router-view />
  </app-layout>
</template>

<script>
import AppLayout from '@/layouts'

export default {
  name: 'App',
  components: { AppLayout },
}
</script>
