<template>
  <div class="flex flex-col w-full xl:w-1/3 max-h-max">
    <content-wrapper externalClass="lg:sticky lg:top-4">
      <h2 class="text-lg font-medium mb-2">
        {{ $t('user.basket.basketSummary') }}
      </h2>
      <ul class="mb-4">
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t('user.basket.totalPrice') }}</span>
          <span class="text-lg font-bold"> {{
            toCurrency(basket.totalPrice, "tr-TR", "currency", getCongressProductCurrency(basket.productCurrency))
          }}</span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t('user.basket.totalVat') }}</span>
          <span class="text-lg font-bold" v-if="basket.totalVat != 0">
            {{ toCurrency(basket.totalVat, "tr-TR", "currency", getCongressProductCurrency(basket.productCurrency)) }}
          </span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t('user.basket.salePrice') }}</span>
          <span class="text-lg font-bold">{{
            toCurrency(basket.salePrice, "tr-TR", "currency", getCongressProductCurrency(basket.productCurrency))
            }}</span>
        </li>
      </ul>
      <div class="flex items-center mb-4">
        <input type="checkbox" :checked="isAgreement" @change="isAgreement = !isAgreement"
          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
        <label @click="openModal" class="ml-2 text-sm font-medium text-gray-900">
          <span class="cursor-pointer underline">
            {{ $t('user.profile.distanceSalesAgreementClick') }}
          </span>
          <span>{{ $t('user.profile.distanceSalesAgreement') }}</span>
        </label>
      </div>
      <button-with-loader @click="payment" :isLoading="isLoading" type="button"
        class="inline-flex gap-1 items-center justify-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 w-full text-center">
        <span v-if="paymentType === 'transfer-tab'">
          {{ $t('user.payment.createOrder') }}
        </span>
        <span v-else-if="paymentType === 'iyzico-tab'">
          {{ $t('user.payment.payment.iyzico') }}
        </span>
        <span v-else>{{ $t('user.payment.payment') }}</span>
        <ArrowLongRightIcon class="w-5 h-5" />
      </button-with-loader>
    </content-wrapper>
  </div>
  <distance-sales-modal :closeModal="closeModal" />
  <credit-card-response-modal :creditCardResponse="creditCardResponse" :closeModal="closeCreditCardResponseModal"
    :payment3dResult="payment3dResult" />

  <iyzico-response-modal :source="iyzicoResponseModalUrl" :closeModal="closeCreditCardResponseModal"
    :iyzicoPaymentResult="payment3dResult" />
</template>

<script>
import {
  ButtonWithLoader,
  DistanceSalesModal,
  ContentWrapper,
} from '@/components'
import { service } from '@/common/services'
import { useResponseToast, useToast } from '@/common/hooks'
import { ArrowLongRightIcon } from '@heroicons/vue/24/outline'
import router from '@/router'
import { mapMutations } from 'vuex'
import CreditCardResponseModal from './CreditCardResponseModal.vue'
import IyzicoResponseModal from './IyzicoResponseModal.vue'
import { toCurrency } from '@/common/utils'

export default {
  name: 'BasketSummary',
  components: {
    ButtonWithLoader,
    DistanceSalesModal,
    CreditCardResponseModal,
    IyzicoResponseModal,
    ContentWrapper,
    ArrowLongRightIcon,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      isLoading: false,
      modal: null,
      isAgreement: false,
      creditCardResponse: null,
      creditCardResponseModal: null,
      iyzicoResponseModalUrl: null,
      iyzicoResponseModal: null,
    }
  },
  props: {
    basket: {},
    propFormData: {},
    paymentType: String,
    isCorporateInvoice: Boolean,
  },
  mounted() {
    this.initModal()
    window.checkout = CreditCardResponseModal
  },
  methods: {
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    ...mapMutations(['clearBasket']),
    payment() {
      if (this.validateIsCorporate()) {
        if (this.isAgreement) {
          this.isLoading = true
          switch (this.paymentType) {
            case 'transfer-tab':
              this.dispatchOrderPayWithTransfer()
              break
            case 'credit-card-tab':
              this.dispatchOrderPayWithCreditCard()
              break
            case 'credit-card-tab-3d':
              this.dispatchOrderPayWith3d()
              break
            case 'iyzico-tab':
              this.dispatchOrderPayWithIyzico()
              break
          }
        } else {
          this.isLoading = false
          this.toast.error(this.$t('user.payment.requiredDistanceSales'))
        }
      }
    },
    closeModal() {
      this.modal.hide()
    },
    openModal() {
      this.modal.show()
    },
    closeCreditCardResponseModal() {
      if (this.creditCardResponseModal) {
        this.creditCardResponseModal.hide()
      }

      if (this.iyzicoResponseModal) {
        this.iyzicoResponseModal.hide()
      }
    },
    initModal() {
      const modalEl = document.getElementById('distance-sales-modal')
      const modalOptions = {
        placement: 'center-center',
        backdropClasses:
          'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        onHide: () => {
          this.isAgreement = true
        },
      }
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions)
      }
    },
    dispatchOrderPayWithTransfer() {
      const payload = Object.fromEntries(
        Object.entries(this.propFormData).filter(([, v]) => v != null),
      )
      service
        .dispatch('order/pay-with-transfer', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.clearBasket()
              router.push({
                name: 'order-completed/id',
                params: { id: response.orderId },
              })
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    dispatchOrderPayWithCreditCard() {
      const payload = {
        ...this.propFormData,
      }
      service
        .dispatch('order/pay-with-creditcard', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.clearBasket()
              router.push({
                name: 'order-completed/id',
                params: { id: response.orderId },
              })
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    dispatchOrderPayWith3d() {
      const payload = {
        ...this.propFormData,
      }
      service
        .dispatch('order/pay-with-3d', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.creditCardResponse = response.html
              const modalEl = document.getElementById(
                'credit-card-response-modal',
              )
              const modalOptions = {
                placement: 'center-center',
                backdropClasses:
                  'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
                onHide: () => { },
              }
              if (modalEl) {
                // eslint-disable-next-line
                this.creditCardResponseModal = new Modal(modalEl, modalOptions)
                this.creditCardResponseModal.show()
              }
            },
            errorCallback: () => {
              this.creditCardResponseModal.hide()
            },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    payment3dResult(data) {
      const response = data !== undefined && JSON.parse(data)
      console.debug('payment3dResult', response)
      if (response) {
        if (response.IsSuccess) {
          if (this.creditCardResponseModal != null) {
            this.creditCardResponseModal.hide()
          }
          else if (this.iyzicoResponseModal != null) {
            this.iyzicoResponseModal.hide()
          }
          this.clearBasket()
          router.push({
            name: 'order-completed/id',
            params: { id: response.OrderId },
          })
        } else {
          if (response.Messages) {
            response.Messages.map((message) => {
              if (this.$t(`error.${message.Code}`) === '') {
                this.toast.error(message.Code)
              } else {
                this.toast.error(this.$t(`error.${message.Code}`))
              }
            })
          } else {
            this.toast.error(this.$t(`error.${response.Message}`))
          }
          this.creditCardResponseModal.hide()
        }
      } else {
        this.toast.error(this.$t(`customError.responseUndefined`))
      }
    },
    dispatchOrderPayWithIyzico() {
      service
        .dispatch('order/pay-with-iyzico', this.propFormData)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.iyzicoResponseModalUrl = response.pageUrl
              const modalEl = document.getElementById(
                'credit-card-response-modal',
              )
              const modalOptions = {
                placement: 'center-center',
                backdropClasses:
                  'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
                onHide: () => { },
              }
              if (modalEl) {
                // eslint-disable-next-line
                this.iyzicoResponseModal = new Modal(modalEl, modalOptions)
                this.iyzicoResponseModal.show()
              }
            },
            errorCallback: () => {
              if (this.iyzicoResponseModal != null) {
                this.iyzicoResponseModal.hide()
              }
              if (response.Messages) {
                response.Messages.map((message) => {
                  if (this.$t(`error.${message.Code}`) === '') {
                    this.toast.error(message.Code)
                  } else {
                    this.toast.error(this.$t(`error.${message.Code}`))
                  }
                })
              } else {
                this.toast.error(this.$t(`error.${response.Message}`))
              }
            },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    validateIsCorporate() {
      if (this.isCorporateInvoice) {
        if (
          this.propFormData.invoiceTaxNumber === '' ||
          this.propFormData.invoiceTaxOffice === ''
        ) {
          !this.propFormData.invoiceTaxNumber &&
            this.toast.error(this.$t('shared.validateTaxNumber'))
          !this.propFormData.invoiceTaxOffice &&
            this.toast.error(this.$t('shared.validateTaxOffice'))
          return false
        }
        return true
      } else {
        return true
      }
    },
    toCurrency,
  },
}
</script>
