
<template>
    <div class="flex flex-col lg:flex-row">
        <div class="w-full lg:w-1/2 lg:pr-2">
            <div class="flex-auto mb-6">
                <label for="ArrivalDateTime" class="block mb-2 text-sm font-medium text-gray-900">
                    {{ this.congressDetail.isFlightDetailsOneWay === true ?
                        $t("admin.congressDetail.transferDetails.DateTime") : (isArrival ?
                            $t("admin.congressDetail.transferDetails.ArrivalDateTime") :
                            $t("admin.congressDetail.transferDetails.DepartureDateTime")) }}
                </label>

                <input type="datetime-local" :min="congressDetail.startDate" :max="congressDetail.endDate" name="Arrival"
                    v-model="transferDetails.dateTime" time-picker-inline format="yyyy-MM-dd HH:mm" locale="tr" required
                    :placeholder="(this.congressDetail.isFlightDetailsOneWay === true ? $t('admin.congressDetail.transferDetails.DateTime') : (isArrival ?
                        $t('admin.congressDetail.transferDetails.ArrivalDateTime') :
                        $t('admin.congressDetail.transferDetails.DepartureDateTime')))"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full" />
            </div>


            <div class="flex-auto mb-6">
                <label for="companyName" class="block mb-2 text-sm font-medium text-gray-900">
                    {{ this.congressDetail.isFlightDetailsOneWay === true ?
                        $t("admin.congressDetail.transferDetails.City") : (isArrival ?
                            $t("admin.congressDetail.transferDetails.ArrivalCity") :
                            $t("admin.congressDetail.transferDetails.DepartureCity")) }}
                </label>
                <input type="text" id="enterCity" v-model="transferDetails.city" required
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                <i class="text-xs"> {{ this.congressDetail.isFlightDetailsOneWay === true ?
                    $t("admin.congressDetail.transferDetails.CityTip") : (isArrival ?
                        $t("admin.congressDetail.transferDetails.ArrivalCityTip") :
                        $t("admin.congressDetail.transferDetails.DepartureCityTip")) }}</i>

            </div>
        </div>

        <div class="w-full lg:w-1/2 lg:pr-2">
            <div class="flex-auto mb-6">
                <label for="companyName" class="block mb-2 text-sm font-medium text-gray-900">
                    {{ this.congressDetail.isFlightDetailsOneWay === true ?
                        $t("admin.congressDetail.transferDetails.FlightNumber") : (isArrival ?
                            $t("admin.congressDetail.transferDetails.ArrivalFlightNumber") :
                            $t("admin.congressDetail.transferDetails.DepartureFlightNumber")) }}
                </label>
                <input type="text" id="enterCity" v-model="transferDetails.flightNumber"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                <i class="text-xs"> {{ $t("admin.congressDetail.transferDetails.FlightNumberTip") }}</i>
            </div>
        </div>

    </div>

    <div class="lg:w-full lg:flex-grow space-y-6 lg:space-y-0 pb-2 pr-2">
        <label for="accommodationNote" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressDetail.transferDetails.Notes") }}
        </label>
        <textarea id="description" v-model="transferDetails.notes" rows="4"
            class="block p-2.5 w-full text-sm resize-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
    </div>
</template>

<script>
/* eslint-disable */

import moment from 'moment'
import { mapGetters } from 'vuex'
import { toCurrency } from '@/common/utils'
import DatePicker from "@vuepic/vue-datepicker";

export default {
    name: 'TransferDetail',
    components: {
        DatePicker,
    },
    setup() { },
    data() {
        return {
            moment: moment,
            transferDetails: {},
        }
    },
    props: {
        congress: {},
        congressDetail: {},
        TransferDetails: {},
        isArrival: true,
    },
    methods: {
        getCongressProductCurrency() {
            let _currency;
            switch (this.productCurrency) {
                case 1:
                    _currency = "EUR";
                    break;
                case 2:
                    _currency = "USD";
                    break;
                default:
                    _currency = "TRY";
                    break;
            }
            return _currency;
        },
        toCurrency,
    },
    mounted() {
        this.transferDetails = this.TransferDetails;
    },
    watch: {
        transferDetails: function (data) {
            this.propTransferDetails = data
        },
    },
    computed: {
        ...mapGetters(['getLang']),
    },
}
</script>
