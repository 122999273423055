<template>
  <div class="step-container">
    <div class="flex justify-center py-4 mb-6">
      <template v-for="item in stepList" :key="item.key">
        <div class="flex flex-col relative w-[120px]">
          <div :class="step >= item.key
            ? 'bg-blue-700 text-white'
            : 'bg-gray-200 text-gray-600'
            " class="flex justify-center items-center rounded-full mx-auto mb-2 w-10 h-10">

            <span>{{ item.key }} </span>
          </div>
          <div class="text-xs text-center md:text-base" :class="step === item.key && 'font-bold'">
            {{ $t(item.value) }}
          </div>
        </div>
        <div v-if="stepList.length !== item.key" class="w-1/3 flex items-center px-4 pb-[30px]">
          <div class="w-full bg-gray-200 rounded">
            <div :class="step >= item.key + 1 ? 'w-full' : 'w-0'" class="bg-blue-700 py-1 rounded"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepWizard',
  props: {
    step: Number,
    stepList: Array,
  },
}
</script>

<style scoped>
.step-container {
  overflow: auto;
  overflow-y: hidden;
}

@media (max-width: 330px) {
  .step-container {
    padding-left: 120px
  }
}

@media (min-width: 331px) and (max-width: 420px) {
  .step-container {
    padding-left: 70px
  }
}
</style>
