<template>
  <div class="relative">
    <slot></slot>
    <div
      v-if="isLoading"
      class="flex items-center justify-center bg-white opacity-50 w-full h-full absolute top-0"
    >
      <spinner-loader></spinner-loader>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from '@/components/Loader/SpinnerLoader.vue'
export default {
  name: 'TableWrapperWithLoader',
  components: { SpinnerLoader },
  props: {
    isLoading: Boolean,
  },
}
</script>
