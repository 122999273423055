<template>
  <div
    id="distance-sales-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
  >
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <!-- Modal content -->
      <div
        class="modal-scroll relative bg-white rounded-lg shadow h-full md:h-[650px] overflow-auto dark:bg-gray-700"
      >
        <!-- Modal header -->
        <div
          class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600"
        >
          <h3
            v-if="getLang === 'EN'"
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            PRIVACY NOTICE FOR ONLINE PAYMENT SYSTEM
          </h3>
          <h3
            v-if="getLang === 'TR'"
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            ONLINE ÖDEME SİSTEMİ AYDINLATMA BEYANI
          </h3>
          <button
            type="button"
            @click="closeModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <XMarkIcon class="w-5 h-5" />
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <div v-if="getLang === 'EN'">
            <p class="text-base font-bold">
              1.IDENTITY OF DATA CONTROLLER
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              As Serenas International Tourism Congress Organization Inc.
              <strong>(“Serenas Group”)</strong>
              , we attach importance to the protection of your personal data
              and, being aware of our titles as data controller and data
              processor, we take care to act in accordance with the law in all
              our personal data processing processes. In this context, we would
              like to inform you that your personal data is processed in
              accordance with the Personal Data Protection Law No.6698
            </p>
            <p class="text-base font-bold">
              2.PROCESSED PERSONAL DATA AND OUR PROCESSING PURPOSE
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              Our company provides online payment service within the scope of
              congress organizations and affiliated activities, in this
              direction, your identity information consisting of your name and
              surname, your contact information consisting of your phone number,
              e-mail address and address, your professional experience
              information consisting of your institution, department and
              position, title and your field of expertise, your financial data
              regarding payment fee and payment type, the data related to
              process security you shared by filling out the relevant form are
              collected, stored and processed by us in the capacity of data
              controller for carrying out goods and services operation
              processes, carrying out activities for customer satisfaction,
              executing company services commitment processes, executing
              contract processes , executing activities in accordance with the
              legislation and informing authorized persons, institutions and
              organizations.
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              The credit card information you enter on the system is processed
              in the infrastructure of the relevant bank or financial
              institution and is not recorded and processed by us.
            </p>
            <p class="text-base font-bold">
              3.COLLECTING METHODS OF YOUR PERSONAL DATA AND LEGAL REASONS FOR
              PROCESSING
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              Your personal data is collected by Serenas Group electronically
              through the forms on the online payment system and is processed
              for the reasons that it is clearly stated in the law, it is
              mandatory for the performance of the agreement, it is compulsory
              for the data controller to fulfill its legal obligations, and it
              is compulsory for the legitimate interests of the data controller.
            </p>
            <p class="text-base font-bold">
              4.TRANSFERRING YOUR PERSONAL DATA
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              Your personal data detailed above may be shared with our
              shareholders and authorized public institutions and organizations
              upon request, our lawyers, consultants and real or legal entities
              from whom we receive other services for the purposes of following
              and executing legal affairs, executing activities in accordance
              with the legislation, carrying out financial and accounting works,
              conducting internal audit activities and informing authorized
              persons and institutions.
            </p>
            <p class="text-base font-bold">
              5.YOUR RIGHTS REGARDING YOUR PERSONAL DATA
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              You can send your requests regarding your rights listed in Article
              11 of the Law on the Protection of Personal Data No. 6698, through
              communication channels by duly applying. Accordingly, to forward
              your requests, you can send an e-mail to kvk@serenas.com.tr via
              your e-mail address registered in the system, Hilal Mah. Algeria
              Cad. No: 13 06550 Yıldız, Çankaya/ANKARA via registered mail or
              notary public to SERENAS Uluslararası Turizm Kongre Organizasyon
              A.Ş.
            </p>
          </div>
          <div v-if="getLang === 'TR'">
            <p class="text-base font-bold">
              1.VERİ SORUMLUSUNUN KİMLİĞİ
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              SERENAS Uluslararası Turizm Kongre Organizasyon A.Ş.
              <strong>(“Serenas Group”)</strong>
              olarak kişisel verilerinizin korunmasına önem veriyor ve veri
              sorumlusu ve veri işleyen sıfatlarımızın bilinciyle tüm kişisel
              veri işleme süreçlerimizde hukuka uygun hareket etmeye özen
              gösteriyoruz. Bu kapsamda, aldığınız online ödeme sistemi hizmeti
              nezdinde kişisel verilerinizin her koşulda 6698 sayılı Kişisel
              Verilerin Korunması Kanunu ve ilgili mevzuat hükümlerine uyulmak
              suretiyle işlendiğini bildirir, kişisel verilerinizi işleme
              faaliyetlerimize dair sizleri bilgilendirmek isteriz.
            </p>
            <p class="text-base font-bold">
              2.İŞLENEN KİŞİSEL VERİLERİNİZ VE İŞLEME AMAÇLARIMIZ
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              Şirketimizin kongre organizasyonları ve bağlı faaliyetleri
              kapsamında online ödeme hizmeti verilmekte, bu doğrultuda, ilgili
              formu doldurarak paylaşmış olduğunuz adınız ve soyadınızdan oluşan
              kimlik verileriniz, telefon numaranız, e-posta adresiniz ve
              adresinizden oluşan iletişim verileriniz, unvan, çalışılan kurum,
              departman, pozisyon ve uzmanlık alanınızdan oluşan mesleki deneyim
              verileriniz, ödeme bedeli ve türünden oluşan finans verileriniz ve
              işlem güvenliği verileriniz mal ve hizmet operasyon süreçlerinin
              yürütülmesi, finans ve muhasebe işlerinin yürütülmesi, sözleşme
              süreçlerinin yürütülmesi, bilgi güvenliği süreçlerinin
              yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesi ve yetkili
              kişi kurum ve kuruluşlara bilgi verilmesi amaçlarıyla tarafımızca
              veri sorumlusu sıfatıyla toplanmakta, saklanmakta ve
              işlenmektedir.
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              Sistem üzerinde girmiş olduğunuz kredi kartı bilgileri ilgili
              banka veya finansal kuruluş altyapısında işlenmekte olup
              tarafımızca kaydedilmemekte ve işlenmemektedir.
            </p>
            <p class="text-base font-bold">
              3.KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEPLERİ
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              Kişisel verileriniz Serenas Group tarafından tarafınızca online
              ödeme sistemi üzerinde yer alan formlar aracılığıyla elektronik
              ortamda toplanmakta olup, kanunlarda açıkça görülmesi, sözleşmenin
              ifası için zorunlu olması, veri sorumlusunun hukuki yükümlülüğünü
              yerine getirebilmesi için zorunlu olması ve veri sorumlusunun
              meşru menfaatleri için zorunlu olması sebepleriyle işlenmektedir.
            </p>
            <p class="text-base font-bold">
              4.KİŞİSEL VERİLERİNİZİN AKTARILMASI
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              Yukarıda ayrıntılı belirtilen kişisel verileriniz, tarafımızca
              hukuk işlerinin takibi ve yürütülmesi, faaliyetlerin mevzuata
              uygun yürütülmesi, finans ve muhasebe işlerinin yürütülmesi, iç
              denetim faaliyetlerinin yürütülmesi ve yetkili kişi kurum ve
              kuruluşlara bilgi verilmesi amaçlarıyla avukatlarımız,
              danışmanlarımız ve diğer hizmet alınan gerçek veya özel hukuk
              tüzel kişileri, hissedarlarımız ve talep halinde yetkili kamu
              kurum ve kuruluşları ile paylaşılabilir.
            </p>
            <p class="text-base font-bold">
              5.KİŞİSEL VERİLERİNİZ İLE İLGİLİ HAKLARINIZ
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400 mb-2"
            >
              6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde
              sayılan haklarınıza ilişkin taleplerinizi, veri sorumlusu şirket
              SERENAS Uluslararası Turizm Kongre Organizasyon A.Ş. iletişim
              kanalları üzerinden usulüne uygun başvuru yaparak iletebilirsiniz.
              Buna göre, taleplerinizi iletmek için sistemde kayıtlı e-posta
              adresiniz üzerinden kvk@serenas.com.tr adresine e-posta
              gönderebilir, Hilal Mah. Cezayir Cad. No:13 06550 Yıldız,
              Çankaya/ANKARA adresine iadeli taahhütlü mektup veya noter
              kanalıyla ulaştırabilirsiniz.
            </p>
          </div>
        </div>
        <!-- Modal footer -->
        <div
          class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600"
        >
          <button
            @click="closeModal"
            type="button"
            class="inline-flex gap-1 items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <CheckCircleIcon class="w-5 h-5" />
            {{ $t('shared.accept') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'
import { mapGetters } from 'vuex'
export default {
  name: 'DistanceSalesModal',
  components: { XMarkIcon, CheckCircleIcon },
  props: {
    closeModal: Function,
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped></style>
