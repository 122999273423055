import { useToast } from "vue-toastification";
import { i18n } from "@/common/locale";

const toast = useToast();

export const useResponseToast = ({
  response,
  successCallback,
  errorCallback,
  isSaveMessage,
  noToastMessageCodes = [],
}) => {
  if (response) {
    if (response.isSuccess) {
      if (isSaveMessage) {
        toast.success(i18n.global.t("shared.successfullyCompleted"));
      }
      successCallback && successCallback();
    } else {
      if (response.messages) {
        response.messages.map((message) => {
          if (!noToastMessageCodes.includes(message.code)) {
            if (i18n.global.t(`error.${message.code}`) === "") {
              toast.error(message.code);
            } else {
              toast.error(i18n.global.t(`error.${message.code}`));
            }
          }
        });
      } else {
        toast.error(i18n.global.t(`error.${response.message}`));
      }
      errorCallback && errorCallback();
    }
  }else{
    toast.error(i18n.global.t(`customError.responseUndefined`));
  }
};
