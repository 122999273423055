<template>
  <div class="bg-gray-100 p-5 flex justify-between items-center text-xs">
    <div class="w-[100px] truncate">{{ $t('user.basket.productType') }}</div>
    <div class="flex-1 truncate mx-4">{{ $t('user.basket.name') }}</div>
    <div class="min-w-[50px]">{{ $t('user.basket.price') }}</div>
    <div class="w-5"></div>
  </div>
  <div v-for="item in basket.items" :key="item.id">
    <div
      :id="`accordion-${item.id}-header`"
      class="accordion-header flex border-b-2 p-5 bg-white dark:bg-gray-800"
    >
      <div :aria-controls="`accordion-${item.id}-header`" class="w-full">
        <div class="flex justify-between items-center text-xs">
          <div class="w-[100px] truncate">
            <span v-if="item.productType === 1">{{ $t('shared.inside') }}</span>
            <span v-if="item.productType === 2">
              {{ $t('shared.outside') }}
            </span>
            <span v-if="item.productType === 3">
              {{ $t('shared.acommodation') }}
            </span>
            <span v-if="item.productType === 4">
              {{ $t('shared.transfer') }}
            </span>
            <span v-if="item.productType === 5">{{ $t('shared.course') }}</span>
          </div>
          <div class="flex-1 truncate mx-4">
            <span v-if="getLang === 'TR'">
              {{ item.name }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.nameEn }}
            </span>
          </div>
          <div class="w-[50px] min-w-[50px] sm:w-auto truncate">
            {{ toCurrency(item.price) }}
          </div>
          <div class="w-5">
            <div class="up-icon">
              <ChevronUpIcon class="h-6 w-6 text-blue-500" />
            </div>
            <div class="down-icon">
              <ChevronDownIcon class="h-6 w-6 text-blue-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="`accordion-${item.id}-body`"
      class="hidden"
      :aria-labelledby="`accordion-${item.id}-body`"
    >
      <div
        class="p-5 text-sm border-gray-200 dark:border-gray-700 dark:bg-gray-900"
      >
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.basket.vat') }}:
          </span>
          <span class="text-gray-900">{{ toCurrency(item.vat) }}</span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.basket.firstNameLastName') }}:
          </span>
          <span class="text-gray-900">
            {{ item.firstName }} {{ item.surname }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.basket.email') }}:
          </span>
          <span class="text-gray-900">{{ item.email }}</span>
        </div>
        <div class="flex mb-2">
          <a
            @click="deleteBasketById(item.id)"
            class="cursor-pointer flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t('shared.delete') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useResponseToast } from '@/common/hooks'
import { service } from '@/common/services'
import { mapActions } from 'vuex'
import moment from 'moment'
import { toCurrency } from '@/common/utils'

export default {
  name: 'BasketAccordionList',
  components: { ChevronUpIcon, ChevronDownIcon },
  data() {
    return {
      moment: moment,
      accordion: null,
    }
  },
  props: {
    basket: Object,
  },
  mounted() {
    this.initAccordion(this.basket.items)
  },
  methods: {
    ...mapActions(['fetchBasket']),
    deleteBasketById(id) {
      service.delete(`basket/${id}`).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.fetchBasket()
          },
          errorCallback: () => {},
          isSaveMessage: true,
        })
      })
    },
    initAccordion(items) {
      let accordionItems = []
      if (
        items !== undefined &&
        document.querySelector('.accordion-header') !== null
      ) {
        items.map((item, index) => {
          if (index === 0) {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: true,
            })
          } else {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: false,
            })
          }
        })
        const options = {
          alwaysOpen: false,
          activeClasses: 'text-gray-900 dark:text-white',
          inactiveClasses: 'text-gray-900 dark:text-white',
        }
        // eslint-disable-next-line
        this.accordion = new Accordion(accordionItems, options)
      }
    },
    toCurrency
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.accordion-header[aria-expanded='true'] {
  .up-icon {
    @apply block;
  }

  .down-icon {
    @apply hidden;
  }
}

.accordion-header[aria-expanded='false'] {
  .up-icon {
    @apply hidden;
  }

  .down-icon {
    @apply block;
  }
}
</style>
