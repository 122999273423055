<template>
  <div class="bg-gray-100 p-5 flex justify-between items-center text-xs">
    <div class="flex-1 truncate sm:max-w-[200px] md:max-w-[300px] mr-2">
      {{ $t('user.order.congressName') }}
    </div>
    <div class="flex-1 truncate sm:max-w-[200px]">
      {{ $t('user.order.firstNameLastName') }}
    </div>
    <div class="w-5"></div>
    <div class="w-5"></div>
  </div>
  <div v-for="item in order.items" :key="item">
    <div
      :id="`accordion-${item.id}-header`"
      class="accordion-header flex border-b-2 p-5 bg-white dark:bg-gray-800"
    >
      <div :aria-controls="`accordion-${item.id}-header`" class="w-full">
        <div class="flex justify-between items-center text-xs">
          <div class="flex-1 truncate sm:max-w-[200px] md:max-w-[300px] mr-2">
            <span v-if="getLang === 'TR'">
              {{ item.congressName }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.congressNameEn }}
            </span>
          </div>
          <div class="flex-1 truncate sm:max-w-[200px]">
            {{ item.firstName }} {{ item.lastName }}
          </div>
          <div class="w-5 mx-4">
            <span
              v-if="item.isActive"
              class="block rounded bg-green-100 p-1 w-6 h-6"
            >
              <CheckCircleIcon class="h-4 w-4 text-green-800" />
            </span>
            <span v-else class="block rounded bg-red-100 p-1 w-6 h-6">
              <MinusCircleIcon class="h-4 w-4 text-red-800" />
            </span>
          </div>
          <div class="w-5">
            <div class="up-icon">
              <ChevronUpIcon class="h-6 w-6 text-blue-500" />
            </div>
            <div class="down-icon">
              <ChevronDownIcon class="h-6 w-6 text-blue-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="`accordion-${item.id}-body`"
      class="hidden"
      :aria-labelledby="`accordion-${item.id}-body`"
    >
      <div
        class="p-5 text-sm bg-gray-200 border-gray-200 dark:border-gray-700 dark:bg-gray-900"
      >
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.orderNo') }}:
          </span>
          <span class="text-gray-900">
            {{ item.orderNo }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.totalPrice') }}:
          </span>
          <span class="text-gray-900">{{ toCurrency(item.totalPrice) }}</span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.totalTax') }}:
          </span>
          <span class="text-gray-900">{{ toCurrency(item.totalTax) }}</span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.paymentType') }}:
          </span>
          <span class="text-gray-900" v-if="item.paymentType === 1">
            {{ $t(`user.order.creditcard`) }}
          </span>
          <span class="text-gray-900" v-if="item.paymentType === 2">
            {{ $t(`user.order.transfer`) }}
          </span>
        </div>
        <td>
          <span v-if="item.orderStatus === 'waitingforpayment'">
            {{ $t(`user.order.waitingforpayment`) }}
          </span>
          <span v-else-if="item.orderStatus === 'waitingfortransfer'">
            {{
              this.CheckItemHasInovice(item)
                ? $t(`user.order.waitingforApprove`)
                : $t(`user.order.waitingfortransfer`)
            }}
          </span>
          <span v-else-if="item.orderStatus === 'paymentcompleted'">
            {{ $t(`user.order.paymentcompleted`) }}
          </span>
          <span v-else>
            {{ $t(`user.order.canceled`) }}
          </span>
        </td>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.createdDate') }}:
          </span>
          <span class="text-gray-900">
            {{ moment(item.createdDate).format('DD.MM.YYYY HH:mm') }}
          </span>
        </div>

        <div class="flex mb-2">
          <router-link
            :to="{ name: 'order/id', params: { id: item.id } }"
            class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t('shared.details') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  MinusCircleIcon,
} from '@heroicons/vue/20/solid'
import { toCurrency } from '@/common/utils'

export default {
  name: 'OrderAccordionList',
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    CheckCircleIcon,
    MinusCircleIcon,
  },
  data() {
    return {
      moment: moment,
      accordion: null,
    }
  },
  props: {
    order: Object,
    pageSize: Number,
    payload: Object,
  },
  mounted() {
    this.initAccordion(this.order.items)
  },
  methods: {
    initAccordion(items) {
      let accordionItems = []
      if (
        items !== undefined &&
        document.querySelector('.accordion-header') !== null
      ) {
        items.map((item, index) => {
          if (index === 0) {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: true,
            })
          } else {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: false,
            })
          }
        })
        const options = {
          alwaysOpen: false,
          activeClasses: 'text-gray-900 dark:text-white',
          inactiveClasses: 'text-gray-900 dark:text-white',
        }
        // eslint-disable-next-line
        this.accordion = new Accordion(accordionItems, options)
      }
    },
    toCurrency
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.accordion-header[aria-expanded='true'] {
  .up-icon {
    @apply block;
  }

  .down-icon {
    @apply hidden;
  }
}

.accordion-header[aria-expanded='false'] {
  .up-icon {
    @apply hidden;
  }

  .down-icon {
    @apply block;
  }
}
</style>
