<template>
  <div class="overflow-x-auto relative py-4">
    <div class="tab">
      <tabs variant="underline" v-model="activeTab">
        <tab
          v-for="(item, key) in validWireInformationList"
          :name="`wire.${key}`"
          :key="key"
          :title="item.Currency"
        >
          <CongressWireInformation
            :wireInformation="item"
            :isEditable="false"
          />
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Tabs, Tab } from "flowbite-vue";
import { ref } from "vue";
import CongressWireInformation from "@/components/Congress/CongressWireInformation.vue";

export default {
  name: "TransferTab",
  components: {
    CongressWireInformation,
    Tabs,
    Tab,
  },
  computed: {
    ...mapGetters(["getBasket", "getLang"]),
    validWireInformationList: function() {
      return this.getBasket.wireTransferInformations.filter(
        (item) => item.PrimaryIban != ""
      );

    }
  },
  data() {
    return {
      activeTab: ref("wire.0"),
    };
  },
  methods: {},
};
</script>
