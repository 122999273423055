import { BehaviorSubject } from "rxjs";
import { service } from "@/common/services";
import OpenAuthService from "./openauth.service";
import router from "@/router";

const accountCurrentSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const openAuth = new OpenAuthService();

class AuthService {
  // Serenas Login
  loginOID() {
    openAuth.login();
  }

  // Standard Login
  login(payload) {
    return service.dispatch("account/login", payload).then((res) => {
      if (res.isSuccess) {
        this.setUser(res);
      }
      return res;
    });
  }

  sync(payload) {
    return service
      .dispatch("account/sync", { token: payload.access_token })
      .then((res) => {
        if (res.isSuccess) {
          this.setUser(res);
        }
        return res;
      });
  }

  logout() {
    const serenasStorage = localStorage.getItem(
      Object.keys(localStorage).find((item) => item.includes("oidc"))
    );
    if (serenasStorage) {
      localStorage.removeItem(
        Object.keys(localStorage).find((item) => item.includes("oidc"))
      );
    }
    localStorage.removeItem("currentUser");
    accountCurrentSubject.next(null);
    router.push({ path: "/login" });
    return true;
  }

  register(payload) {
    return service.dispatch("account/register", payload).then((res) => {
      return res;
    });
  }

  setUser(user) {
    localStorage.setItem(
      "currentUser",
      JSON.stringify({ token: user.token, id: user.id, isAdmin: user.isAdmin })
    );
    accountCurrentSubject.next(user);
    router.push(this.returnUrl() || { name: "home" });
    return user;
  }

  returnUrl() {
    let returnUrl = "";

    if (window.location.search.includes("?returnUrl=")) {
      returnUrl = window.location.search.split("?returnUrl=")[1];
    }
    if (localStorage.getItem("returnUrl")) {
      returnUrl = localStorage.getItem("returnUrl");
      localStorage.removeItem("returnUrl");
    }

    if (returnUrl) {
      return { path: returnUrl };
    }
  }

  accountCurrentValue() {
    return accountCurrentSubject.value;
  }
}

export const accountCurrent = accountCurrentSubject.asObservable();
export default new AuthService();
