<template>
  <div class="order sm:hidden block">
    <no-data externalClass="relative pt-12">
      <div class="text-2xl font-bold mb-2 text-gray-900">
        {{ $t("shared.noData") }}
      </div>
      <div class="text-sm font-normal text-gray-500 text-center">
        {{ $t("disabled.reportsView") }}
      </div>
    </no-data>
  </div>

  <div class="order hidden sm:block">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <template v-if="order.items.length">
        <content-wrapper>
          <order-action :search="search" :filterOrderStatus="filterOrderStatus" :filterPaymentType="filterPaymentType"
            :filterStartDate="filterStartDate" :filterEndDate="filterEndDate" :filterReset="filterReset"
            :payload="payload" :downloadExcel="downloadExcel" :order="order" />
          <table-wrapper-with-loader :isLoading="isTableLoading">
            <template v-if="downXl">
              <order-table-list :order="order" :payload="payload" :pageSize="payload.pageSize" />
            </template>
            <template v-else>
              <order-accordion-list :order="order" :payload="payload" :pageSize="payload.pageSize" />
            </template>
          </table-wrapper-with-loader>

          <order-pagination v-if="order.items" :order="order" :changePage="changePage" />
        </content-wrapper>
      </template>
      <template v-else>
        <content-wrapper>
          <order-action :search="search" :filterOrderStatus="filterOrderStatus" :filterPaymentType="filterPaymentType"
            :filterStartDate="filterStartDate" :filterEndDate="filterEndDate" :filterReset="filterReset"
            :payload="payload" :downloadExcel="downloadExcel" :order="order" />
        </content-wrapper>
        <no-data externalClass="relative pt-12">
          <div class="text-2xl font-bold mb-2 text-gray-900">
            {{ $t("shared.noData") }}
          </div>
          <div class="text-sm font-normal text-gray-500">
            {{ $t("shared.noDataDescription") }}
          </div>
        </no-data>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from "vue";
import {
  useToast,
  useMediaQuery,
  useResponseToast,
  useMeta,
} from "@/common/hooks";
import { service } from "@/common/services";
import moment from "moment";
import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  TableWrapperWithLoader,
  ContentWrapper,
  NoData,
} from "@/components";
import OrderAction from "./OrderAction.vue";
import OrderTableList from "./OrderTableList.vue";
import OrderAccordionList from "./OrderAccordionList.vue";
import OrderPagination from "./OrderPagination.vue";

export default {
  name: "OrderPage",
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    TableWrapperWithLoader,
    ContentWrapper,
    NoData,
    OrderAction,
    OrderTableList,
    OrderAccordionList,
    OrderPagination,
  },
  data() {
    return {
      moment: moment,
      breadcrumbList: [{ name: this.$t("sidebar.order"),key : "sidebar.order"}],
      pageTitle: this.$t("sidebar.order"),
      order: Object,
      isLoading: true,
      isTableLoading: false,
      payload: {
        searchText: "",
        pageNumber: 1,
        pageSize: 10,
        sortColumn: "",
        sortDescending: true,
        orderStatus: 0,
        paymentType: 0,
        startDate: null,
        endDate: null,
      },
    };
  },
  setup() {
    const { downXl } = useMediaQuery();
    const toast = useToast();
    return { toast, downXl };
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t("metaTitle.orders"),
      }))
    );
  },
  mounted() {
    this.dispatchOrderList(this.payload);
  },
  watch: {
    order: function (data) {
      return (this.order = data);
    },
  },
  methods: {
    dispatchOrderList(payload) {
      this.isTableLoading = true;
      service
        .dispatch("reports/order", payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              if (response.items.length) {
                this.order = response;
              } else {
                this.order = [];
              }
            },
            errorCallback: () => {
              response.messages.map((message) => {
                if (message.code === "Data is empty") {
                  this.payload = { ...payload };
                  this.order.items = [];
                }
              });
            },
            isSaveMessage: false,
            noToastMessageCodes: ["Data is empty"],
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.isTableLoading = false;
        });
    },
    changePage(pageNumber) {
      this.payload = { ...this.payload, pageNumber };
      this.dispatchOrderList(this.payload);
    },
    search(searchText) {
      this.payload = { ...this.payload, searchText: searchText, pageNumber: 1 };
      this.dispatchOrderList(this.payload);
    },
    filterPaymentType($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        paymentType: Number($event.target.value),
      };
      this.dispatchOrderList(this.payload);
    },
    filterOrderStatus($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        orderStatus: Number($event.target.value),
      };
      this.dispatchOrderList(this.payload);
    },
    filterStartDate($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        startDate: moment($event).format("YYYY-MM-DD"),
      };
      this.dispatchOrderList(this.payload);
    },
    filterEndDate($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        endDate: moment($event).format("YYYY-MM-DD"),
      };
      this.dispatchOrderList(this.payload);
    },
    filterReset() {
      this.payload = {
        searchText: "",
        pageNumber: 1,
        pageSize: 10,
        sortColumn: "",
        sortDescending: true,
        orderStatus: 0,
        paymentType: 0,
        startDate: null,
        endDate: null,
      };
      this.dispatchOrderList(this.payload);
    },
    async downloadExcel() {
      const response = await service.dispatch("order/list", {
        ...this.payload,
        pageSize: 10000,
      });
      return response.items.map((item) => {
        let orderStatus = "";
        let paymentType = "";
        switch (item.orderStatus) {
          case 1:
            orderStatus = this.$t("user.excelRowConvert.waitingforpayment");
            break;
          case 2:
            orderStatus = this.$t("user.excelRowConvert.waitingfortransfer");
            break;
          case 3:
            orderStatus = this.$t("user.excelRowConvert.paymentcompleted");
            break;
          case 4:
            orderStatus = this.$t("user.excelRowConvert.canceled");
            break;
        }
        switch (item.paymentType) {
          case 1:
            paymentType = this.$t("user.excelRowConvert.creditcard");
            break;
          case 2:
            orderStatus = this.$t("user.excelRowConvert.transfer");
            break;
        }
        return {
          ...item,
          orderStatus: orderStatus,
          paymentType: paymentType,
          startDate: moment(item.startDate).format("DD.MM.YYYY"),
          endDate: moment(item.endDate).format("DD.MM.YYYY"),
          createdDate: moment(item.createdDate).format("DD.MM.YYYY HH:mm"),
          paymentApproveDate: item.isPaymentApproved
            ? moment(item.paymentApproveDate).format("DD.MM.YYYY HH:mm")
            : "",
          isPaymentApproved: item.isPaymentApproved
            ? this.$t("user.excelRowConvert.paid")
            : this.$t("user.excelRowConvert.noPaid"),
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.order {
  @apply h-full;
}
</style>
