<template>
  <button v-if="isLoading" disabled="true">
    <span role="status" class="flex justify-center">
      <CustomIcon name="spinner-icon" />
      <span class="sr-only">Loading...</span>
    </span>
  </button>
  <button v-else>
    <slot></slot>
  </button>
</template>

<script>
import CustomIcon from "@/components/Icon/CustomIcon.vue";
export default {
  name: "WrapperWithLoader",
  components: { CustomIcon },
  props: {
    isLoading: Boolean
  },
};
</script>
