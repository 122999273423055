<template>
  <div class="flex flex-col lg:flex-row gap-4 my-6">
    <content-wrapper externalClass="w-full">
      <h2 class="text-lg font-medium mb-2">
        {{ $t("user.order.congressInfo") }}
      </h2>
      <ul class="mb-4">
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.order.congressName") }}</span>
          <span class="text-sm font-bold" v-if="getLang === 'TR'">
            {{ order.congressName }}
          </span>
          <span class="text-sm font-bold" v-if="getLang === 'EN'">
            {{ order.congressNameEn }}
          </span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.order.startDate") }}</span>
          <span class="text-sm font-bold">
            {{ moment(order.startDate).format("DD.MM.YYYY") }}
          </span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.order.endDate") }}</span>
          <span class="text-sm font-bold">
            {{ moment(order.endDate).format("DD.MM.YYYY") }}
          </span>
        </li>
      </ul>
    </content-wrapper>
    <content-wrapper externalClass="w-full">
      <h2 class="text-lg font-medium mb-2">
        {{ $t("user.order.orderInfo") }}
      </h2>
      <ul class="mb-4">
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.order.orderStatus") }}</span>
          <span v-if="order.orderStatus === 1">
            {{ $t(`user.order.waitingforpayment`) }}
          </span>
          <span v-else-if="order.orderStatus === 2">
            {{
              this.CheckItemHasInovice(order)
                ? $t(`user.order.waitingforApprove`)
                : $t(`user.order.waitingfortransfer`)
            }}
          </span>
          <span v-else-if="order.orderStatus === 3">
            {{ $t(`user.order.paymentcompleted`) }}
          </span>
          <span v-else>
            {{ $t(`user.order.canceled`) }}
          </span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.basket.totalPrice") }}</span>
          <span class="text-sm font-bold">{{
            toCurrency(order.totalPrice)
            }}</span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.basket.totalVat") }}</span>
          <span class="text-sm font-bold">{{
            toCurrency(order.totalTax)
            }}</span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.order.paymentType") }}</span>
          <span v-if="order.paymentType === 1" class="text-sm font-bold">
            {{ $t(`user.order.creditcard`) }}
          </span>
          <span v-if="order.paymentType === 2" class="text-sm font-bold">
            {{ $t(`user.order.transfer`) }}
          </span>
        </li>
        <li v-if="order.paymentType === 2" class="flex justify-between items-center border-b border-gray-200 p-4">
          <span class="text-sm">
            {{ $t("user.order.bankReceipt") }} : {{ order.documentName }}
          </span>
          <a v-if="order.documentPath" type="button" target="blank" download :href="order.documentPath"
            class="inline-flex gap-1 justify-center items-center w-full sm:w-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            <CloudArrowDownIcon class="w-5 h-5 text-white" />
            <span>{{ $t("user.order.download") }}</span>
          </a>
        </li>
      </ul>
    </content-wrapper>
    <content-wrapper externalClass="w-full">
      <h2 class="text-lg font-medium mb-2">
        {{ $t("user.order.billingInfo") }}
      </h2>
      <ul class="mb-4">
        <li class="flex flex-wrap items-center border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.order.invoiceName") }} :</span>
          <span class="text-sm">{{ order.invoiceName }}</span>
        </li>
        <li class="flex flex-wrap items-center border-b border-gray-200 p-4">
          <span class="text-sm">{{ $t("user.order.invoiceAddress") }} :</span>
          <span class="text-sm">
            {{ order.invoiceAddress }} {{ order.invoiceCountry }} /
            {{ order.invoiceCity }} {{ order.invoiceTaxNumber }} /
            {{ order.invoiceTaxOffice }}
          </span>
        </li>
      </ul>
    </content-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { ContentWrapper } from "@/components";
import { CloudArrowDownIcon } from "@heroicons/vue/20/solid";
import { toCurrency } from "@/common/utils";

export default {
  name: "OrderDetailSummary",
  components: { ContentWrapper, CloudArrowDownIcon },
  data() {
    return {
      moment: moment,
      isLoading: false,
    };
  },
  props: {
    order: {},
  },
  methods: {
    toCurrency,
    CheckItemHasInovice(item) {
      return item.documentPath != null && item.documentPath != "";
    },
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
