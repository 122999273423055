import { ref, watchEffect } from "vue";

const useMedia = (query) => {
  const matches = ref(true);

  watchEffect((onInvalidate) => {
    const media = window.matchMedia(query);

    if (media.matches !== matches.value) {
      matches.value = media.matches;
    }

    const onChange = () => {
      matches.value = media.matches;
    };

    media.addEventListener("change", onChange);

    onInvalidate(() => {
      media.removeEventListener("change", onChange);
    });
  });

  return matches;
};

const mediaQuery = {
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
};


export const useMediaQuery = () => {
  const downSm = useMedia(mediaQuery.sm);
  const downMd = useMedia(mediaQuery.md);
  const downLg = useMedia(mediaQuery.lg);
  const downXl = useMedia(mediaQuery.lg);



  return { downSm, downMd, downLg,downXl };
};

