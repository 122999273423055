<template>
  <div class="flex justify-center items-center w-full h-full">
    <spinner-loader />
  </div>
</template>

<script>
import { UserManager, WebStorageStateStore } from 'oidc-client'
import { authService } from '@/common/services'
import router from '@/router'
import { SpinnerLoader } from '@/components'

export default {
  name: 'SerenasLogin',
  components: { SpinnerLoader },
  data() {
    return {
      authUser: null,
    }
  },
  mounted() {
    const mgr = new UserManager({
      response_mode: 'query',
      userStore: new WebStorageStateStore(undefined),
    })
    mgr
      .signinRedirectCallback()
      .then((user) => {
        this.setAuthUser(user)
      })
      .catch((err) => {
        console.debug(err)
      })
  },
  methods: {
    setAuthUser(user) {
      this.authUser = user
      authService
        .sync(user)
        .then(() => {
          router.push({ path: '/' })
        })
        .catch(() => {
          router.push({ name: 'login' })
        })
    },
  },
}
</script>
