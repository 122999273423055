<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead
      class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400"
    >
      <tr>
        <th scope="col" class="p-4">
          {{ $t('admin.congress.no') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('admin.congress.name') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('admin.congress.startDate') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('admin.congress.endDate') }}
        </th>
        <th scope="col" class="p-4">
          {{ $t('admin.congress.email') }}
        </th>
        <th scope="col" class="p-4"></th>
        <th scope="col" class="p-4">
          {{ $t('admin.congress.actions') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in congress.items"
        :key="item"
        :id="`row-${item.id}`"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td class="p-4">
          {{
            congress.pageNumber === 1
              ? congressIndex + index
              : pageSize + congressIndex + index
          }}
        </td>
        <th
          scope="row"
          class="p-4 font-medium text-gray-900 dark:text-white"
        >
          <p class="truncate w-[180px] 2xl:w-auto" :title="`${getLang === 'TR' ? item.name:item.nameEn}`">
            <span v-if="getLang === 'TR'">
              {{ item.name }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.nameEn }}
            </span>
          </p>
        </th>
        <td class="p-4 whitespace-nowrap">
          {{ moment(item.startDate).format('DD.MM.YYYY') }}
        </td>
        <td class="p-4 whitespace-nowrap">
          {{ moment(item.endDate).format('DD.MM.YYYY') }}
        </td>
        <td class="p-4">
          {{ item.contactEmail }}
        </td>
        <td class="p-4 text-center">
          <span
            v-if="item.isActive"
            class="block rounded bg-green-100 p-1 w-6 h-6"
          >
            <CheckCircleIcon class="h-4 w-4 text-green-800" />
          </span>
          <span v-else class="block rounded bg-red-100 p-1 w-6 h-6">
            <MinusCircleIcon class="h-4 w-4 text-red-800" />
          </span>
        </td>
        <td class="p-4">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'congress/edit/id', params: { id: item.id } }"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              :title="`${$t('shared.edit')}`"
            >
              <PencilSquareIcon class="w-4 h-4" />
            </router-link>
            <span class="px-2">|</span>
            <a
              @click="deleteCongress(item.id)"
              class="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"
              :title="`${$t('shared.delete')}`"
            >
              <TrashIcon class="w-4 h-4" />
            </a>
            <span class="px-2">|</span>
            <router-link
              :to="{ name: 'congress/contents/id', params: { id: item.id } }"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              :title="`${$t('shared.contents')}`"
            >
              <DocumentTextIcon class="w-4 h-4" />
            </router-link>
            <span class="px-2">|</span>
            <router-link
              :to="{ name: 'congress/detail', params: { id: item.id } }"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              :title="`${$t('shared.details')}`"
            >
              <InformationCircleIcon class="w-4 h-4" />
            </router-link>
          </div>
        </td>
        <!-- <tr class="bg-gray border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-400">
                    <td class="p-4"></td>
                    <td colspan="4" class="p-4">
                        <p>
                        Expand Content
                        </p>
                    </td>
                </tr> -->
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  CheckCircleIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
  DocumentTextIcon,
  InformationCircleIcon,
} from '@heroicons/vue/20/solid'
import moment from 'moment'
import { service } from '@/common/services'
import { useResponseToast, useToast } from '@/common/hooks'

export default {
  name: 'CongressTableList',
  components: {
    CheckCircleIcon,
    MinusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    DocumentTextIcon,
    InformationCircleIcon,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      moment: moment,
      congressIndex: 1,
    }
  },
  props: {
    congress: Object,
    pageSize: Number,
    payload: Object,
    dispatchCongressList: Function,
  },
  methods: {
    deleteCongress(id) {
      service.delete('congress', id).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.dispatchCongressList(this.payload)
          },
          errorCallback: () => {},
          isSaveMessage: true,
        })
      })
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>
