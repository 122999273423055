<template>
    <h2 class="text-2xl mb-6">{{ pageTitle }}</h2>
</template>
  
  <script>
  export default {
      name: "PageTitle",
      props:{
        pageTitle:String
      }
  }
  </script>
  