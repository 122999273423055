<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 206.87 206.87"
  >
    <g>
      <circle class="cls-33" cx="103.44" cy="178.26" r="28.61" />
      <circle class="cls-26" cx="106.6" cy="178.2" r="28.61" />
      <circle class="cls-29" cx="109.73" cy="178" r="28.61" />
      <circle class="cls-4" cx="112.82" cy="177.68" r="28.61" />
      <circle class="cls-3" cx="115.88" cy="177.23" r="28.61" />
      <circle class="cls-5" cx="118.89" cy="176.66" r="28.61" />
      <circle class="cls-2" cx="121.86" cy="175.98" r="28.61" />
      <circle class="cls-13" cx="124.78" cy="175.17" r="28.61" />
      <circle class="cls-21" cx="127.66" cy="174.25" r="28.61" />
      <circle class="cls-1" cx="130.48" cy="173.23" r="28.61" />
      <circle class="cls-19" cx="133.25" cy="172.09" r="28.61" />
      <circle class="cls-23" cx="135.96" cy="170.84" r="28.61" />
      <circle class="cls-24" cx="138.61" cy="169.49" r="28.61" />
      <circle class="cls-11" cx="141.2" cy="168.05" r="28.61" />
      <circle class="cls-31" cx="143.73" cy="166.5" r="28.61" />
      <circle class="cls-9" cx="146.19" cy="164.86" r="28.61" />
      <circle class="cls-17" cx="148.57" cy="163.12" r="28.61" />
      <circle class="cls-30" cx="150.89" cy="161.29" r="28.61" />
      <circle class="cls-10" cx="153.13" cy="159.38" r="28.61" />
      <circle class="cls-28" cx="155.29" cy="157.38" r="28.61" />
      <circle class="cls-28" cx="157.38" cy="155.29" r="28.61" />
      <circle class="cls-22" cx="159.38" cy="153.13" r="28.61" />
      <circle class="cls-18" cx="161.29" cy="150.89" r="28.61" />
      <circle class="cls-14" cx="163.12" cy="148.57" r="28.61" />
      <circle class="cls-12" cx="164.86" cy="146.19" r="28.61" />
      <circle class="cls-27" cx="166.5" cy="143.73" r="28.61" />
      <circle class="cls-32" cx="168.05" cy="141.2" r="28.61" />
      <circle class="cls-16" cx="169.49" cy="138.61" r="28.61" />
      <circle class="cls-15" cx="170.84" cy="135.96" r="28.61" />
      <circle class="cls-20" cx="172.09" cy="133.25" r="28.61" />
      <circle class="cls-132" cx="173.23" cy="130.48" r="28.61" />
      <circle class="cls-149" cx="174.25" cy="127.66" r="28.61" />
      <circle class="cls-155" cx="175.17" cy="124.78" r="28.61" />
      <circle class="cls-140" cx="175.98" cy="121.86" r="28.61" />
      <circle class="cls-148" cx="176.66" cy="118.89" r="28.61" />
      <circle class="cls-126" cx="177.23" cy="115.88" r="28.61" />
      <circle class="cls-125" cx="177.68" cy="112.82" r="28.61" />
      <circle class="cls-150" cx="178" cy="109.73" r="28.61" />
      <circle class="cls-147" cx="178.2" cy="106.6" r="28.61" />
      <circle class="cls-139" cx="178.26" cy="103.44" r="28.61" />
      <circle class="cls-141" cx="178.25" cy="102.05" r="28.61" />
      <circle class="cls-142" cx="178.21" cy="100.66" r="28.61" />
      <circle class="cls-105" cx="178.15" cy="99.28" r="28.61" />
      <circle class="cls-116" cx="178.06" cy="97.91" r="28.61" />
      <circle class="cls-153" cx="177.95" cy="96.55" r="28.61" />
      <circle class="cls-154" cx="177.81" cy="95.19" r="28.61" />
      <circle class="cls-156" cx="177.65" cy="93.84" r="28.61" />
      <circle class="cls-137" cx="177.47" cy="92.5" r="28.61" />
      <circle class="cls-138" cx="177.26" cy="91.17" r="28.61" />
      <circle class="cls-136" cx="177.03" cy="89.84" r="28.61" />
      <circle class="cls-128" cx="176.78" cy="88.52" r="28.61" />
      <circle class="cls-129" cx="176.5" cy="87.21" r="28.61" />
      <circle class="cls-123" cx="176.2" cy="85.91" r="28.61" />
      <circle class="cls-122" cx="175.87" cy="84.61" r="28.61" />
      <circle class="cls-90" cx="175.53" cy="83.33" r="28.61" />
      <circle class="cls-87" cx="175.16" cy="82.06" r="28.61" />
      <circle class="cls-89" cx="174.77" cy="80.79" r="28.61" />
      <circle class="cls-88" cx="174.36" cy="79.53" r="28.61" />
      <circle class="cls-118" cx="173.93" cy="78.29" r="28.61" />
      <circle class="cls-127" cx="173.48" cy="77.05" r="28.61" />
      <circle class="cls-119" cx="173" cy="75.83" r="28.61" />
      <circle class="cls-120" cx="172.51" cy="74.61" r="28.61" />
      <circle class="cls-121" cx="171.99" cy="73.41" r="28.61" />
      <circle class="cls-151" cx="171.46" cy="72.21" r="28.61" />
      <circle class="cls-152" cx="170.9" cy="71.03" r="28.61" />
      <circle class="cls-124" cx="170.32" cy="69.86" r="28.61" />
      <circle class="cls-131" cx="169.73" cy="68.7" r="28.61" />
      <circle class="cls-133" cx="169.11" cy="67.56" r="28.61" />
      <circle class="cls-146" cx="168.48" cy="66.42" r="28.61" />
      <circle class="cls-145" cx="167.83" cy="65.3" r="28.61" />
      <circle class="cls-130" cx="167.15" cy="64.19" r="28.61" />
      <circle class="cls-70" cx="166.46" cy="63.09" r="28.61" />
      <circle class="cls-69" cx="165.75" cy="62" r="28.61" />
      <circle class="cls-75" cx="165.03" cy="60.93" r="28.61" />
      <circle class="cls-114" cx="164.28" cy="59.87" r="28.61" />
      <circle class="cls-96" cx="163.52" cy="58.83" r="28.61" />
      <circle class="cls-95" cx="162.74" cy="57.8" r="28.61" />
      <circle class="cls-117" cx="161.94" cy="56.78" r="28.61" />
      <circle class="cls-115" cx="161.12" cy="55.78" r="28.61" />
      <circle class="cls-99" cx="160.29" cy="54.79" r="28.61" />
      <circle class="cls-98" cx="159.44" cy="53.82" r="28.61" />
      <circle class="cls-109" cx="158.58" cy="52.86" r="28.61" />
      <circle class="cls-79" cx="157.7" cy="51.91" r="28.61" />
      <circle class="cls-82" cx="156.8" cy="50.98" r="28.61" />
      <circle class="cls-83" cx="155.89" cy="50.07" r="28.61" />
      <circle class="cls-65" cx="154.96" cy="49.17" r="28.61" />
      <circle class="cls-59" cx="154.02" cy="48.29" r="28.61" />
      <circle class="cls-76" cx="153.06" cy="47.43" r="28.61" />
      <circle class="cls-64" cx="152.08" cy="46.58" r="28.61" />
      <circle class="cls-60" cx="151.09" cy="45.75" r="28.61" />
      <circle class="cls-58" cx="150.09" cy="44.93" r="28.61" />
      <circle class="cls-74" cx="149.07" cy="44.14" r="28.61" />
      <circle class="cls-73" cx="148.04" cy="43.35" r="28.61" />
      <circle class="cls-104" cx="147" cy="42.59" r="28.61" />
      <circle class="cls-107" cx="145.94" cy="41.85" r="28.61" />
      <circle class="cls-103" cx="144.87" cy="41.12" r="28.61" />
      <circle class="cls-101" cx="143.78" cy="40.41" r="28.61" />
      <circle class="cls-102" cx="142.69" cy="39.72" r="28.61" />
      <circle class="cls-143" cx="141.58" cy="39.05" r="28.61" />
      <circle class="cls-144" cx="140.45" cy="38.39" r="28.61" />
      <circle class="cls-91" cx="139.32" cy="37.76" r="28.61" />
      <circle class="cls-106" cx="138.17" cy="37.14" r="28.61" />
      <circle class="cls-134" cx="137.01" cy="36.55" r="28.61" />
      <circle class="cls-135" cx="135.84" cy="35.97" r="28.61" />
      <circle class="cls-94" cx="134.66" cy="35.41" r="28.61" />
      <circle class="cls-97" cx="133.46" cy="34.88" r="28.61" />
      <circle class="cls-112" cx="132.26" cy="34.36" r="28.61" />
      <circle class="cls-113" cx="131.04" cy="33.87" r="28.61" />
      <circle class="cls-92" cx="129.82" cy="33.39" r="28.61" />
      <circle class="cls-111" cx="128.58" cy="32.94" r="28.61" />
      <circle class="cls-100" cx="127.34" cy="32.51" r="28.61" />
      <circle class="cls-93" cx="126.08" cy="32.1" r="28.61" />
      <circle class="cls-108" cx="124.82" cy="31.71" r="28.61" />
      <circle class="cls-110" cx="123.54" cy="31.34" r="28.61" />
      <circle class="cls-163" cx="122.26" cy="31" r="28.61" />
      <circle class="cls-158" cx="120.96" cy="30.67" r="28.61" />
      <circle class="cls-164" cx="119.66" cy="30.37" r="28.61" />
      <circle class="cls-67" cx="118.35" cy="30.1" r="28.61" />
      <circle class="cls-54" cx="117.03" cy="29.84" r="28.61" />
      <circle class="cls-55" cx="115.71" cy="29.61" r="28.61" />
      <circle class="cls-52" cx="114.37" cy="29.4" r="28.61" />
      <circle class="cls-66" cx="113.03" cy="29.22" r="28.61" />
      <circle class="cls-62" cx="111.68" cy="29.06" r="28.61" />
      <circle class="cls-63" cx="110.32" cy="28.92" r="28.61" />
      <circle class="cls-50" cx="108.96" cy="28.81" r="28.61" />
      <circle class="cls-172" cx="107.59" cy="28.72" r="28.61" />
      <circle class="cls-166" cx="106.21" cy="28.66" r="28.61" />
      <circle class="cls-167" cx="104.83" cy="28.62" r="28.61" />
      <circle class="cls-42" cx="103.44" cy="28.61" r="28.61" />
      <circle class="cls-44" cx="99.7" cy="28.7" r="28.61" />
      <circle class="cls-46" cx="96.01" cy="28.97" r="28.61" />
      <circle class="cls-41" cx="92.38" cy="29.42" r="28.61" />
      <circle class="cls-162" cx="88.8" cy="30.04" r="28.61" />
      <circle class="cls-161" cx="85.28" cy="30.83" r="28.61" />
      <circle class="cls-157" cx="81.83" cy="31.78" r="28.61" />
      <circle class="cls-86" cx="78.44" cy="32.89" r="28.61" />
      <circle class="cls-51" cx="75.13" cy="34.15" r="28.61" />
      <circle class="cls-68" cx="71.89" cy="35.56" r="28.61" />
      <circle class="cls-72" cx="68.74" cy="37.12" r="28.61" />
      <circle class="cls-71" cx="65.67" cy="38.83" r="28.61" />
      <circle class="cls-80" cx="62.69" cy="40.66" r="28.61" />
      <circle class="cls-81" cx="59.81" cy="42.64" r="28.61" />
      <circle class="cls-84" cx="57.03" cy="44.74" r="28.61" />
      <circle class="cls-85" cx="54.34" cy="46.96" r="28.61" />
      <circle class="cls-49" cx="51.77" cy="49.31" r="28.61" />
      <circle class="cls-61" cx="49.31" cy="51.77" r="28.61" />
      <circle class="cls-168" cx="46.96" cy="54.34" r="28.61" />
      <circle class="cls-169" cx="44.74" cy="57.03" r="28.61" />
      <circle class="cls-171" cx="42.64" cy="59.81" r="28.61" />
      <circle class="cls-170" cx="40.66" cy="62.69" r="28.61" />
      <circle class="cls-57" cx="38.83" cy="65.67" r="28.61" />
      <circle class="cls-56" cx="37.12" cy="68.74" r="28.61" />
      <circle class="cls-53" cx="35.56" cy="71.89" r="28.61" />
      <circle class="cls-207" cx="34.15" cy="75.13" r="28.61" />
      <circle class="cls-209" cx="32.89" cy="78.44" r="28.61" />
      <circle class="cls-39" cx="31.78" cy="81.83" r="28.61" />
      <circle class="cls-178" cx="30.83" cy="85.28" r="28.61" />
      <circle class="cls-177" cx="30.04" cy="88.8" r="28.61" />
      <circle class="cls-48" cx="29.42" cy="92.38" r="28.61" />
      <circle class="cls-36" cx="28.97" cy="96.01" r="28.61" />
      <circle class="cls-34" cx="28.7" cy="99.7" r="28.61" />
      <circle class="cls-45" cx="28.61" cy="103.44" r="28.61" />
      <circle class="cls-203" cx="28.61" cy="104.87" r="28.61" />
      <circle class="cls-210" cx="28.61" cy="106.31" r="28.61" />
      <circle class="cls-213" cx="28.61" cy="107.75" r="28.61" />
      <circle class="cls-201" cx="28.61" cy="109.19" r="28.61" />
      <circle class="cls-200" cx="28.61" cy="110.63" r="28.61" />
      <circle class="cls-205" cx="28.61" cy="112.07" r="28.61" />
      <circle class="cls-199" cx="28.61" cy="113.51" r="28.61" />
      <circle class="cls-198" cx="28.61" cy="114.95" r="28.61" />
      <circle class="cls-38" cx="28.61" cy="116.39" r="28.61" />
      <circle class="cls-37" cx="28.61" cy="117.83" r="28.61" />
      <circle class="cls-78" cx="28.61" cy="119.26" r="28.61" />
      <circle class="cls-160" cx="28.61" cy="120.7" r="28.61" />
      <circle class="cls-173" cx="28.61" cy="122.14" r="28.61" />
      <circle class="cls-174" cx="28.61" cy="123.58" r="28.61" />
      <circle class="cls-175" cx="28.61" cy="125.02" r="28.61" />
      <circle class="cls-159" cx="28.61" cy="126.46" r="28.61" />
      <circle class="cls-176" cx="28.61" cy="127.9" r="28.61" />
      <circle class="cls-43" cx="28.61" cy="129.34" r="28.61" />
      <circle class="cls-47" cx="28.61" cy="130.78" r="28.61" />
      <circle class="cls-35" cx="28.61" cy="132.22" r="28.61" />
      <circle class="cls-6" cx="28.61" cy="133.65" r="28.61" />
      <circle class="cls-7" cx="28.61" cy="135.09" r="28.61" />
      <circle class="cls-8" cx="28.61" cy="136.53" r="28.61" />
      <circle class="cls-77" cx="28.61" cy="137.97" r="28.61" />
      <circle class="cls-40" cx="28.61" cy="139.41" r="28.61" />
      <circle class="cls-180" cx="28.61" cy="140.85" r="28.61" />
      <circle class="cls-181" cx="28.61" cy="142.29" r="28.61" />
      <circle class="cls-194" cx="28.61" cy="143.73" r="28.61" />
      <circle class="cls-191" cx="28.61" cy="145.17" r="28.61" />
      <circle class="cls-195" cx="28.61" cy="146.6" r="28.61" />
      <circle class="cls-196" cx="28.61" cy="148.04" r="28.61" />
      <circle class="cls-193" cx="28.61" cy="149.48" r="28.61" />
      <circle class="cls-202" cx="28.61" cy="150.92" r="28.61" />
      <circle class="cls-186" cx="28.61" cy="152.36" r="28.61" />
      <circle class="cls-182" cx="28.61" cy="153.8" r="28.61" />
      <circle class="cls-183" cx="28.61" cy="155.24" r="28.61" />
      <circle class="cls-187" cx="28.61" cy="156.68" r="28.61" />
      <circle class="cls-185" cx="28.61" cy="158.12" r="28.61" />
      <circle class="cls-184" cx="28.61" cy="159.56" r="28.61" />
      <circle class="cls-188" cx="28.61" cy="160.99" r="28.61" />
      <circle class="cls-189" cx="28.61" cy="162.43" r="28.61" />
      <circle class="cls-179" cx="28.61" cy="163.87" r="28.61" />
      <circle class="cls-197" cx="28.61" cy="165.31" r="28.61" />
      <circle class="cls-165" cx="28.61" cy="166.75" r="28.61" />
      <circle class="cls-204" cx="28.61" cy="168.19" r="28.61" />
      <circle class="cls-212" cx="28.61" cy="169.63" r="28.61" />
      <circle class="cls-206" cx="28.61" cy="171.07" r="28.61" />
      <circle class="cls-208" cx="28.61" cy="172.51" r="28.61" />
      <circle class="cls-214" cx="28.61" cy="173.94" r="28.61" />
      <circle class="cls-211" cx="28.61" cy="175.38" r="28.61" />
      <circle class="cls-190" cx="28.61" cy="176.82" r="28.61" />
      <circle class="cls-192" cx="28.61" cy="178.26" r="28.61" />
    </g>
    <g>
      <circle class="cls-25" cx="103.44" cy="103.44" r="28.61" />
      <circle class="cls-25" cx="103.44" cy="103.44" r="17.61" />
    </g>
  </svg>
</template>

<style>
.cls-1 {
  fill: #f71755;
}

.cls-2 {
  fill: #fa1952;
}

.cls-3 {
  fill: #fc1b50;
}

.cls-4 {
  fill: #fc1c4f;
}

.cls-5 {
  fill: #fb1a51;
}

.cls-6 {
  fill: #6a17e1;
}

.cls-7 {
  fill: #6918e0;
}

.cls-8 {
  fill: #6819e0;
}

.cls-9 {
  fill: #f2125b;
}

.cls-10 {
  fill: #ef105e;
}

.cls-11 {
  fill: #f41459;
}

.cls-12 {
  fill: #ea0c63;
}

.cls-13 {
  fill: #f91953;
}

.cls-14 {
  fill: #eb0c62;
}

.cls-15 {
  fill: #e70867;
}

.cls-16 {
  fill: #e70966;
}

.cls-17 {
  fill: #f1125c;
}

.cls-18 {
  fill: #ec0d61;
}

.cls-19 {
  fill: #f61656;
}

.cls-20 {
  fill: #e60868;
}

.cls-21 {
  fill: #f81854;
}

.cls-22 {
  fill: #ed0e60;
}

.cls-23 {
  fill: #f51657;
}

.cls-24 {
  fill: #f51558;
}

.cls-25 {
  fill: #ffb600;
}

.cls-26 {
  fill: #fe1d4d;
}

.cls-27 {
  fill: #e90b64;
}

.cls-28 {
  fill: #ee0f5f;
}

.cls-29 {
  fill: #fd1c4e;
}

.cls-30 {
  fill: #f0115d;
}

.cls-31 {
  fill: #f3135a;
}

.cls-32 {
  fill: #e80a65;
}

.cls-33 {
  fill: #ff1e4c;
}

.cls-34 {
  fill: #7f03e9;
}

.cls-35 {
  fill: #6b16e1;
}

.cls-36 {
  fill: #8003e8;
}

.cls-37 {
  fill: #740ce6;
}

.cls-38 {
  fill: #750be6;
}

.cls-39 {
  fill: #8306e4;
}

.cls-40 {
  fill: #661bdf;
}

.cls-41 {
  fill: #9815cd;
}

.cls-42 {
  fill: #9b17ca;
}

.cls-43 {
  fill: #6d14e2;
}

.cls-44 {
  fill: #9a16cb;
}

.cls-45 {
  fill: #7e02ea;
}

.cls-46 {
  fill: #9916cc;
}

.cls-47 {
  fill: #6c15e2;
}

.cls-48 {
  fill: #8104e7;
}

.cls-49 {
  fill: #8d0dda;
}

.cls-50 {
  fill: #9e16c6;
}

.cls-51 {
  fill: #9412d2;
}

.cls-52 {
  fill: #a115c2;
}

.cls-53 {
  fill: #8608e1;
}

.cls-54 {
  fill: #a214c0;
}

.cls-55 {
  fill: #a215c1;
}

.cls-56 {
  fill: #8708e0;
}

.cls-57 {
  fill: #8809df;
}

.cls-58 {
  fill: #b70da4;
}

.cls-59 {
  fill: #ba0ca0;
}

.cls-60 {
  fill: #b80da3;
}

.cls-61 {
  fill: #8c0cda;
}

.cls-62 {
  fill: #9f15c4;
}

.cls-63 {
  fill: #9f16c5;
}

.cls-64 {
  fill: #b90da2;
}

.cls-65 {
  fill: #bb0c9f;
}

.cls-66 {
  fill: #a015c3;
}

.cls-67 {
  fill: #a314bf;
}

.cls-68 {
  fill: #9311d3;
}

.cls-69 {
  fill: #c50993;
}

.cls-70 {
  fill: #c50892;
}

.cls-71 {
  fill: #9110d5;
}

.cls-72 {
  fill: #9211d4;
}

.cls-73 {
  fill: #b60ea6;
}

.cls-74 {
  fill: #b60da5;
}

.cls-75 {
  fill: #c40994;
}

.cls-76 {
  fill: #b90ca1;
}

.cls-77 {
  fill: #671adf;
}

.cls-78 {
  fill: #730de5;
}

.cls-79 {
  fill: #bd0b9d;
}

.cls-80 {
  fill: #900fd6;
}

.cls-81 {
  fill: #900fd7;
}

.cls-82 {
  fill: #bc0b9e;
}

.cls-83 {
  fill: #bc0c9e;
}

.cls-84 {
  fill: #8f0ed8;
}

.cls-85 {
  fill: #8e0dd9;
}

.cls-86 {
  fill: #9513d1;
}

.cls-87 {
  fill: #d10482;
}

.cls-88 {
  fill: #d00584;
}

.cls-89 {
  fill: #d00483;
}

.cls-90 {
  fill: #d20481;
}

.cls-91 {
  fill: #b010ae;
}

.cls-92 {
  fill: #aa12b6;
}

.cls-93 {
  fill: #a813b9;
}

.cls-94 {
  fill: #ad11b2;
}

.cls-95 {
  fill: #c20a97;
}

.cls-96 {
  fill: #c20996;
}

.cls-97 {
  fill: #ac11b3;
}

.cls-98 {
  fill: #bf0b9b;
}

.cls-99 {
  fill: #bf0a9a;
}

.cls-100 {
  fill: #a812b8;
}

.cls-101 {
  fill: #b30faa;
}

.cls-102 {
  fill: #b20fab;
}

.cls-103 {
  fill: #b30ea9;
}

.cls-104 {
  fill: #b50ea7;
}

.cls-105 {
  fill: #db0175;
}

.cls-106 {
  fill: #af10af;
}

.cls-107 {
  fill: #b40ea8;
}

.cls-108 {
  fill: #a713ba;
}

.cls-109 {
  fill: #be0b9c;
}

.cls-110 {
  fill: #a613bb;
}

.cls-111 {
  fill: #a912b7;
}

.cls-112 {
  fill: #ab11b4;
}

.cls-113 {
  fill: #ab12b5;
}

.cls-114 {
  fill: #c30995;
}

.cls-115 {
  fill: #c00a99;
}

.cls-116 {
  fill: #da0176;
}

.cls-117 {
  fill: #c10a98;
}

.cls-118 {
  fill: #cf0585;
}

.cls-119 {
  fill: #cd0587;
}

.cls-120 {
  fill: #cd0688;
}

.cls-121 {
  fill: #cc0689;
}

.cls-122 {
  fill: #d30480;
}

.cls-123 {
  fill: #d3037f;
}

.cls-124 {
  fill: #ca078c;
}

.cls-125 {
  fill: #e0026f;
}

.cls-126 {
  fill: #e0036e;
}

.cls-127 {
  fill: #ce0586;
}

.cls-128 {
  fill: #d5037d;
}

.cls-129 {
  fill: #d4037e;
}

.cls-130 {
  fill: #c60891;
}

.cls-131 {
  fill: #c9078d;
}

.cls-132 {
  fill: #e50769;
}

.cls-133 {
  fill: #c8078e;
}

.cls-134 {
  fill: #ae10b0;
}

.cls-135 {
  fill: #ae11b1;
}

.cls-136 {
  fill: #d6037c;
}

.cls-137 {
  fill: #d7027a;
}

.cls-138 {
  fill: #d6027b;
}

.cls-139 {
  fill: #dd0072;
}

.cls-140 {
  fill: #e2056c;
}

.cls-141 {
  fill: #dc0073;
}

.cls-142 {
  fill: #dc0174;
}

.cls-143 {
  fill: #b10fac;
}

.cls-144 {
  fill: #b110ad;
}

.cls-145 {
  fill: #c70890;
}

.cls-146 {
  fill: #c7078f;
}

.cls-147 {
  fill: #de0171;
}

.cls-148 {
  fill: #e1046d;
}

.cls-149 {
  fill: #e4066a;
}

.cls-150 {
  fill: #df0270;
}

.cls-151 {
  fill: #cb068a;
}

.cls-152 {
  fill: #ca068b;
}

.cls-153 {
  fill: #d90177;
}

.cls-154 {
  fill: #d90278;
}

.cls-155 {
  fill: #e3056b;
}

.cls-156 {
  fill: #d80279;
}

.cls-157 {
  fill: #9613d0;
}

.cls-158 {
  fill: #a514bd;
}

.cls-159 {
  fill: #6f12e3;
}

.cls-160 {
  fill: #720ee5;
}

.cls-161 {
  fill: #9714cf;
}

.cls-162 {
  fill: #9714ce;
}

.cls-163 {
  fill: #a513bc;
}

.cls-164 {
  fill: #a414be;
}

.cls-165 {
  fill: #542dd7;
}

.cls-166 {
  fill: #9c16c8;
}

.cls-167 {
  fill: #9c17c9;
}

.cls-168 {
  fill: #8b0cdb;
}

.cls-169 {
  fill: #8a0bdc;
}

.cls-170 {
  fill: #890ade;
}

.cls-171 {
  fill: #890add;
}

.cls-172 {
  fill: #9d16c7;
}

.cls-173 {
  fill: #710fe4;
}

.cls-174 {
  fill: #7110e4;
}

.cls-175 {
  fill: #7011e3;
}

.cls-176 {
  fill: #6e13e2;
}

.cls-177 {
  fill: #8205e6;
}

.cls-178 {
  fill: #8205e5;
}

.cls-179 {
  fill: #562bd7;
}

.cls-180 {
  fill: #651bdf;
}

.cls-181 {
  fill: #641cde;
}

.cls-182 {
  fill: #5c24db;
}

.cls-183 {
  fill: #5b25da;
}

.cls-184 {
  fill: #5928d9;
}

.cls-185 {
  fill: #5927d9;
}

.cls-186 {
  fill: #5d23db;
}

.cls-187 {
  fill: #5a26da;
}

.cls-188 {
  fill: #5829d8;
}

.cls-189 {
  fill: #572ad8;
}

.cls-190 {
  fill: #4d34d3;
}

.cls-191 {
  fill: #621edd;
}

.cls-192 {
  fill: #4c35d3;
}

.cls-193 {
  fill: #5f21dc;
}

.cls-194 {
  fill: #631dde;
}

.cls-195 {
  fill: #611fdd;
}

.cls-196 {
  fill: #6020dc;
}

.cls-197 {
  fill: #552cd7;
}

.cls-198 {
  fill: #760ae6;
}

.cls-199 {
  fill: #7709e7;
}

.cls-200 {
  fill: #7907e8;
}

.cls-201 {
  fill: #7a06e8;
}

.cls-202 {
  fill: #5e22db;
}

.cls-203 {
  fill: #7d03ea;
}

.cls-204 {
  fill: #532ed6;
}

.cls-205 {
  fill: #7808e7;
}

.cls-206 {
  fill: #5130d5;
}

.cls-207 {
  fill: #8507e2;
}

.cls-208 {
  fill: #5031d5;
}

.cls-209 {
  fill: #8406e3;
}

.cls-210 {
  fill: #7c04e9;
}

.cls-211 {
  fill: #4e33d4;
}

.cls-212 {
  fill: #522fd6;
}

.cls-213 {
  fill: #7b05e9;
}

.cls-214 {
  fill: #4f32d4;
}
</style>
