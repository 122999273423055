<template>
    <div class="flex justify-between items-center">
        <button type="button" @click="navigateBefore" class="flex gap-1 items-center justify-center py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2
            focus:ring-offset-2 focus:ring-green-500" style="font-size:smaller;padding-right:4px;">
            <ChevronLeftIcon class="w-5 h-5" />
            {{ this.$t('wizard.order.navigation.final.before') }}
        </button>
        <button type="button" @click="this.navigateCart" class="flex gap-1 items-center justify-center py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2
            focus:ring-offset-2 focus:ring-green-500" style="font-size:smaller;padding-left:4px;">
            {{ this.$t('wizard.order.navigation.final.cart') }}
            <ChevronRightIcon class="w-5 h-5" />
        </button>
    </div>
    <div class="flex justify-end items-end p-3">
        <button type="button" @click="navigateStart" class="flex gap-2 items-center justify-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-green-500">
            {{ this.$t('wizard.order.navigation.final.new') }}
            <PlusCircleIcon class="w-5 h-5" />
        </button>
    </div>
</template>


<script>

import { ChevronRightIcon, ChevronLeftIcon, PlusCircleIcon } from '@heroicons/vue/24/outline'
import router from '@/router'

export default {
    components: {
        ChevronRightIcon,
        ChevronLeftIcon,
        PlusCircleIcon,
    },
    props: {
        navigateBefore: Function,
        navigateStart: Function,
    },
    methods: {
        navigateCart() {
            router.push({ name: 'basket' });
        },
    },
}
</script>
