<template>
  <div class="flex flex-col gap-2 items-center mb-4">
    <template v-if="!order.documentPath">
      <ExclamationCircleIcon class="w-16 h-16 text-yellow-500" />
      <h2 class="text-2xl mb-4">
        {{ $t('user.order.receiptDescription') }}
      </h2>
    </template>
    <div class="flex flex-col lg:flex-row gap-2">
      <input
        class="file-upload block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        id="documentPath"
        @change="changeImage"
        type="file"
      />
      <button-with-loader
        :isLoading="isLoading"
        type="button"
        @click="dispatchOrderUploadDocument"
        class="min-w-[250px] inline-flex gap-1 justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        <CloudArrowUpIcon class="w-5 h-5 text-white" />
        <span>{{ $t('user.order.uploadReceipt') }}</span>
      </button-with-loader>
    </div>
  </div>
</template>

<script>
import { service } from '@/common/services'
import { useResponseToast, useToast } from '@/common/hooks'
import { ButtonWithLoader } from '@/components'
import {
  ExclamationCircleIcon,
  CloudArrowUpIcon,
} from '@heroicons/vue/20/solid'
export default {
  name: 'OrderDetailAction',
  components: { ExclamationCircleIcon, CloudArrowUpIcon, ButtonWithLoader },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      documentPath: null,
      fileName: null,
      isLoading: false,
    }
  },
  props: {
    fetchOrder: Function,
    order: {},
  },
  methods: {
    changeImage($event) {
      const selectedImage = $event.target.files[0]
      this.fileName = selectedImage.name
      this.createBase64Image(selectedImage)
    },
    createBase64Image(fileObject) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const base64String = e.target.result.split('base64,')
        this.documentPath = base64String[1]
      }
      reader.readAsDataURL(fileObject)
    },
    dispatchOrderUploadDocument() {
      this.isLoading = true
      const payload = {
        orderId: this.$route.params.id,
        fileName: this.fileName,
        fileBase64String: this.documentPath,
      }
      service
        .dispatch('order/upload-document', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.fetchOrder()
            },
            errorCallback: () => {},
            isSaveMessage: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.file-upload {
  &::-webkit-file-upload-button {
    @apply bg-blue-700;
  }
}
</style>
