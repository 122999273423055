<template>
  <form>
    <div class="flex flex-wrap flex-col lg:flex-row">
      <div class="w-full">
        <div class="flex-auto mb-6">
          <label
            for="insideDesc"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.insideDesc') }}
          </label>
          <ckeditor :editor="editor" v-model="formData.insideDesc" :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="insideDescEn"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.insideDescEn') }}
          </label>
          <ckeditor :editor="editor" v-model="formData.insideDescEn" :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="outsideDesc"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.outsideDesc') }}
          </label>
          <ckeditor :editor="editor" v-model="formData.outsideDesc" :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="outsideDescEn"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.outsideDescEn') }}
          </label>
          <ckeditor
            :editor="editor"
            v-model="formData.outsideDescEn"
           :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="accommodationDesc"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.accommodationDesc') }}
          </label>
          <ckeditor
            :editor="editor"
            v-model="formData.accommodationDesc"
           :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="accommodationDescEn"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.accommodationDescEn') }}
          </label>
          <ckeditor
            :editor="editor"
            v-model="formData.accommodationDescEn"
           :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="transferDesc"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.transferDesc') }}
          </label>
          <ckeditor :editor="editor" v-model="formData.transferDesc" :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="transferDescEn"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.transferDescEn') }}
          </label>
          <ckeditor
            :editor="editor"
            v-model="formData.transferDescEn"
           :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="courseDesc"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.courseDesc') }}
          </label>
          <ckeditor :editor="editor" v-model="formData.courseDesc" :config="editorConfig"></ckeditor>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="courseDescEn"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('admin.congress.courseDescEn') }}
          </label>
          <ckeditor :editor="editor" v-model="formData.courseDescEn" :config="editorConfig"></ckeditor>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button-with-loader
        :isLoading="isLoading"
        type="button"
        @click="save"
        class="w-32 inline-flex gap-1 items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        <CustomIcon name="save-icon" />
        {{ $t('shared.save') }}
      </button-with-loader>
    </div>
  </form>
</template>

<script>
import { useResponseToast, useToast } from '@/common/hooks'
import router from '@/router'
import { service } from '@/common/services'
import { ButtonWithLoader, CustomIcon } from '@/components'
import CKEditor from '@ckeditor/ckeditor5-vue'
import Editor from '@/common/packages/ckeditor5/ckeditor.js'

export default {
  name: 'CongressContentsForm',
  components: { ButtonWithLoader, CustomIcon, ckeditor: CKEditor.component },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      editor: Editor,
      editorConfig: {
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
      },
      formData: {},
      isLoading: false,
      congressId: this.$route.params.id,
    }
  },
  props: {
    propFormData: {},
  },
  watch: {
    formData: function (data) {
      return data
    },
  },
  mounted() {
    this.formData = this.propFormData
  },
  methods: {
    save() {
      this.isLoading = true
      const payload = {
        ...this.formData,
        congressId: this.congressId,
      }
      service
        .dispatch('congress/set-contents', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              router.push({ name: 'congress' })
            },
            errorCallback: () => {},
            isSaveMessage: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped></style>
