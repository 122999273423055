<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.productType") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.name") }} -
          {{ $t("admin.congressDetail.participantType") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.price") }}
        </th>

        <th scope="col" class="p-4">KDV</th>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.stockTitle") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.startDate") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.endDate") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.lastRegistrationDate") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("admin.congress.actions") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in congressDetail" :key="item.id"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td class="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          <span v-if="item.productType === 1">
            {{ $t("admin.congressDetail.inside") }}
          </span>
          <span v-if="item.productType === 2">
            {{ $t("admin.congressDetail.outside") }}
          </span>
          <span v-if="item.productType === 3">
            {{ $t("admin.congressDetail.accommodation") }}
          </span>
          <span v-if="item.productType === 4">
            {{ $t("admin.congressDetail.transfer") }}
          </span>
          <span v-if="item.productType === 5">
            {{ $t("admin.congressDetail.course") }}
          </span>
        </td>
        <td class="p-4 font-medium text-gray-900 dark:text-white">
          <p class="truncate w-[180px] 2xl:w-auto" :title="`${getLang === 'TR'
            ? `${item.name} • ${item.participantType}`
            : `${item.nameEn} • ${item.participantTypeEn}`
            }`">
            <span v-if="getLang === 'TR'">
              {{ item.name }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.nameEn }}
            </span>
            <br />
            <span v-if="getLang === 'TR'" class="text-gray-500">
              {{ item.participantType }}
            </span>
            <span v-if="getLang === 'EN'" class="text-gray-500">
              {{ item.participantTypeEn }}
            </span>
          </p>
        </td>
        <th class="p-4">
          {{ toCurrency(item.price, "tr-TR", "currency", getCongressProductCurrency(item.productCurrency)) }}
          <span class="text-gray-500 text-xs font-light" v-if="item.pricePerDay && item.pricePerDay > 0">
            <br />
            {{ toCurrency(item.pricePerDay, "tr-TR", "currency", getCongressProductCurrency(item.productCurrency)) }} /
            {{ $t("admin.congressDetail.day") }}
          </span>
        </th>

        <th class="p-4"><span v-if="item.vatRate != 0">%{{ item.vatRate }}</span></th>

        <td class="p-4 font-medium text-gray-900 dark:text-white">

          <p class="truncate w-[180px] 2xl:w-auto" v-if="item.stockQuantity != null && item.stockQuantity != 0">
            <span>
              {{ $t("admin.congressDetail.stockQuantity") }} : {{ item.stockQuantity }}
            </span>
            <br />

            <span class="text-gray-500">
              {{ $t("admin.congressDetail.stockSoldQuantity") }} : {{ item.soldQuantity }}
            </span>
            <br>

            <span class="text-gray-500">
              {{ $t("admin.congressDetail.stockReservedQuantity") }} : {{ item.reservedQuantity }}
            </span>
            <br>

            <span class="text-gray-500">
              {{ $t("admin.congressDetail.stockLeft") }} : {{ item.stockQuantity - (item.soldQuantity + item.reservedQuantity) }}
            </span>
          </p>
          <p v-else-if="item.stockQuantity == 0">
            {{ $t("admin.congressDetail.outOfStock") }}
          </p>
          <p v-else>
            {{ $t("admin.congressDetail.stockUnlimited") }}
          </p>
        </td>

        <td class="p-4 whitespace-nowrap">
          {{ moment(item.startDate).format("DD.MM.YYYY") }}
        </td>
        <td class="p-4 whitespace-nowrap">
          {{ moment(item.endDate).format("DD.MM.YYYY") }}
        </td>
        <td class="p-4 whitespace-nowrap">
          {{ moment(item.lastRegistrationDate).format("DD.MM.YYYY") }}
        </td>

        <td class="p-4">
          <div class="flex">
            <a @click="duplicateItem(item.id)"
              class="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
              :title="`${$t('shared.duplicate')}`">
              <DocumentDuplicateIcon class="w-4 h-4" />
            </a>
            <span class="px-2">|</span>
            <a @click="openModal(item.id)"
              class="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
              :title="`${$t('shared.edit')}`">
              <PencilSquareIcon class="w-4 h-4" />
            </a>
            <span class="px-2">|</span>
            <a @click="deleteCongressDetail(item.id)"
              class="cursor-pointer flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
              :title="`${$t('shared.delete')}`">
              <TrashIcon class="w-4 h-4" />
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div id="edit-congress-detail-modal" tabindex="-1" aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <!-- Modal content -->
      <div class="modal-scroll relative bg-white rounded-lg shadow h-full md:h-[650px] overflow-auto dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
            {{ $t("admin.congressDetail.editCongressDetail") }}
          </h3>
          <button type="button" @click="closeModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
            <XMarkIcon class="w-5 h-5" />
          </button>
        </div>
        <!-- Modal body -->
        <div v-if="formData" class="p-6 space-y-6">
          <congress-detail-edit-form :propFormData="formData" :dispatchCongressDetailListByFilter="dispatchCongressDetailListByFilter
            " :detailId="detailId" :closeModal="closeModal" :payload="payload" :currency="currency" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { useResponseToast, useToast } from "@/common/hooks";
import { service } from "@/common/services";
import CongressDetailEditForm from "./CongressDetailEditForm";
import { toCurrency } from "@/common/utils";
import {
  XMarkIcon,
  PencilSquareIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/20/solid";

export default {
  name: "CongressDetailTableList",
  components: {
    CongressDetailEditForm,
    XMarkIcon,
    PencilSquareIcon,
    TrashIcon,
    DocumentDuplicateIcon,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      moment: moment,
      modal: null,
      formData: {},
      detailId: null,
    };
  },
  mounted() {
    this.initModal();
  },
  props: {
    congressDetail: Object,
    dispatchCongressDetailListByFilter: Function,
    payload: {},
    currency: Number,
  },
  watch: {
    congressDetail: function (data) {
      return data;
    },
  },
  methods: {
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    deleteCongressDetail(id) {
      service.delete("congress-detail", id).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.dispatchCongressDetailListByFilter(this.payload);
          },
          errorCallback: () => { },
          isSaveMessage: true,
        });
      });
    },
    duplicateItem(id) {
      const payload = {
        congressId: this.$route.params.id,
        congressDetailId: id,
      };
      service
        .dispatch("congress-detail/duplicate", payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.dispatchCongressDetailListByFilter(this.payload);
            },
            errorCallback: () => { },
            isSaveMessage: true,
          });
        });
    },
    fetchCongressDetailById(id) {
      service
        .fetch(`congress-detail/${id}/detail`)
        .then((response) => {
          console.log(response);
          this.detailId = id;
          this.formData = response;
        })
        .catch((err) => {
          console.debug("ERROR: ", err);
        });
    },
    openModal(id) {
      this.fetchCongressDetailById(id);
      this.modal.show();
    },
    closeModal() {
      this.modal.hide();
    },
    initModal() {
      const modalEl = document.getElementById("edit-congress-detail-modal");
      const modalOptions = {
        placement: "center-center",
        backdropClasses:
          "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        onShow: () => {
          document.querySelector(
            "#edit-congress-detail-modal .modal-scroll"
          ).scrollTop = 0;
        },
      };
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions);
      }
    },
    toCurrency,
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
