<template>
  <div class="terms-of-use">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <content-wrapper>
        <div class="flex flex-col lg:flex-row">
          <div
            class="min-w-auto lg:min-w-[350px] mr-0 lg:mr-6 border rounded p-6 h-min mb-6 lg:mb-0"
          >
            <div class="text-base font-bold uppercase mb-6">
              {{ pageTitle }}
            </div>
            <ul class="sidebar-menu mb-6">
              <li>
                <router-link :to="{ name: 'privacyPolicy' }">
                  {{ $t('user.staticPages.privacyPolicy') }}
                </router-link>
              </li>
              <li>
                <router-link class="active" :to="{ name: 'termsOfUse' }">
                  {{ $t('user.staticPages.termsOfUse') }}
                </router-link>
              </li>
            </ul>
            <button
              @click="goToContact"
              type="button"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            >
              {{ $t('user.staticPages.contactUs') }}
            </button>
          </div>
          <div class="w-auto">
            <div v-if="getLang === 'EN'">
              <p class="text-sm mb-2">
                This platforms is owned and operated by SERENAS Uluslararası
                Turizm Kongre Organizasyon A.Ş. (“Serenas Group”), a company
                registered in Turkey and located at Hilal Mah. Cezayir Cad.
                No:13 06550 Yıldız, Çankaya/ANKARA, TURKEY.
              </p>
              <p class="text-sm mb-2">
                These terms of use regulate the legal effects of how you use our
                platform or any linked websites and apps owned by Serenas Group
                or utilise any of our services accessed via our websites and
                apps. By using our platform, websites and apps in any way,
                setting up an account or utilising any of our services, you
                agree to be bound by these terms and conditions.
              </p>
              <p class="text-sm mb-2">
                Our platform and related applications are free to use unless
                stated otherwise, and operated to provide access to various
                conferences, seminars, panels, organisations and event within
                the scope of our services as to event organisation and content.
                Though your access and use of the Services is governed by the
                Terms effective at the time, please note that we may revise and
                update these Terms from time to time in our discretion.
              </p>
              <p class="text-sm mb-2">
                It is only permissible to use our platform and applications in
                accordance with the law and for personal purposes. With that
                respect, our guests and members are solely liable for any
                process, transaction or activity may be carried out by using our
                website. Guests and members accept and warrant to use our
                website avoiding any act to violate third party rights. Serenas
                Group cannot be held liable for any damage, costs and/or loss
                that may occur under the guests and members’ activities.
              </p>
              <p class="text-sm mb-2">
                Serenas Group has the sole ownership for all intellectual
                property rights and licenses of all materials consisting of, but
                not limited to, platform, website and mobile application layout,
                design, visuals, all information, pictures, trademarks, domains,
                logos, icons, demonstrative, written, electronic, graphic or
                machine-readable technical data, software, sales systems,
                business models. None of these materials cannot be altered,
                copied, distributed, reproduced, translated, downloaded, posted,
                forwarded, reissued or represented without the prior
                authorisation and without any credit, including all codes and
                software. Or else, Serenas Group holds the right to take legal
                action. All content, including paid content, on the Services, is
                owned by, collected by, or licensed to Serenas Group and is
                protected by applicable laws. Except as expressly and
                unambiguously provided herein, we do not grant you any express
                or implied rights, and all rights in and to the Company IP are
                retained by us.
              </p>
              <p class="text-sm mb-2">
                If the whole or any part of any provision of these terms and
                conditions is or becomes invalid, void or unenforceable for any
                reason the same shall to the extent required to be severed from
                these terms and conditions and rendered ineffective so far as is
                possible without modifying the remaining provisions of these
                terms and conditions and shall in no way affect the validity or
                enforceability of any other provisions.
              </p>
              <p class="text-sm mb-2">
                These terms and conditions and/or your use of the platform and
                relevant applications shall be governed by and construed in
                accordance with Turkish law and the Ankara Courts and
                Enforcement Offices shall have exclusive jurisdiction over any
                dispute which may arise.
              </p>
              <p class="text-sm mb-2">
                In case of you open an account, you are responsible for all
                activities that occur in connection with your account. You will
                treat as confidential your account access credentials and will
                not disclose them to any third party. You agree to immediately
                notify us if you have any reason to believe that your account
                credentials have been compromised or if there is any
                unauthorized use of your account or password or any other breach
                of security. We ask that you use particular caution when
                accessing your profile and not disclose your personal
                information to third parties.
              </p>
              <p class="text-sm mb-2">
                All information and data processed within our services are
                strictly confidential. As Serenas Group, we may process and use
                your personal data to carry out our services provided within our
                website in accordance with conditions set forth in our Privacy
                Policy. For further information, please visit our Privacy
                Policy.
              </p>
            </div>
            <div v-if="getLang === 'TR'">
              <p class="text-sm mb-2">
                Bu platform, Türkiye menşeili Hilal Mah. Cezayir Cad. No:13
                06550 Yıldız, Çankaya/ANKARA, TÜRKİYE adresinde mukim şirket
                SERENAS Uluslararası Turizm Kongre Organizasyon A.Ş. (“Serenas
                Group”) tarafından yayına koyulmakta ve yürütülmektedir.
              </p>
              <p class="text-sm mb-2">
                İşbu Kullanım Koşulları, platformumuzu ve/veya Serenas Group
                tarafından yürütülen bağlantılı internet siteleri ve mobil
                uygulamaları kullanımınız veya bu site ve uygulamalar
                aracılığıyla erişim sağladığınız hizmetlerimize ilişkin hukuki
                neticeleri düzenlemek üzere hazırlanmıştır. Platformumuzu,
                internet siteleri veya mobil uygulamalarımızı üyelik hesabı
                açmak veya herhangi bir şekilde kullanmak suretiyle, işbu
                kullanım koşullarına tabi hale geldiğinizi bildiririz.
              </p>
              <p class="text-sm mb-2">
                Platformumuz ve bağlantılı uygulamalarımız aksi belirtilmedikçe
                kullanımı ücretsiz olup organizasyon ve etkinlik yönetimi
                faaliyetlerimiz kapsamında çeşitli kongre, seminer, panel,
                organizasyon ve etkinliğe erişim sağlamanız üzere
                yürütülmektedir. İşbu kullanım koşulları, okumakta olduğunuz
                zaman diliminde güncel ve geçerli olmakla birlikte, değişen ve
                gelişen faaliyetlerimiz doğrultusunda güncellenebilir ve
                üzerinde değişiklikler yapılabilir.
              </p>
              <p class="text-sm mb-2">
                Siteyi kullananlar, yalnızca hukuka uygun ve şahsi amaçlarla
                site üzerinde işlem yapabilirler. Bu kapsamda, ziyaretçilerin ve
                üyelerin site dâhilinde yaptığı her işlem ve eylemdeki hukuki ve
                cezai sorumlulukları kendilerine aittir. Ziyaretçi ve üyeler,
                Serenas Group ve/veya başka bir üçüncü kişinin haklarına tecavüz
                teşkil edecek nitelikteki herhangi bir faaliyette
                bulunmayacağını taahhüt eder. Ziyaretçi ve üyelerin site
                üzerindeki faaliyetleri nedeniyle üçüncü kişilerin uğradıkları
                veya uğrayabilecekleri zararlardan dolayı Serenas Group’un
                doğrudan ve/veya dolaylı hiçbir sorumluluğu yoktur.
              </p>
              <p class="text-sm mb-2">
                Serenas Group platformun ve bağlantılı site ve uygulamaların
                genel görünüm ve dizaynı ile içerikleri, sitedeki tüm bilgi,
                resim, Serenas markaları ve diğer markalar, alan adı, logo,
                ikon, demonstratif, yazılı, elektronik, grafik veya makinede
                okunabilir şekilde sunulan teknik veriler, bilgisayar
                yazılımları, uygulanan satış sistemi, iş metodu ve iş modeli de
                dahil tüm materyallerin ve bunlara ilişkin fikri ve sınai
                mülkiyet haklarının sahibidir ve tüm fikri mülkiyet ile ilişkili
                hakları yasal koruma altındadır. Sitede bulunan hiçbir materyal;
                önceden izin alınmadan ve kaynak gösterilmeden, kod ve yazılım
                da dahil olmak üzere, değiştirilemez, kopyalanamaz,
                çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz,
                başka bir bilgisayara yüklenemez, postalanamaz, iletilemez,
                sunulamaz ya da dağıtılamaz. Sitenin bütünü veya bir kısmı başka
                bir internet sitesinde izinsiz olarak kullanılamaz. Aksine
                hareketler hukuki ve cezai sorumluluğu gerektirir. Serenas
                Group’un burada açıkça belirtilmeyen diğer tüm hakları saklıdır.
              </p>
              <p class="text-sm mb-2">
                İşbu kullanım koşullarının herhangi bir kısmının herhangi bir
                sebeple geçersiz veya uygulanamaz hale gelmesi halinde, geçersiz
                hale gelen kısım haricinde protokolün bütünü korunacak ve
                kullanım koşullarının geçerli hükümlerini etkilemeyecektir.
              </p>
              <p class="text-sm mb-2">
                İşbu kullanım koşullarından doğabilecek her türlü uyuşmazlığın
                çözümünde Türk hukuku geçerli olup Ankara Mahkemeleri ve İcra
                Müdürlükleri yetkilidir.
              </p>
              <p class="text-sm mb-2">
                Kullanıcı, ziyaretçi ve üyelerin sisteme girdiği tüm bilgilere
                sadece kendileri ulaşabilmekte ve bu bilgileri sadece kendileri
                değiştirebilmektedir. Yenel Etiket, site üzerinden kullanıcılar,
                ziyaretçiler ve üyeler tarafından kendisine iletilen bilgileri
                Gizlilik Politikası hükümleri doğrultusunda kullanabilir. Bu
                bilgileri işleyebilir, bir veritabanı üzerinde tasnif edip
                muhafaza edebilir. Bu hususta daha fazla bilgi almak için lütfen
                Gizlilik Politikamızı okuyunuz.
              </p>
            </div>
          </div>
        </div>
      </content-wrapper>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import router from '@/router'

import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  ContentWrapper,
} from '@/components'

export default {
  name: 'TermsOfUsePage',
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    ContentWrapper,
  },
  data() {
    return {
      breadcrumbList: [{ name: this.$t('sidebar.termsOfUse'),key:'sidebar.termsOfUse' }],
      pageTitle: this.$t('sidebar.termsOfUse'),
      isLoading: true,
    }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.termsOfUse'),
      })),
    )
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    goToContact() {
      router.push({ name: 'contact' })
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.terms-of-use {
  @apply h-full;
  .sidebar-menu li {
    @apply mb-4;

    a {
      &.active {
        @apply text-blue-500;
      }
      @apply text-base font-medium text-gray-500;

      &:hover {
        @apply text-blue-500;
      }
    }
  }

  .content-list li {
    @apply list-disc mb-4 ml-4;

    a {
      @apply text-blue-500;
    }
  }
}
</style>
