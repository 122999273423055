<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="p-4">
          &nbsp;
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.createdDate") }}
        </th>

        <th scope="col" class="p-4">
          {{ $t("user.order.congressName") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.firstNameLastName") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.email") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.orderStatus") }}
        </th>
        <th scope="col" class="p-4 text-right">
          {{ $t("user.order.totalTax") }}
        </th>
        <th scope="col" class="p-4 text-right">
          {{ $t("user.order.totalPrice") }}
        </th>
        <th scope="col" class="p-4"></th>
        <th scope="col" class="p-4"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in order.items" :key="item" :id="`row-${item.id}`"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td scope="row" class="p-4">
          <Popper :hover="true" closeDelay="100" :arrow="true">
            <button @click="copyData(item.orderNo)">
              <HashtagIcon class="h-4 w-4 text-[#1da1f2]" />
            </button>
            <template #content>
              <span v-if="isCopied" class="text-xs whitespace-nowrap">
                {{ $t('shared.copiedUrl') }}
              </span>
              <span v-else class="text-xs whitespace-nowrap">
                {{ item.orderNo }}
              </span>
            </template>
          </Popper>
        </td>
        <td class="p-4">
          <span class="whitespace-nowrap">{{ moment(item.createdDate).format("DD/MM/YYYY") }}</span><br />
          <span class="whitespace-nowrap">{{ moment(item.createdDate).format("HH:mm") }}</span>
        </td>
        <td class="p-4 font-medium text-gray-900 dark:text-white">
          <p class="truncate w-[150px] 2xl:w-auto" :title="`${getLang === 'TR' ? item.congressName : item.congressNameEn
            }`">
            <span v-if="getLang === 'TR'">
              {{ item.congressName }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.congressNameEn }}
            </span>
          </p>
        </td>
        <td class="p-4">{{ item.firstName }} {{ item.lastName }}</td>
        <td class="p-4">{{ item.email }}</td>
        <td class="p-4">
          <span v-if="item.orderStatus === 1">
            {{ $t(`user.order.waitingforpayment`) }}
          </span>
          <span v-else-if="item.orderStatus === 2">
            {{
              this.CheckItemHasInovice(item)
              ? $t(`user.order.waitingforApprove`)
              : $t(`user.order.waitingfortransfer`)
            }}
          </span>
          <span v-else-if="item.orderStatus === 3 && item.isPaymentApproved">
            {{ $t(`user.order.paymentcompleted`) }}
          </span>
          <span v-else>
            {{ $t(`user.order.cancelled`) }}
          </span>
        </td>
        <td class="p-4 text-right whitespace-nowrap">
          {{
            toCurrency(
              item.totalTax,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.productCurrency)
            )
          }}
        </td>
        <td class="p-4 text-right whitespace-nowrap">
          {{
            toCurrency(
              item.totalPrice,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.productCurrency)
            )
          }}
        </td>
        <td class="p-4 text-center">
          <span v-if="item.isPaymentApproved" class="block rounded bg-green-100 p-1 w-6 h-6">
            <CheckCircleIcon class="h-4 w-4 text-green-800" />
          </span>
          <span v-else-if="item.orderStatus === 2 && this.CheckItemHasInovice(item)"
            class="block rounded bg-green-100 p-1 w-6 h-6">
            <QuestionMarkCircleIcon class="h-4 w-4 text-yellow-300" />
          </span>
          <span v-else class="block rounded bg-red-100 p-1 w-6 h-6">
            <MinusCircleIcon class="h-4 w-4 text-red-800" />
          </span>
        </td>
        <td class="p-4">
          <div class="flex">
            <router-link :to="{ name: 'order/id', params: { id: item.id } }"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline" :title="`${$t('shared.details')}`">
              <InformationCircleIcon class="w-4 h-4" />
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  HashtagIcon,
  CheckCircleIcon,
  MinusCircleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/20/solid";
import { toCurrency } from "@/common/utils";
export default {
  name: "OrderTableList",
  components: {
    HashtagIcon,
    CheckCircleIcon,
    MinusCircleIcon,
    QuestionMarkCircleIcon,
    InformationCircleIcon,
  },
  data() {
    return {
      moment: moment,
    };
  },
  props: {
    order: Object,
    pageSize: Number,
    payload: Object,
  },
  methods: {
    toCurrency,
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    CheckItemHasInovice(item) {
      return item.documentPath != null && item.documentPath != "";
    },
    copyData(data) {
      this.isCopied = true
      navigator.clipboard.writeText(data)
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
