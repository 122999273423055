<template>
  <div class="congress-detail">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <template v-if="congressDetail.length">
        <content-wrapper>
          <congress-detail-action :search="search" :filterReset="filterReset" :filterProductType="filterProductType" :filterStockType="filterStockType"
            :congressDetail="congressDetail" :payload="payload" :currency="congressCurrency"
            :dispatchCongressDetailListByFilter="dispatchCongressDetailListByFilter
              " />
          <template v-if="downXl">
            <congress-detail-table-list :congressDetail="congressDetail" :payload="payload" :currency="congressCurrency"
              :dispatchCongressDetailListByFilter="dispatchCongressDetailListByFilter
                " />
          </template>
          <template v-else>
            <congress-detail-accordion-list :congressDetail="congressDetail" :payload="payload"
              :dispatchCongressDetailListByFilter="dispatchCongressDetailListByFilter
                " />
          </template>
        </content-wrapper>
      </template>
      <template v-else>
        <content-wrapper>
          <congress-detail-action :search="search" :filterReset="filterReset" :filterProductType="filterProductType" :filterStockType="filterStockType"
            :payload="payload" :currency="congressCurrency" :dispatchCongressDetailListByFilter="dispatchCongressDetailListByFilter
              " />
        </content-wrapper>

        <no-data externalClass="relative pt-12">
          <div class="text-2xl font-bold mb-2 text-gray-900">
            {{ $t('shared.noData') }}
          </div>
          <div class="text-sm font-normal text-gray-500">
            {{ $t('shared.noDataDescription') }}
          </div>
        </no-data>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { service } from '@/common/services'
import {
  useMediaQuery,
  useToast,
  useResponseToast,
  useMeta,
} from '@/common/hooks'
import moment from 'moment'
import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  NoData,
  ContentWrapper,
} from '@/components'
import CongressDetailAction from './CongressDetailAction.vue'
import CongressDetailTableList from './CongressDetailTableList.vue'
import CongressDetailAccordionList from './CongressDetailAccordionList.vue'

export default {
  name: 'AdminCongressDetailPage',
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    ContentWrapper,
    NoData,
    CongressDetailAction,
    CongressDetailTableList,
    CongressDetailAccordionList,
  },
  data() {
    return {
      moment: moment,
      breadcrumbList: [
        { name: this.$t('sidebar.congress'), url: 'congress', key: 'sidebar.congress' },
        { name: this.$t('sidebar.congressDetail'), key: 'sidebar.congressDetail' },
      ],
      pageTitle: this.$t('sidebar.congressDetail'),
      congressDetail: Array,
      congressCurrency: Number,
      isLoading: true,
      payload: {
        congressId: this.$route.params.id,
        productType: null,
        searchText: '',
        sortColumn: '',
        sortDescending: true,
      },
    }
  },
  setup() {
    const { downXl } = useMediaQuery()
    const toast = useToast()
    return { toast, downXl }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.congressDetail'),
      })),
    )
  },
  mounted() {
    this.dispatchCongressDetailListByFilter(this.payload)
  },
  watch: {
    congressDetail: function (data) {
      return (this.congressDetail = data)
    },
  },
  methods: {
    dispatchCongressDetailListByFilter(payload) {
      service
        .dispatch(`congress-detail/list-by-filter`, payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.congressCurrency = response.productCurrency;
              if (response.items.length) {
                this.congressDetail = response.items
              } else {
                this.congressDetail = []
              }
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    search(searchText) {
      this.payload = { ...this.payload, searchText }
      this.dispatchCongressDetailListByFilter(this.payload)
    },
    filterStockType($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        stockType: Number($event.target.value),
      }
      this.dispatchCongressDetailListByFilter(this.payload)
    },
    filterProductType($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        productType: Number($event.target.value),
      }
      this.dispatchCongressDetailListByFilter(this.payload)
    },
    filterReset() {
      this.payload = {
        congressId: this.$route.params.id,
        productType: null,
        searchText: '',
        sortColumn: '',
        sortDescending: true,
      };
      this.dispatchCongressDetailListByFilter(this.payload)
    },
  },
}
</script>

<style scoped lang="scss">
.congress-detail {
  @apply h-full;
}
</style>
