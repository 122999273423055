<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="p-4">
          {{ $t("user.order.orderNo") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.createdDate") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.congressName") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.orderStatus") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.paymentType") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.totalTax") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.totalPrice") }}
        </th>
        <th scope="col" class="p-4"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in order.items" :key="item" :id="`row-${item.id}`"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td scope="row" class="p-4">{{ item.orderNo }}</td>
        <td class="p-4 whitespace-nowrap">
          {{ moment(item.createdDate).format("DD.MM.YYYY HH:mm") }}
        </td>
        <td scope="row" class="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          <span v-if="getLang === 'TR'">
            {{ item.congressName }}
          </span>
          <span v-if="getLang === 'EN'">
            {{ item.congressNameEn }}
          </span>
        </td>
        <td class="p-4">
          <span v-if="item.orderStatus.toLowerCase() === 'waitingforpayment'">
            {{ $t(`user.order.waitingforpayment`) }}
          </span>
          <span v-else-if="item.orderStatus.toLowerCase() === 'waitingfortransfer'">
            {{
              this.CheckItemHasInovice(item)
                ? $t(`user.order.waitingforApprove`)
                : $t(`user.order.waitingfortransfer`)
            }}
          </span>
          <span v-else-if="item.orderStatus.toLowerCase() === 'paymentcompleted'">
            {{ $t(`user.order.paymentcompleted`) }}
          </span>
          <span v-else>
            {{ $t(`user.order.canceled`) }}
          </span>
        </td>
        <td class="p-4">
          {{ $t(`user.order.${item.paymentType.toLowerCase()}`) }}
        </td>
        <td class="p-4">
          {{
            toCurrency(
              item.totalTax,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.productCurrency)
            )
          }}
        </td>
        <td class="p-4">
          {{
            toCurrency(
              item.totalPrice,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.productCurrency)
            )
          }}
        </td>
        <td class="p-4">
          <div class="flex">
            <router-link :to="{ name: 'userOrder/id', params: { id: item.id } }"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline" :title="`${$t('shared.details')}`">
              <InformationCircleIcon class="w-4 h-4" />
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { InformationCircleIcon } from "@heroicons/vue/20/solid";
import { toCurrency } from "@/common/utils";

export default {
  name: "OrderTableList",
  components: { InformationCircleIcon },
  data() {
    return {
      moment: moment,
    };
  },
  props: {
    order: Object,
    pageSize: Number,
    payload: Object,
  },
  methods: {
    toCurrency,
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    CheckItemHasInovice(item) {
      return item.documentPath != null && item.documentPath != "";
    },
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
