<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="p-4">
          &nbsp;
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.operationDate") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.order.congressName") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("admin.congressDetail.productType") }}
        </th>
        <th scope="col" class="p-4">{{ $t("user.order.packageType") }}</th>
        <th scope="col" class="p-4">{{ $t("user.order.packageName") }}</th>
        <th scope="col" class="p-4">{{ $t("user.order.orderNote") }}</th>
        <th scope="col" class="p-4 text-center">{{ $t("shared.dateAndTime") }}</th>
        <th scope="col" class="p-4">{{ $t("user.order.daysNights") }}</th>
        <th scope="col" class="p-4">{{ $t("shared.name") }}</th>
        <th scope="col" class="p-4 text-right">{{ $t("user.order.price") }}</th>
        <th scope="col" class="p-4 text-right">{{ $t("user.order.totalPrice") }}</th>
        <th scope="col" class="p-4"> {{ $t("user.order.paymentType") }} </th>
        <th scope="col" class="p-4"> {{ $t("user.order.orderStatus") }} </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in order.items" :key="item" :id="`row-${item.id}`"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td scope="row" class="p-4">
          <Popper :hover="true" closeDelay="100" :arrow="true">
            <button @click="copyData(item.orderNo)">
              <HashtagIcon class="h-4 w-4 text-[#1da1f2]" />
            </button>
            <template #content>
              <span v-if="isCopied" class="text-xs whitespace-nowrap">
                {{ $t('shared.copiedUrl') }}
              </span>
              <span v-else class="text-xs whitespace-nowrap">
                {{ item.orderNo }}
              </span>
            </template>
          </Popper>
        </td>
        <td class="p-4">
          <span class="whitespace-nowrap">{{ moment(item.OperationDate).format("DD/MM/YYYY") }}</span><br />
          <span class="whitespace-nowrap">{{ moment(item.OperationDate).format("HH:mm") }}</span>
        </td>
        <td class="p-4">
          <span v-if="getLang === 'TR'">
            {{ item.congressName }}
          </span>
          <span v-if="getLang === 'EN'">
            {{ item.congressNameEn }}
          </span>
        </td>

        <td class="p-4 whitespace-nowrap">
          <span v-if="item.productType === 1">
            {{ $t("admin.congressDetail.inside") }}
          </span>
          <span v-if="item.productType === 2">
            {{ $t("admin.congressDetail.outside") }}
          </span>
          <span v-if="item.productType === 3">
            {{ $t("admin.congressDetail.accommodation") }}
          </span>
          <span v-if="item.productType === 4">
            {{ $t("admin.congressDetail.transfer") }}
          </span>
          <span v-if="item.productType === 5">
            {{ $t("admin.congressDetail.course") }}
          </span>
        </td>

        <td class="p-4">
          <span v-if="getLang === 'TR'">
            {{ item.packageType }}
          </span>
          <span v-if="getLang === 'EN'">
            {{ item.packageTypeEn }}
          </span>
        </td>

        <td class="p-4">
          <span v-if="getLang === 'TR'">
            {{ item.packageName }}
          </span>
          <span v-if="getLang === 'EN'">
            {{ item.packageNameEn }}
          </span>
        </td>

        <td class="p-4">
          {{ item.notes }}
        </td>

        <td class="p-4 text-center leading-none">
          <span class="whitespace-nowrap">{{ moment(item.startDate).format("DD/MM/YYYY") }}</span><br />
          <ChevronDownIcon class="flex h-3 w-3" /><br />
          <span class="whitespace-nowrap">{{ moment(item.endDate).format("DD/MM/YYYY") }}</span>
        </td>

        <td class="p-4 text-center">{{ item.daysAndNigths }}</td>
        <td class="p-4">
          <span class="whitespace-nowrap">{{ item.buyerName }}</span><br />
          <span class="whitespace-nowrap text-gray-500 font-semibold" v-if="item.participantName != item.buyerName">{{
            item.participantName }}</span>
          <span class="whitespace-nowrap text-gray-300 font-semibold" v-if="item.participantName === item.buyerName">{{
            $t("shared.self") }}</span>
        </td>

        <td class="p-4 text-right">
          {{
            toCurrency(
              item.price,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.productCurrency)
            )
          }}
          <br />
          <span class="text-xs text-gray-400 whitespace-nowrap font-semibold">+
            {{
              toCurrency(
                item.vat,
                "tr-TR",
                "currency",
                getCongressProductCurrency(item.productCurrency)
              )
            }}
            (%{{ item.vatRatio }})</span>

        </td>

        <td class="p-4 text-right">
          {{
            toCurrency(
              item.totalPrice,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.productCurrency)
            )
          }}
        </td>
        <td class="p-4 text-center">
          <span class="text-gray-900" v-if="item.paymentType === 1">
            {{ $t(`user.order.creditcard`) }}
          </span>
          <span class="text-gray-900" v-if="item.paymentType === 2">
            {{ $t(`user.order.transfer`) }}
          </span>
        </td>
        <td class="p-4 text-center">
          <span class="text-gray-900" v-if="item.orderStatus === 1">  <QuestionMarkCircleIcon class="h-4 w-4 text-yellow-300" /></span>
          <span class="text-gray-900" v-else-if="item.orderStatus === 2 && this.CheckItemHasInovice(item)">  <QuestionMarkCircleIcon class="h-4 w-4 text-yellow-300" /> </span>
          <span class="text-gray-900" v-else-if="item.orderStatus === 3"> <CheckCircleIcon class="h-4 w-4 text-green-800" /> </span>
          <span class="text-gray-900" v-else>  <MinusCircleIcon class="h-4 w-4 text-red-800" /></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { toCurrency } from "@/common/utils";
import Popper from 'vue3-popper'
import { HashtagIcon, ChevronDownIcon,MinusCircleIcon,CheckCircleIcon,QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'

export default {
  name: "OrderDetailedTableList",
  components: {
    HashtagIcon,
    ChevronDownIcon,
    Popper,
    MinusCircleIcon,
    CheckCircleIcon,
    QuestionMarkCircleIcon
  },
  data() {
    return {
      moment: moment,
    };
  },
  props: {
    order: Object,
    pageSize: Number,
    payload: Object,
  },
  methods: {
    toCurrency,
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    CheckItemHasInovice(item) {
      return item.documentPath != null && item.documentPath != "";
    },
    copyData(data) {
      this.isCopied = true
      navigator.clipboard.writeText(data)
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
