<template>
  <content-wrapper>
    <div class="flex justify-between items-center">
      <h2 class="text-lg font-medium mb-2">
        {{ $t('user.payment.billing.billingInfo') }}
      </h2>
      <a
        @click="toggleBilling"
        class="cursor-pointer disable block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        <ChevronUpIcon v-if="isToggleBilling" class="w-5 h-5" />
        <ChevronDownIcon v-if="!isToggleBilling" class="w-5 h-5" />
      </a>
    </div>
    <Transition name="billing-transition">
      <div v-if="isToggleBilling">
        <div class="w-full">
          <div class="flex flex-col lg:flex-row">
            <label
              class="inline-flex relative items-center mb-4 cursor-pointer"
            >
              <input
                type="checkbox"
                value=""
                @change="toggleCorporateInvoice"
                class="sr-only peer"
              />
              <div
                class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
              ></div>
              <span
                class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {{ $t('user.profile.corporateInvoice') }}
              </span>
            </label>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between mt-4">
          <div class="w-full lg:w-1/2 sm:pr-2">
            <div class="flex-auto mb-6">
              <label
                for="invoiceName"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                {{ $t('user.profile.invoiceName') }}
              </label>
              <input
                type="text"
                id="invoiceName"
                v-model="formData.invoiceName"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>

            <div class="flex-auto mb-6">
              <label
                for="invoiceCountryId"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                {{ $t('user.profile.country') }}
              </label>
              <select
                id="invoiceCountryId"
                v-model="formData.invoiceCountryId"
                @change="fetchStateById"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="null" selected disabled>
                  {{ $t('user.profile.chooseCountry') }}
                </option>
                <option
                  v-for="item in invoiceCountryList"
                  :value="item.id"
                  :key="item"
                >
                  {{ getLang === 'TR' ? item.translations.tr : item.name }}
                </option>
              </select>
            </div>
            <div class="flex-auto mb-6">
              <template v-if="invoiceCountryCode === 'TR'">
                <label
                  for="invoiceStateId"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  {{ $t('user.profile.city') }}
                </label>
              </template>
              <template v-else>
                <label
                  for="invoiceStateId"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  {{ $t('user.profile.state') }}
                </label>
              </template>
              <select
                id="invoiceStateId"
                v-model="formData.invoiceStateId"
                @change="fetchCityById"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="null" selected disabled>
                  {{
                    invoiceCountryCode !== 'TR'
                      ? $t('user.profile.chooseState')
                      : $t('user.profile.chooseCity')
                  }}
                </option>
                <option
                  v-for="item in invoiceStateList"
                  :value="item.id"
                  :key="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-1/2 sm:pl-2">
            <div v-if="invoiceCountryCode !== 'TR'" class="flex-auto mb-6">
              <label
                for="invoiceCityId"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                {{ $t('user.profile.city') }}
              </label>
              <select
                id="invoiceCityId"
                v-model="formData.invoiceCityId"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="null" selected disabled>
                  {{ $t('user.profile.chooseCity') }}
                </option>
                <option
                  v-for="item in invoiceCityList"
                  :value="item.id"
                  :key="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <template v-if="isCorporateInvoice">
              <div class="flex-auto mb-6">
                <label
                  for="invoiceTaxNumber"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  {{ $t('user.profile.taxNumber') }}
                </label>
                <input
                  type="text"
                  id="invoiceTaxNumber"
                  v-model="formData.invoiceTaxNumber"
                  v-maska="'##########'"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div class="flex-auto mb-6">
                <label
                  for="invoiceTaxOffice"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  {{ $t('user.profile.taxDepartment') }}
                </label>
                <input
                  type="text"
                  id="invoiceTaxOffice"
                  v-model="formData.invoiceTaxOffice"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
            </template>

            <div class="flex-auto mb-6">
              <label
                for="invoiceAddress"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                {{ $t('user.profile.invoiceAddress') }}
              </label>
              <input
                type="text"
                id="invoiceAddress"
                v-model="formData.invoiceAddress"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </content-wrapper>
</template>

<script>
import { useResponseToast } from '@/common/hooks'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'
import { service } from '@/common/services'
import { ContentWrapper } from '@/components'
import { mapGetters } from 'vuex'

export default {
  name: 'BillingInfo',
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    ContentWrapper,
  },
  data() {
    return {
      modal: null,
      isToggleBilling: true,
      formData: {},
      invoiceCountryList: [],
      invoiceStateList: [],
      invoiceCityList: [],
      invoiceCountryCode: '',
    }
  },
  props: {
    propFormData: {},
    isCorporateInvoice: Boolean,
    toggleCorporateInvoice: Function,
  },
  watch: {
    propFormData: function (data) {
      this.formData = data
    },
  },
  mounted() {
    this.formData = this.propFormData
    this.fetchCountry()
    this.formData?.invoiceCountryId && this.fetchStateById()
    this.formData?.invoiceStateId && this.fetchCityById()
  },
  methods: {
    toggleBilling() {
      this.isToggleBilling = !this.isToggleBilling
    },
    fetchCountry() {
      service.fetch('country').then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.invoiceCountryList =
              this.getLang === 'TR'
                ? response.items.sort((a, b) =>
                    a.translations.tr.localeCompare(b.translations.tr),
                  )
                : response.items
            this.formData.invoiceStateId && this.fetchStateById()
            this.formData.invoiceCityId && this.fetchCityById()
          },
          errorCallback: () => {},
          isSaveMessage: false,
        })
      })
    },
    fetchStateById() {
      service
        .fetch(`state/${this.formData.invoiceCountryId}`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.invoiceStateList = response.items
              if (response.items.length > 0) {
                this.invoiceCountryCode = response.items[0].countryCode
                this.formData.invoiceStateId = response.items[0].id
              }
              this.formData.invoiceStateId && this.fetchCityById()
            },
            errorCallback: () => {},
            isSaveMessage: false,
          })
        })
    },
    fetchCityById() {
      service
        .fetch(`city/${this.formData.invoiceStateId}/by-state`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.invoiceCityList = response.items
              if (response.items.length > 0) {
                this.formData.invoiceCityId = response.items[0].id
              }
            },
            errorCallback: () => {},
            isSaveMessage: false,
          })
        })
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style>
.billing-transition-enter-active {
  transition: all 0.5s ease-out;
}

.billing-transition-leave-active {
  transition: all 0.5s ease-out;
}

.billing-transition-enter-from,
.billing-transition-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
