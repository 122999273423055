<template>
  <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar"
    aria-controls="sidebar-multi-level-sidebar" type="button"
    class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none">
    <span class="sr-only">Open Menu</span>
    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd"
        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
      </path>
    </svg>
  </button>

  <aside id="sidebar-multi-level-sidebar"
    class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
    aria-label="Sidebar">
    <div class="h-full flex flex-col py-4 overflow-y-auto bg-white border-r border-solid border-color-[#e5e5e5]">
      <ul class="grow space-y-2">

        <li class="h-[60px] hidden sm:list-item">
          <router-link to="/" class="static flex items-center mb-5 focus:outline-none">
            <CustomIcon name="logo-with-text" class="w-full h-8 sm:h-9" />
          </router-link>
        </li>

        <template v-for="(item, index) in menuList" :key="item">
          <li v-if="item.subMenu != undefined">
            <button :aria-controls="'submenu-' + index" :data-collapse-toggle="'submenu-' + index" type="button"
              class="flex items-center w-full text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100">
              <span class="whitespace-nowrap icon py-2 px-4 box-border flex justify-center">
                <component :is="item.icon" :item="item" :key="item" :class="[item.isAdmin && 'text-orange-500']"
                  class="w-6 h-6 text-[#1da1f2]" />
              </span>
              <span :class="[item.isAdmin && 'font-semibold']" class="flex-1 text-left name text-gray-900">{{
                $t(`sidebar.${item.name}`) }}
              </span>
              <svg sidebar-toggle-item class="w-6 h-6 pr-1" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
            </button>
            <ul :id="'submenu-' + index" class="hidden py-2 space-y-2">
              <li v-for="subItem in item.subMenu" :key="subItem">
                <router-link :to="{ name: subItem.url }"
                  class="flex items-center text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none">
                  <span class="whitespace-nowrap icon py-2 px-4 box-border flex justify-center">
                    <component :is="subItem.icon" :item="subItem" :key="subItem"
                      :class="[subItem.isAdmin && 'text-orange-500']" class="pl-2 w-6 h-6 text-[#1da1f2]" />
                  </span>
                  <span :class="[subItem.isAdmin && 'font-semibold']" class="name text-sm text-gray-900">{{
                    $t(`sidebar.${subItem.name}`) }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>

          <li v-else>
            <router-link :to="{ name: item.url }"
              class="flex items-center text-gray-900 rounded-lg hover:bg-gray-100 transition duration-75 focus:outline-none">
              <span class="whitespace-nowrap icon py-2 px-4 box-border flex justify-center">
                <component :is="item.icon" :item="item" :key="item" :class="[item.isAdmin && 'text-orange-500']"
                  class="w-6 h-6 text-[#1da1f2]" />
              </span>
              <span :class="[item.isAdmin && 'font-semibold']" class="name text-gray-900">{{ $t(`sidebar.${item.name}`) }}
              </span>
            </router-link>
          </li>
        </template>


      </ul>

      <ul class="flex-none space-y-2">

        <li v-if="getLang === 'EN'">
          <div @click="updateAccountLang('TR')"
            class="static flex items-center text-gray-900 rounded-lg hover:bg-gray-100 transition duration-75 cursor-pointer">
            <span class="whitespace-nowrap icon py-2 px-4 box-border flex justify-center">
              <LanguageIcon class="w-6 h-6" />
            </span>

            <span class="name"> {{ $t('shared.turkish') }}</span>
          </div>
        </li>

        <li v-if="getLang === 'TR'">
          <div @click="updateAccountLang('EN')"
            class="static flex items-center text-gray-900 rounded-lg hover:bg-gray-100 transition duration-75 cursor-pointer">
            <span class="whitespace-nowrap icon py-2 px-4 box-border flex justify-center">
              <LanguageIcon class="w-6 h-6" />
            </span>

            <span class="name"> {{ $t('shared.english') }}</span>
          </div>
        </li>

        <li>
          <div @click="logout"
            class="static flex items-center text-gray-900 rounded-lg hover:bg-gray-100 transition duration-75 cursor-pointer">
            <span class="whitespace-nowrap icon py-2 px-4 box-border flex justify-center">
              <CustomIcon name="logout-icon" class="w-6 h-6" />
            </span>

            <span class="name">{{ $t('sidebar.logout') }}</span>
          </div>
        </li>

      </ul>
    </div>
  </aside>
</template>

<script>
import CustomIcon from '@/components/Icon/CustomIcon.vue'
import {
  ChartPieIcon,
  DocumentPlusIcon,
  UserIcon,
  ShieldCheckIcon,
  IdentificationIcon,
  ShoppingCartIcon,
  DocumentChartBarIcon,
  DocumentIcon,
  LanguageIcon,
} from '@heroicons/vue/24/outline'
import { i18n } from '@/common/locale'
import { service } from '@/common/services'
import { useResponseToast } from '@/common/hooks'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SidebarMenuItems',
  components: {
    CustomIcon,
    ChartPieIcon,
    DocumentPlusIcon,
    UserIcon,
    ShieldCheckIcon,
    IdentificationIcon,
    ShoppingCartIcon,
    DocumentChartBarIcon,
    DocumentIcon,
    LanguageIcon,
  },
  data() {
    return {
      currentLang: localStorage.getItem('language'),
      menuList: [
        {
          name: 'homepage',
          url: 'home',
          icon: 'ChartPieIcon',
          isAdmin: false,
        },
        {
          name: 'myOrder',
          url: 'userOrder',
          icon: 'ShoppingCartIcon',
          isAdmin: false,
        },
        {
          name: 'profile',
          url: 'profile',
          icon: 'UserIcon',
          isAdmin: false,
        },
        {
          name: 'contact',
          url: 'contact',
          icon: 'IdentificationIcon',
          isAdmin: false,
        },
        {
          name: 'privacyPolicy',
          url: 'privacyPolicy',
          icon: 'ShieldCheckIcon',
          isAdmin: false,
        },
        {
          name: 'congress',
          url: 'congress',
          icon: 'DocumentPlusIcon',
          isAdmin: true,
        },
        {
          name: 'reports',
          url: 'admin',
          icon: 'DocumentChartBarIcon',
          isAdmin: true,
          subMenu: [
            {
              name: 'order',
              url: 'order',
              icon: 'DocumentIcon',
              isAdmin: true,
            },
            {
              name: 'detailedOrder',
              url: 'detailedOrder',
              icon: 'DocumentIcon',
              isAdmin: true,
            },
          ],

        },
      ],
    }
  },
  props: {
    accountCurrent: String,
    logout: Function,
  },
  mounted() {
    let newMenuList = []
    if (!this.accountCurrent.isAdmin) {
      this.menuList.map((item) => {
        if (!item.isAdmin) {
          newMenuList.push(item)
        }
      })
      this.menuList = newMenuList
    }
    this.menuList = this.menuList.sort((a, z) => a.isAdmin - z.isAdmin);
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  methods: {
    ...mapActions(['setLang']),
    updateAccountLang(lang) {
      const payload = {
        langCode: lang,
      }
      service
        .update('account/lang', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.currentLang = lang
              this.isToggle = false
              lang = lang.toUpperCase()
              i18n.global.locale = lang
              this.setLang(lang)
              localStorage.setItem('lang', lang)
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .catch((err) => {
          console.debug('DEBUG ERR: ' + err)
        })
    },
  }

}
</script>
