<template>
    <div class="congress">
        <breadcrumb-list :breadcrumbList="breadcrumbList" />
        <page-title :pageTitle="this.getTitle()" />

        <OrderWizard :dispatchBasket="dispatchBasket" :fetchCongressDetailActiveList="fetchCongressDetailActiveList"
            :isLoading="this.isLoading" :congress="this.congress" :congressItems="this.congressItems"
            ref="orderWizard" />

        <basket-item-update-popup :accepted="updateAndDispatchBasket" :denied="updateAndDispatchBasketDenied" />
        <basket-popup :isLoading="isBasketPopupLoading" :addAnotherCongress="addAnotherCongress"
            :noCurrentlyCongress="noCurrentlyCongress" />

    </div>
</template>
<script>
import OrderWizard from './OrderWizard.vue';
import { service } from "@/common/services";
import { useResponseToast, useToast } from "@/common/hooks";
import { ref } from "vue";
import { BreadcrumbList, PageTitle } from "@/components";
import { mapActions, mapGetters } from "vuex";

import BasketItemUpdatePopup from "./BasketItemUpdatePopup.vue";

import BasketPopup from '../Congress/BasketPopup.vue';


export default {
    name: 'CongressOrder',
    components: {
        BreadcrumbList,
        PageTitle,
        OrderWizard,
        BasketItemUpdatePopup,
        BasketPopup,
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            breadcrumbList: [
                { name: this.$t("sidebar.congress"), url: "congress-order/id", key: "sidebar.congress" },
            ],
            isBasketPopupLoading: false,
            congressId: this.$route.params.id,
            pageTitle: null,
            activeTab: ref("tab-0"),
            isLoading: true,
            congress: {},
            congressItems: [],
            basketItemOverwrite: false,
            basketItemForRemoving: null,
            modal: null,
            modalUpdate: null,
        };
    },
    methods: {
        ...mapActions(["fetchBasket"]),
        fetchCongressDetailActiveList() {
            service
                .fetch(`congress-detail/active-list/${this.congressId}`)
                .then((response) => {
                    useResponseToast({
                        response: response,
                        successCallback: () => {
                            if (response.items.length) {
                                this.congress = response.congress;
                                this.congressItems = response.items;

                                console.debug("before filter this.congressItems", this.congressItems);

                                this.congressItems.forEach((congressItem) => {
                                    congressItem.participantTypes.forEach((participantType) => {
                                        participantType.items = participantType.items.filter(
                                            (item) => !item.isSoldOut
                                        );
                                    })
                                    
                                    congressItem.participantTypes = congressItem.participantTypes.filter((participantType) => participantType.items.length > 0);
                                });

                                this.congressItems = this.congressItems.filter((congressItem) => congressItem.participantTypes.length > 0);

                                console.debug("after filter this.congressItems", this.congressItems);

                                this.pageTitle =
                                    this.getLang === "TR"
                                        ? response.congress.name
                                        : response.congress.nameEn;
                                this.breadcrumbList.push({
                                    name:
                                        this.getLang === "TR"
                                            ? response.congress.name
                                            : response.congress.nameEn,
                                });
                            } else {
                                this.congress = {};
                                this.congressItems = [];
                            }
                        },
                        errorCallback: () => { },
                        isSaveMessage: false,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }, getTitle(){
            if (this.getLang === "TR") {
                return this.congress.name;
            } else {
                return this.congress.nameEn;
            }

        },
        dispatchBasket(id, accommodationNotes = null, dates = null, transferDetails = null) {
            if (id) {
                this.congressDetailId = id;

                if (this.isBasketOverrideRequired(id)) {
                    this.modalUpdate.show();
                    return;
                }

                if (this.basketItemForRemoving != null) {

                    this.deleteBasketById(this.basketItemForRemoving.id).then((response) => {
                        useResponseToast({
                            response: response,
                            successCallback: () => {
                                this.basketItemForRemoving = null;

                                //this.fetchBasket();
                                this.dispatchBasket(id);
                                this.modalUpdate.hide();

                                this.basketItemOverwrite = false;
                            },
                            errorCallback: () => { },
                            isSaveMessage: true,
                        });
                    });

                    return;
                }

                let _transferDetails = [];
                if (transferDetails) {
                    _transferDetails = transferDetails.filter((item) => Object.keys(item).length > 0);
                }

                const payload = {
                    congressId: this.$route.params.id,
                    congressDetailId: id,
                    accountSubId: this.$refs.orderWizard.accountSubId,
                    bookDates: dates,
                    notes: accommodationNotes,
                    transferDetails: _transferDetails,
                };

                console.log(payload)

                service.dispatch("basket", payload).then((response) => {
                    useResponseToast({
                        response: response,
                        successCallback: () => {
                            this.toast.success(this.$t("user.congress.successBasket"));
                            this.fetchBasket();
                            this.$refs.orderWizard.nextStep();
                        },
                        errorCallback: () => {
                            response.messages.map((message) => {
                                if (
                                    message.code ===
                                    "FEATURE_VALIDATION_BASKET_CANNOT_CREATE_ANOTHER_BASKET"
                                ) {
                                    this.modal.show();
                                }
                            });
                        },
                        isSaveMessage: false,
                        noToastMessageCodes: [
                            "FEATURE_VALIDATION_BASKET_CANNOT_CREATE_ANOTHER_BASKET",
                        ],
                    });
                });
            } else {
                this.toast.error(this.$t("user.congress.selectPackage"));
            }
        },
        deleteBasketById(id) {
            return service.delete(`basket/${id}`)
        },
        initModal() {
            const modalOptions = {
                placement: "top-center",
                backdropClasses:
                    "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
                onHide: () => { },
            };

            const modalElUpdate = document.getElementById("basket-update-popup-modal");
            if (modalElUpdate) {
                // eslint-disable-next-line
                this.modalUpdate = new Modal(modalElUpdate, modalOptions);
            }

            const modalEl = document.getElementById("basket-popup-modal");
            if (modalEl) {
                // eslint-disable-next-line
                this.modal = new Modal(modalEl, modalOptions);
            }
        },
        updateAndDispatchBasket() {
            this.basketItemOverwrite = true;
            this.dispatchBasket(this.congressDetailId);

        },
        updateAndDispatchBasketDenied() {
            if (this.modal != null)
                this.modal.hide();
        },
        GetProductTypeIdFromTag(productTypeTag) {
            switch (productTypeTag) {
                case "participantType":
                    return 0;
                case "inside":
                    return 1;
                case "outside":
                    return 2;
                case "accommodation":
                    return 3;
                case "transfer":
                    return 4;
                case "course":
                    return 5;
                default:
                    return -1;
            }
        },
        isBasketOverrideRequired(id) {
            let required = false;

            const selectedUserEmail = this.$refs.orderWizard.getSelectedAccount()?.email;

            if (!selectedUserEmail) {
                return required;
            }

            const selectedUserBasket = this.getBasket.items.filter(
                (item) => item.email === selectedUserEmail
            );

            if (!this.getBasket || selectedUserBasket.length === 0 || this.basketItemOverwrite) {
                return required;
            }

            let overrideRequired = false;
            let productTypeId = -1;

            // Iterate through congress items and their participant types
            this.congressItems.some((congressItem) => {
                return congressItem.participantTypes.some((participantType) => {
                    return participantType.items.some((item) => {
                        if (item.id === id) {
                            const productType = congressItem.productType.toLowerCase();
                            productTypeId = this.GetProductTypeIdFromTag(productType);

                            // Check if any item in the basket matches the product type
                            selectedUserBasket.some((basketItem) => {
                                if (basketItem.productType === productTypeId) {
                                    this.basketItemForRemoving = basketItem;
                                    overrideRequired = true;
                                    return true; // Exit loop early
                                }
                                return false;
                            });

                            return true; // Exit loop early
                        }
                        return false;
                    });
                });
            });

            if (productTypeId !== -1) {
                required = overrideRequired;
            }

            return required;
        },
        noCurrentlyCongress() {
            this.modal.hide();
            this.toast.error(
                this.$t("error.FEATURE_VALIDATION_BASKET_CANNOT_CREATE_ANOTHER_BASKET")
            );
        },
        addAnotherCongress() {
            this.isBasketPopupLoading = true;
            service
                .delete("basket")
                .then((response) => {
                    useResponseToast({
                        response: response,
                        successCallback: () => {
                            this.dispatchBasket(this.congressDetailId);
                        },
                        errorCallback: () => { },
                        isSaveMessage: false,
                    });
                })
                .finally(() => {
                    this.isBasketPopupLoading = false;
                    this.modal.hide();
                });
        },
    },
    mounted() {
        this.initModal();
    },
    computed: {
        ...mapGetters(['getBasket', 'getLang']),
    },
}
</script>