<template>
  <content-wrapper>
    <div class="flex items-center flex-col lg:flex-row">
      <div class="w-full lg:w-1/3 mb-6 lg:mb-0">
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">
          {{ $t('user.congress.selectUser') }}
        </label>
        <select id="user" @change="selectAccountFromId($event.target.value)"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option :selected="accountSubId === null">
            {{ $t('user.congress.selectUser') }}
          </option>
          <option :selected="accountSubId === 'null'" value="null">
            {{ getAccountCurrent?.item.firstName }}
            {{ getAccountCurrent?.item.lastName }}
          </option>
          <option v-for="user in currentUsers" :key="user.id" :value="user.id" :selected="accountSubId === user.id">
            {{ user.firstName }} {{ user.lastName }}
          </option>
        </select>
      </div>
      <div class="w-full lg:w-auto flex-auto lg:mt-6 lg:pl-2">
        <button @click="openModal" :isLoading="isLoading" type="button"
          class="w-auto inline-flex gap-1 items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
          <PlusIcon class="h-5 w-5 text-white font-bold" />
          {{ $t('user.congress.createANewAccount') }}
        </button>
      </div>
    </div>
    <div id="account-sub-form-modal" tabindex="-1" aria-hidden="true"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
      <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
        <!-- Modal content -->
        <div
          class="modal-scroll relative bg-white rounded-lg shadow h-full md:h-[650px] overflow-auto dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
              {{ $t('user.congress.newAccount') }}
            </h3>
            <button type="button" @click="closeModal"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
              <XMarkIcon class="w-5 h-5" />
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <account-sub-form :fetchAccountSubCurrentUser="fetchAccountSubCurrentUser" :selectAccount="this.selectAccountFromId"
              :closeModal="closeModal" />
          </div>
        </div>
      </div>
    </div>
  </content-wrapper>
</template>

<script>
import { XMarkIcon, PlusIcon } from '@heroicons/vue/20/solid'
import { useResponseToast } from '@/common/hooks'
import { service } from '@/common/services'
import { ContentWrapper } from '@/components'
import AccountSubForm from './AccountSubForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectAccount',
  components: { AccountSubForm, XMarkIcon, ContentWrapper, PlusIcon },
  data() {
    return {
      isLoading: false,
      modal: null,
      currentUsers: [],
      selectedId: null,
    }
  },
  props: {
    selectAccount: Function,
    accountSubId: String,
  },
  beforeMount() {
    this.fetchAccountSubCurrentUser()
  },
  mounted() {
    this.initModal()
  },
  methods: {
    fetchAccountSubCurrentUser() {
      service
        .fetch('accountSub/current-user')
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.currentUsers = response.items
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
    async fetchAccountSubCurrentUserPromise(){
      return service
        .fetch('accountSub/current-user')
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.currentUsers = response.items
            },
          })
        })
    },
    openModal() {
      this.modal.show()
    },
    closeModal() {
      this.modal.hide()
    },
    initModal() {
      const modalEl = document.getElementById('account-sub-form-modal')
      const modalOptions = {
        placement: 'center-center',
        backdropClasses:
          'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        onShow: () => {
          document.querySelector(
            '#account-sub-form-modal .modal-scroll',
          ).scrollTop = 0
        },
      }
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions)
      }
    },
    async personFromId(id) {
      this.selectedId = id;
      if (id == "null") {
        //console.log('returend from getAccountCurrent', this.getAccountCurrent?.item.firstName + " " + this.getAccountCurrent?.item.lastName)
        return this.getAccountCurrent?.item.firstName + " " + this.getAccountCurrent?.item.lastName;
      }

      if (this.currentUsers.find((user) => user.id === id) == undefined) {
        await this.fetchAccountSubCurrentUserPromise();
      }
      //console.log('personFromId', id)
      let person = this.currentUsers.find((user) => user.id === id);
      //console.log('personFromId', person)
      return person.firstName + ' ' + person.lastName;
    },
    async selectAccountFromId(id) {
      if (this.selectedId != id){
        this.selectedId = id;
      }

      let user = await this.personFromId(id);
      this.selectAccount(id, user);
    },
    getSelectedAccount() {
      if (this.selectedId == "null") {
        return this.getAccountCurrent?.item;
      }
      return this.currentUsers.find((user) => user.id === this.selectedId);

    },
  },
  computed: {
    ...mapGetters(['getAccountCurrent']),
  },
}
</script>
