import axios from "axios";
import { API_BASE_URL } from "@/environment/environment";

class RequestService {
  dispatch(url, data = null, config = {}) {
    config.withCredentials = true;
    config.headers = config.headers ? config.headers : {};
    config.headers["Content-Type"] = "application/json";
    return axios
      .post(`${API_BASE_URL}/${url}`, data, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  fetch(url, params = {}, config = {}) {
    config.withCredentials = true;
    config.params = {};
    for (const [key, value] of Object.entries(params)) {
      config.params[key] = value;
    }
    return axios
      .get(`${API_BASE_URL}/${url}`, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  update(url, data = null, config = {}) {
    config.withCredentials = true;
    config.headers = config.headers ? config.headers : {};
    config.headers["Content-Type"] = "application/json";
    return axios
      .put(`${API_BASE_URL}/${url}`, data, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  delete(url, params = null, config = {}) {
    config.withCredentials = true;
    if (params) {
      return axios
        .delete(`${API_BASE_URL}/${url}/${params}`, config)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });
    } else {
      return axios
        .delete(`${API_BASE_URL}/${url}`, config)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });
    }
  }
}

export default new RequestService();
