<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 915.46 179.55"
  >
    <g>
      <g>
        <path
          class="cls-157"
          d="M146.13,166.05l13.83-33.75c6.2,4.34,11.73,7.5,16.59,9.51,4.86,2,9.4,3,13.62,3,2.96,0,5.3-.56,7-1.69,1.7-1.12,2.55-2.65,2.55-4.57,0-3.35-4.25-5.9-12.76-7.66-2.74-.6-4.89-1.07-6.42-1.4-9-2.2-15.89-6.09-20.66-11.69-4.77-5.6-7.16-12.57-7.16-20.91,0-12.95,4.64-23.49,13.91-31.61,9.27-8.12,21.43-12.18,36.47-12.18,6.91,0,13.72,.91,20.41,2.72,6.7,1.81,13.44,4.56,20.25,8.23l-13.17,31.53c-4.17-3.07-8.36-5.42-12.55-7.04-4.2-1.62-8.27-2.43-12.22-2.43-3.02,0-5.35,.51-7,1.52s-2.47,2.43-2.47,4.24c0,3.4,6.01,6.39,18.03,8.97,1.92,.44,3.4,.77,4.44,.99,8.29,1.87,14.82,5.68,19.59,11.44s7.16,12.73,7.16,20.91c0,13.34-4.82,24.23-14.45,32.68-9.63,8.45-22.16,12.68-37.58,12.68-7.9,0-15.8-1.13-23.71-3.38-7.9-2.25-15.8-5.62-23.71-10.13Z"
        />
        <path
          class="cls-157"
          d="M253.32,176.34V55.82h80.59v32.68h-38.11v11.61h34.57v30.87h-34.57v12.68h39.1v32.68h-81.58Z"
        />
        <path
          class="cls-157"
          d="M345.69,176.34V55.82h54.49c15.81,0,27.88,3.49,36.22,10.45,8.34,6.97,12.51,17.01,12.51,30.13,0,8.56-2.1,15.61-6.3,21.16-4.2,5.54-9.86,8.78-17,9.71l32.19,49.06h-48.57l-21.07-39.76v39.76h-42.48Zm42.48-89.48v23.54h6.75c4.12,0,7.29-1,9.51-3,2.22-2,3.33-4.87,3.33-8.6s-1.11-6.79-3.33-8.85c-2.22-2.06-5.39-3.09-9.51-3.09h-6.75Z"
        />
        <path
          class="cls-157"
          d="M459.62,176.34V55.82h80.59v32.68h-38.11v11.61h34.57v30.87h-34.57v12.68h39.1v32.68h-81.58Z"
        />
        <path
          class="cls-157"
          d="M551.99,176.34V55.82h42.48l37.45,63.06c-.49-2.8-.84-5.9-1.03-9.3-.19-3.4-.29-7.85-.29-13.34V55.82h41.74v120.51h-42.81l-37.04-62.56c.44,2.69,.77,5.59,.99,8.68,.22,3.1,.33,6.57,.33,10.41v43.46h-41.82Z"
        />
        <path
          class="cls-157"
          d="M678.51,176.34l42.97-120.51h48.24l42.15,120.51h-46.59l-3.87-15.15h-32.68l-3.7,15.15h-46.51Zm57.46-43.79h18.03l-3.7-13.66c-1.81-6.92-3.05-12.24-3.7-15.97-.66-3.73-1.07-7.24-1.23-10.54h-.74c-.16,3.29-.58,6.8-1.23,10.54s-1.89,9.05-3.7,15.97l-3.7,13.66Z"
        />
        <path
          class="cls-157"
          d="M816.02,166.05l13.83-33.75c6.2,4.34,11.73,7.5,16.59,9.51,4.86,2,9.4,3,13.62,3,2.96,0,5.3-.56,7-1.69,1.7-1.12,2.55-2.65,2.55-4.57,0-3.35-4.25-5.9-12.76-7.66-2.74-.6-4.89-1.07-6.42-1.4-9-2.2-15.89-6.09-20.66-11.69-4.77-5.6-7.16-12.57-7.16-20.91,0-12.95,4.64-23.49,13.91-31.61,9.27-8.12,21.43-12.18,36.47-12.18,6.91,0,13.72,.91,20.41,2.72,6.7,1.81,13.44,4.56,20.25,8.23l-13.17,31.53c-4.17-3.07-8.36-5.42-12.55-7.04-4.2-1.62-8.27-2.43-12.22-2.43-3.02,0-5.35,.51-7,1.52s-2.47,2.43-2.47,4.24c0,3.4,6.01,6.39,18.03,8.97,1.92,.44,3.4,.77,4.44,.99,8.29,1.87,14.82,5.68,19.59,11.44,4.77,5.76,7.16,12.73,7.16,20.91,0,13.34-4.82,24.23-14.45,32.68-9.63,8.45-22.16,12.68-37.58,12.68-7.9,0-15.8-1.13-23.71-3.38-7.9-2.25-15.8-5.62-23.71-10.13Z"
        />
      </g>
      <g>
        <path
          class="cls-157"
          d="M835.58,16.91c0,2.36-.42,4.56-1.25,6.59-.84,2.03-2.06,3.85-3.68,5.45-1.61,1.57-3.47,2.77-5.58,3.61-2.11,.84-4.35,1.25-6.73,1.25s-4.66-.42-6.76-1.25-3.96-2.05-5.59-3.65c-1.6-1.56-2.81-3.35-3.63-5.37-.82-2.03-1.23-4.23-1.23-6.63s.41-4.61,1.23-6.65c.82-2.04,2.03-3.84,3.63-5.39,1.61-1.59,3.47-2.79,5.57-3.62,2.1-.83,4.36-1.24,6.78-1.24s4.68,.41,6.78,1.24c2.1,.83,3.96,2.04,5.59,3.62,1.58,1.57,2.79,3.38,3.62,5.42s1.24,4.25,1.24,6.63Zm-17.24,6.08c1.63,0,2.94-.55,3.93-1.66s1.49-2.58,1.49-4.41-.5-3.34-1.49-4.46-2.3-1.68-3.93-1.68-2.94,.57-3.94,1.7c-1,1.13-1.5,2.61-1.5,4.45s.5,3.31,1.5,4.41c1,1.11,2.31,1.66,3.94,1.66Z"
        />
        <path
          class="cls-157"
          d="M849.46,32.96V.73h13.85c4.87,0,8.43,.94,10.69,2.82,2.25,1.88,3.38,4.86,3.38,8.94,0,3.8-1.01,6.58-3.04,8.33-2.03,1.75-5.24,2.63-9.64,2.63h-3.63v9.51h-11.6Zm11.65-23.27v5.37h1.81c1.1,0,1.94-.23,2.53-.69,.59-.46,.88-1.12,.88-1.97s-.3-1.52-.9-1.99c-.6-.48-1.44-.72-2.51-.72h-1.81Z"
        />
        <path
          class="cls-157"
          d="M887.47,30.2l3.7-9.03c1.66,1.16,3.14,2.01,4.44,2.54,1.3,.54,2.51,.8,3.64,.8,.79,0,1.42-.15,1.87-.45,.45-.3,.68-.71,.68-1.22,0-.9-1.14-1.58-3.41-2.05-.73-.16-1.31-.29-1.72-.37-2.41-.59-4.25-1.63-5.53-3.13-1.28-1.5-1.92-3.36-1.92-5.59,0-3.46,1.24-6.28,3.72-8.45,2.48-2.17,5.73-3.26,9.75-3.26,1.85,0,3.67,.24,5.46,.73s3.6,1.22,5.42,2.2l-3.52,8.43c-1.12-.82-2.23-1.45-3.36-1.88-1.12-.43-2.21-.65-3.27-.65-.81,0-1.43,.14-1.87,.41-.44,.27-.66,.65-.66,1.13,0,.91,1.61,1.71,4.82,2.4,.51,.12,.91,.21,1.19,.26,2.22,.5,3.96,1.52,5.24,3.06,1.28,1.54,1.92,3.41,1.92,5.59,0,3.57-1.29,6.48-3.86,8.74-2.58,2.26-5.93,3.39-10.05,3.39-2.11,0-4.23-.3-6.34-.9s-4.23-1.5-6.34-2.71Z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle class="cls-33" cx="60.23" cy="159.62" r="16.66" />
        <circle class="cls-26" cx="62.07" cy="159.58" r="16.66" />
        <circle class="cls-29" cx="63.89" cy="159.46" r="16.66" />
        <circle class="cls-4" cx="65.69" cy="159.28" r="16.66" />
        <circle class="cls-3" cx="67.47" cy="159.02" r="16.66" />
        <circle class="cls-5" cx="69.22" cy="158.69" r="16.66" />
        <circle class="cls-2" cx="70.95" cy="158.29" r="16.66" />
        <circle class="cls-13" cx="72.65" cy="157.82" r="16.66" />
        <circle class="cls-21" cx="74.33" cy="157.28" r="16.66" />
        <circle class="cls-1" cx="75.97" cy="156.68" r="16.66" />
        <circle class="cls-19" cx="77.58" cy="156.02" r="16.66" />
        <circle class="cls-23" cx="79.16" cy="155.3" r="16.66" />
        <circle class="cls-24" cx="80.71" cy="154.51" r="16.66" />
        <circle class="cls-11" cx="82.21" cy="153.67" r="16.66" />
        <circle class="cls-31" cx="83.68" cy="152.77" r="16.66" />
        <circle class="cls-9" cx="85.12" cy="151.81" r="16.66" />
        <circle class="cls-17" cx="86.51" cy="150.8" r="16.66" />
        <circle class="cls-30" cx="87.85" cy="149.74" r="16.66" />
        <circle class="cls-10" cx="89.16" cy="148.62" r="16.66" />
        <circle class="cls-28" cx="90.42" cy="147.46" r="16.66" />
        <circle class="cls-28" cx="91.63" cy="146.24" r="16.66" />
        <circle class="cls-22" cx="92.8" cy="144.98" r="16.66" />
        <circle class="cls-18" cx="93.91" cy="143.68" r="16.66" />
        <circle class="cls-14" cx="94.98" cy="142.33" r="16.66" />
        <circle class="cls-12" cx="95.99" cy="140.94" r="16.66" />
        <circle class="cls-27" cx="96.94" cy="139.51" r="16.66" />
        <circle class="cls-32" cx="97.84" cy="138.04" r="16.66" />
        <circle class="cls-16" cx="98.69" cy="136.53" r="16.66" />
        <circle class="cls-15" cx="99.47" cy="134.99" r="16.66" />
        <circle class="cls-20" cx="100.2" cy="133.41" r="16.66" />
        <circle class="cls-132" cx="100.86" cy="131.8" r="16.66" />
        <circle class="cls-149" cx="101.46" cy="130.15" r="16.66" />
        <circle class="cls-155" cx="101.99" cy="128.48" r="16.66" />
        <circle class="cls-140" cx="102.46" cy="126.78" r="16.66" />
        <circle class="cls-148" cx="102.86" cy="125.05" r="16.66" />
        <circle class="cls-126" cx="103.19" cy="123.29" r="16.66" />
        <circle class="cls-125" cx="103.45" cy="121.51" r="16.66" />
        <circle class="cls-150" cx="103.64" cy="119.71" r="16.66" />
        <circle class="cls-147" cx="103.75" cy="117.89" r="16.66" />
        <circle class="cls-139" cx="103.79" cy="116.05" r="16.66" />
        <circle class="cls-141" cx="103.78" cy="115.24" r="16.66" />
        <circle class="cls-142" cx="103.76" cy="114.43" r="16.66" />
        <circle class="cls-105" cx="103.73" cy="113.63" r="16.66" />
        <circle class="cls-116" cx="103.68" cy="112.83" r="16.66" />
        <circle class="cls-153" cx="103.61" cy="112.04" r="16.66" />
        <circle class="cls-154" cx="103.53" cy="111.25" r="16.66" />
        <circle class="cls-156" cx="103.44" cy="110.46" r="16.66" />
        <circle class="cls-137" cx="103.33" cy="109.68" r="16.66" />
        <circle class="cls-138" cx="103.21" cy="108.91" r="16.66" />
        <circle class="cls-136" cx="103.07" cy="108.13" r="16.66" />
        <circle class="cls-128" cx="102.93" cy="107.36" r="16.66" />
        <circle class="cls-129" cx="102.76" cy="106.6" r="16.66" />
        <circle class="cls-123" cx="102.59" cy="105.84" r="16.66" />
        <circle class="cls-122" cx="102.4" cy="105.09" r="16.66" />
        <circle class="cls-90" cx="102.2" cy="104.34" r="16.66" />
        <circle class="cls-87" cx="101.99" cy="103.6" r="16.66" />
        <circle class="cls-89" cx="101.76" cy="102.86" r="16.66" />
        <circle class="cls-88" cx="101.52" cy="102.13" r="16.66" />
        <circle class="cls-118" cx="101.27" cy="101.41" r="16.66" />
        <circle class="cls-127" cx="101.01" cy="100.69" r="16.66" />
        <circle class="cls-119" cx="100.73" cy="99.97" r="16.66" />
        <circle class="cls-120" cx="100.44" cy="99.27" r="16.66" />
        <circle class="cls-121" cx="100.14" cy="98.57" r="16.66" />
        <circle class="cls-151" cx="99.83" cy="97.87" r="16.66" />
        <circle class="cls-152" cx="99.51" cy="97.18" r="16.66" />
        <circle class="cls-124" cx="99.17" cy="96.5" r="16.66" />
        <circle class="cls-131" cx="98.82" cy="95.83" r="16.66" />
        <circle class="cls-133" cx="98.47" cy="95.16" r="16.66" />
        <circle class="cls-146" cx="98.1" cy="94.5" r="16.66" />
        <circle class="cls-145" cx="97.72" cy="93.84" r="16.66" />
        <circle class="cls-130" cx="97.32" cy="93.2" r="16.66" />
        <circle class="cls-70" cx="96.92" cy="92.56" r="16.66" />
        <circle class="cls-69" cx="96.51" cy="91.93" r="16.66" />
        <circle class="cls-75" cx="96.09" cy="91.3" r="16.66" />
        <circle class="cls-114" cx="95.65" cy="90.69" r="16.66" />
        <circle class="cls-96" cx="95.21" cy="90.08" r="16.66" />
        <circle class="cls-95" cx="94.75" cy="89.48" r="16.66" />
        <circle class="cls-117" cx="94.29" cy="88.88" r="16.66" />
        <circle class="cls-115" cx="93.81" cy="88.3" r="16.66" />
        <circle class="cls-99" cx="93.33" cy="87.73" r="16.66" />
        <circle class="cls-98" cx="92.84" cy="87.16" r="16.66" />
        <circle class="cls-109" cx="92.33" cy="86.6" r="16.66" />
        <circle class="cls-79" cx="91.82" cy="86.05" r="16.66" />
        <circle class="cls-82" cx="91.3" cy="85.51" r="16.66" />
        <circle class="cls-83" cx="90.76" cy="84.98" r="16.66" />
        <circle class="cls-65" cx="90.22" cy="84.46" r="16.66" />
        <circle class="cls-59" cx="89.67" cy="83.94" r="16.66" />
        <circle class="cls-76" cx="89.12" cy="83.44" r="16.66" />
        <circle class="cls-64" cx="88.55" cy="82.95" r="16.66" />
        <circle class="cls-60" cx="87.97" cy="82.46" r="16.66" />
        <circle class="cls-58" cx="87.39" cy="81.99" r="16.66" />
        <circle class="cls-74" cx="86.8" cy="81.52" r="16.66" />
        <circle class="cls-73" cx="86.2" cy="81.07" r="16.66" />
        <circle class="cls-104" cx="85.59" cy="80.62" r="16.66" />
        <circle class="cls-107" cx="84.97" cy="80.19" r="16.66" />
        <circle class="cls-103" cx="84.35" cy="79.77" r="16.66" />
        <circle class="cls-101" cx="83.72" cy="79.35" r="16.66" />
        <circle class="cls-102" cx="83.08" cy="78.95" r="16.66" />
        <circle class="cls-143" cx="82.43" cy="78.56" r="16.66" />
        <circle class="cls-144" cx="81.78" cy="78.18" r="16.66" />
        <circle class="cls-91" cx="81.12" cy="77.81" r="16.66" />
        <circle class="cls-106" cx="80.45" cy="77.45" r="16.66" />
        <circle class="cls-134" cx="79.77" cy="77.1" r="16.66" />
        <circle class="cls-135" cx="79.09" cy="76.77" r="16.66" />
        <circle class="cls-94" cx="78.4" cy="76.44" r="16.66" />
        <circle class="cls-97" cx="77.71" cy="76.13" r="16.66" />
        <circle class="cls-112" cx="77.01" cy="75.83" r="16.66" />
        <circle class="cls-113" cx="76.3" cy="75.54" r="16.66" />
        <circle class="cls-92" cx="75.59" cy="75.27" r="16.66" />
        <circle class="cls-111" cx="74.87" cy="75" r="16.66" />
        <circle class="cls-100" cx="74.14" cy="74.75" r="16.66" />
        <circle class="cls-93" cx="73.41" cy="74.51" r="16.66" />
        <circle class="cls-108" cx="72.67" cy="74.29" r="16.66" />
        <circle class="cls-110" cx="71.93" cy="74.07" r="16.66" />
        <circle class="cls-164" cx="71.18" cy="73.87" r="16.66" />
        <circle class="cls-159" cx="70.43" cy="73.68" r="16.66" />
        <circle class="cls-165" cx="69.67" cy="73.51" r="16.66" />
        <circle class="cls-67" cx="68.91" cy="73.35" r="16.66" />
        <circle class="cls-54" cx="68.14" cy="73.2" r="16.66" />
        <circle class="cls-55" cx="67.37" cy="73.07" r="16.66" />
        <circle class="cls-52" cx="66.59" cy="72.94" r="16.66" />
        <circle class="cls-66" cx="65.81" cy="72.84" r="16.66" />
        <circle class="cls-62" cx="65.02" cy="72.74" r="16.66" />
        <circle class="cls-63" cx="64.23" cy="72.66" r="16.66" />
        <circle class="cls-50" cx="63.44" cy="72.6" r="16.66" />
        <circle class="cls-173" cx="62.64" cy="72.55" r="16.66" />
        <circle class="cls-167" cx="61.84" cy="72.51" r="16.66" />
        <circle class="cls-168" cx="61.03" cy="72.49" r="16.66" />
        <circle class="cls-42" cx="60.23" cy="72.48" r="16.66" />
        <circle class="cls-44" cx="58.05" cy="72.54" r="16.66" />
        <circle class="cls-46" cx="55.9" cy="72.69" r="16.66" />
        <circle class="cls-41" cx="53.79" cy="72.95" r="16.66" />
        <circle class="cls-163" cx="51.7" cy="73.32" r="16.66" />
        <circle class="cls-162" cx="49.65" cy="73.77" r="16.66" />
        <circle class="cls-158" cx="47.64" cy="74.33" r="16.66" />
        <circle class="cls-86" cx="45.67" cy="74.97" r="16.66" />
        <circle class="cls-51" cx="43.74" cy="75.71" r="16.66" />
        <circle class="cls-68" cx="41.86" cy="76.53" r="16.66" />
        <circle class="cls-72" cx="40.02" cy="77.44" r="16.66" />
        <circle class="cls-71" cx="38.24" cy="78.43" r="16.66" />
        <circle class="cls-80" cx="36.5" cy="79.5" r="16.66" />
        <circle class="cls-81" cx="34.82" cy="80.65" r="16.66" />
        <circle class="cls-84" cx="33.2" cy="81.87" r="16.66" />
        <circle class="cls-85" cx="31.64" cy="83.17" r="16.66" />
        <circle class="cls-49" cx="30.14" cy="84.53" r="16.66" />
        <circle class="cls-61" cx="28.71" cy="85.97" r="16.66" />
        <circle class="cls-169" cx="27.34" cy="87.47" r="16.66" />
        <circle class="cls-170" cx="26.05" cy="89.03" r="16.66" />
        <circle class="cls-172" cx="24.83" cy="90.65" r="16.66" />
        <circle class="cls-171" cx="23.68" cy="92.33" r="16.66" />
        <circle class="cls-57" cx="22.61" cy="94.06" r="16.66" />
        <circle class="cls-56" cx="21.62" cy="95.85" r="16.66" />
        <circle class="cls-53" cx="20.71" cy="97.68" r="16.66" />
        <circle class="cls-208" cx="19.88" cy="99.57" r="16.66" />
        <circle class="cls-210" cx="19.15" cy="101.5" r="16.66" />
        <circle class="cls-39" cx="18.5" cy="103.47" r="16.66" />
        <circle class="cls-179" cx="17.95" cy="105.48" r="16.66" />
        <circle class="cls-178" cx="17.49" cy="107.53" r="16.66" />
        <circle class="cls-48" cx="17.13" cy="109.61" r="16.66" />
        <circle class="cls-36" cx="16.87" cy="111.73" r="16.66" />
        <circle class="cls-34" cx="16.71" cy="113.88" r="16.66" />
        <circle class="cls-45" cx="16.66" cy="116.05" r="16.66" />
        <circle class="cls-204" cx="16.66" cy="116.89" r="16.66" />
        <circle class="cls-211" cx="16.66" cy="117.73" r="16.66" />
        <circle class="cls-214" cx="16.66" cy="118.56" r="16.66" />
        <circle class="cls-202" cx="16.66" cy="119.4" r="16.66" />
        <circle class="cls-201" cx="16.66" cy="120.24" r="16.66" />
        <circle class="cls-206" cx="16.66" cy="121.08" r="16.66" />
        <circle class="cls-200" cx="16.66" cy="121.91" r="16.66" />
        <circle class="cls-199" cx="16.66" cy="122.75" r="16.66" />
        <circle class="cls-38" cx="16.66" cy="123.59" r="16.66" />
        <circle class="cls-37" cx="16.66" cy="124.43" r="16.66" />
        <circle class="cls-78" cx="16.66" cy="125.27" r="16.66" />
        <circle class="cls-161" cx="16.66" cy="126.1" r="16.66" />
        <circle class="cls-174" cx="16.66" cy="126.94" r="16.66" />
        <circle class="cls-175" cx="16.66" cy="127.78" r="16.66" />
        <circle class="cls-176" cx="16.66" cy="128.62" r="16.66" />
        <circle class="cls-160" cx="16.66" cy="129.45" r="16.66" />
        <circle class="cls-177" cx="16.66" cy="130.29" r="16.66" />
        <circle class="cls-43" cx="16.66" cy="131.13" r="16.66" />
        <circle class="cls-47" cx="16.66" cy="131.97" r="16.66" />
        <circle class="cls-35" cx="16.66" cy="132.81" r="16.66" />
        <circle class="cls-6" cx="16.66" cy="133.64" r="16.66" />
        <circle class="cls-7" cx="16.66" cy="134.48" r="16.66" />
        <circle class="cls-8" cx="16.66" cy="135.32" r="16.66" />
        <circle class="cls-77" cx="16.66" cy="136.16" r="16.66" />
        <circle class="cls-40" cx="16.66" cy="137" r="16.66" />
        <circle class="cls-181" cx="16.66" cy="137.83" r="16.66" />
        <circle class="cls-182" cx="16.66" cy="138.67" r="16.66" />
        <circle class="cls-195" cx="16.66" cy="139.51" r="16.66" />
        <circle class="cls-192" cx="16.66" cy="140.35" r="16.66" />
        <circle class="cls-196" cx="16.66" cy="141.18" r="16.66" />
        <circle class="cls-197" cx="16.66" cy="142.02" r="16.66" />
        <circle class="cls-194" cx="16.66" cy="142.86" r="16.66" />
        <circle class="cls-203" cx="16.66" cy="143.7" r="16.66" />
        <circle class="cls-187" cx="16.66" cy="144.54" r="16.66" />
        <circle class="cls-183" cx="16.66" cy="145.37" r="16.66" />
        <circle class="cls-184" cx="16.66" cy="146.21" r="16.66" />
        <circle class="cls-188" cx="16.66" cy="147.05" r="16.66" />
        <circle class="cls-186" cx="16.66" cy="147.89" r="16.66" />
        <circle class="cls-185" cx="16.66" cy="148.72" r="16.66" />
        <circle class="cls-189" cx="16.66" cy="149.56" r="16.66" />
        <circle class="cls-190" cx="16.66" cy="150.4" r="16.66" />
        <circle class="cls-180" cx="16.66" cy="151.24" r="16.66" />
        <circle class="cls-198" cx="16.66" cy="152.08" r="16.66" />
        <circle class="cls-166" cx="16.66" cy="152.91" r="16.66" />
        <circle class="cls-205" cx="16.66" cy="153.75" r="16.66" />
        <circle class="cls-213" cx="16.66" cy="154.59" r="16.66" />
        <circle class="cls-207" cx="16.66" cy="155.43" r="16.66" />
        <circle class="cls-209" cx="16.66" cy="156.27" r="16.66" />
        <circle class="cls-215" cx="16.66" cy="157.1" r="16.66" />
        <circle class="cls-212" cx="16.66" cy="157.94" r="16.66" />
        <circle class="cls-191" cx="16.66" cy="158.78" r="16.66" />
        <circle class="cls-193" cx="16.66" cy="159.62" r="16.66" />
      </g>
      <g>
        <circle class="cls-25" cx="60.23" cy="116.05" r="16.66" />
        <circle class="cls-25" cx="60.23" cy="116.05" r="10.25" />
      </g>
    </g>
  </svg>
</template>

<style>
.cls-1 {
  fill: #f71755;
}

.cls-2 {
  fill: #fa1952;
}

.cls-3 {
  fill: #fc1b50;
}

.cls-4 {
  fill: #fc1c4f;
}

.cls-5 {
  fill: #fb1a51;
}

.cls-6 {
  fill: #6a17e1;
}

.cls-7 {
  fill: #6918e0;
}

.cls-8 {
  fill: #6819e0;
}

.cls-9 {
  fill: #f2125b;
}

.cls-10 {
  fill: #ef105e;
}

.cls-11 {
  fill: #f41459;
}

.cls-12 {
  fill: #ea0c63;
}

.cls-13 {
  fill: #f91953;
}

.cls-14 {
  fill: #eb0c62;
}

.cls-15 {
  fill: #e70867;
}

.cls-16 {
  fill: #e70966;
}

.cls-17 {
  fill: #f1125c;
}

.cls-18 {
  fill: #ec0d61;
}

.cls-19 {
  fill: #f61656;
}

.cls-20 {
  fill: #e60868;
}

.cls-21 {
  fill: #f81854;
}

.cls-22 {
  fill: #ed0e60;
}

.cls-23 {
  fill: #f51657;
}

.cls-24 {
  fill: #f51558;
}

.cls-25 {
  fill: #ffb600;
}

.cls-26 {
  fill: #fe1d4d;
}

.cls-27 {
  fill: #e90b64;
}

.cls-28 {
  fill: #ee0f5f;
}

.cls-29 {
  fill: #fd1c4e;
}

.cls-30 {
  fill: #f0115d;
}

.cls-31 {
  fill: #f3135a;
}

.cls-32 {
  fill: #e80a65;
}

.cls-33 {
  fill: #ff1e4c;
}

.cls-34 {
  fill: #7f03e9;
}

.cls-35 {
  fill: #6b16e1;
}

.cls-36 {
  fill: #8003e8;
}

.cls-37 {
  fill: #740ce6;
}

.cls-38 {
  fill: #750be6;
}

.cls-39 {
  fill: #8306e4;
}

.cls-40 {
  fill: #661bdf;
}

.cls-41 {
  fill: #9815cd;
}

.cls-42 {
  fill: #9b17ca;
}

.cls-43 {
  fill: #6d14e2;
}

.cls-44 {
  fill: #9a16cb;
}

.cls-45 {
  fill: #7e02ea;
}

.cls-46 {
  fill: #9916cc;
}

.cls-47 {
  fill: #6c15e2;
}

.cls-48 {
  fill: #8104e7;
}

.cls-49 {
  fill: #8d0dda;
}

.cls-50 {
  fill: #9e16c6;
}

.cls-51 {
  fill: #9412d2;
}

.cls-52 {
  fill: #a115c2;
}

.cls-53 {
  fill: #8608e1;
}

.cls-54 {
  fill: #a214c0;
}

.cls-55 {
  fill: #a215c1;
}

.cls-56 {
  fill: #8708e0;
}

.cls-57 {
  fill: #8809df;
}

.cls-58 {
  fill: #b70da4;
}

.cls-59 {
  fill: #ba0ca0;
}

.cls-60 {
  fill: #b80da3;
}

.cls-61 {
  fill: #8c0cda;
}

.cls-62 {
  fill: #9f15c4;
}

.cls-63 {
  fill: #9f16c5;
}

.cls-64 {
  fill: #b90da2;
}

.cls-65 {
  fill: #bb0c9f;
}

.cls-66 {
  fill: #a015c3;
}

.cls-67 {
  fill: #a314bf;
}

.cls-68 {
  fill: #9311d3;
}

.cls-69 {
  fill: #c50993;
}

.cls-70 {
  fill: #c50892;
}

.cls-71 {
  fill: #9110d5;
}

.cls-72 {
  fill: #9211d4;
}

.cls-73 {
  fill: #b60ea6;
}

.cls-74 {
  fill: #b60da5;
}

.cls-75 {
  fill: #c40994;
}

.cls-76 {
  fill: #b90ca1;
}

.cls-77 {
  fill: #671adf;
}

.cls-78 {
  fill: #730de5;
}

.cls-79 {
  fill: #bd0b9d;
}

.cls-80 {
  fill: #900fd6;
}

.cls-81 {
  fill: #900fd7;
}

.cls-82 {
  fill: #bc0b9e;
}

.cls-83 {
  fill: #bc0c9e;
}

.cls-84 {
  fill: #8f0ed8;
}

.cls-85 {
  fill: #8e0dd9;
}

.cls-86 {
  fill: #9513d1;
}

.cls-87 {
  fill: #d10482;
}

.cls-88 {
  fill: #d00584;
}

.cls-89 {
  fill: #d00483;
}

.cls-90 {
  fill: #d20481;
}

.cls-91 {
  fill: #b010ae;
}

.cls-92 {
  fill: #aa12b6;
}

.cls-93 {
  fill: #a813b9;
}

.cls-94 {
  fill: #ad11b2;
}

.cls-95 {
  fill: #c20a97;
}

.cls-96 {
  fill: #c20996;
}

.cls-97 {
  fill: #ac11b3;
}

.cls-98 {
  fill: #bf0b9b;
}

.cls-99 {
  fill: #bf0a9a;
}

.cls-100 {
  fill: #a812b8;
}

.cls-101 {
  fill: #b30faa;
}

.cls-102 {
  fill: #b20fab;
}

.cls-103 {
  fill: #b30ea9;
}

.cls-104 {
  fill: #b50ea7;
}

.cls-105 {
  fill: #db0175;
}

.cls-106 {
  fill: #af10af;
}

.cls-107 {
  fill: #b40ea8;
}

.cls-108 {
  fill: #a713ba;
}

.cls-109 {
  fill: #be0b9c;
}

.cls-110 {
  fill: #a613bb;
}

.cls-111 {
  fill: #a912b7;
}

.cls-112 {
  fill: #ab11b4;
}

.cls-113 {
  fill: #ab12b5;
}

.cls-114 {
  fill: #c30995;
}

.cls-115 {
  fill: #c00a99;
}

.cls-116 {
  fill: #da0176;
}

.cls-117 {
  fill: #c10a98;
}

.cls-118 {
  fill: #cf0585;
}

.cls-119 {
  fill: #cd0587;
}

.cls-120 {
  fill: #cd0688;
}

.cls-121 {
  fill: #cc0689;
}

.cls-122 {
  fill: #d30480;
}

.cls-123 {
  fill: #d3037f;
}

.cls-124 {
  fill: #ca078c;
}

.cls-125 {
  fill: #e0026f;
}

.cls-126 {
  fill: #e0036e;
}

.cls-127 {
  fill: #ce0586;
}

.cls-128 {
  fill: #d5037d;
}

.cls-129 {
  fill: #d4037e;
}

.cls-130 {
  fill: #c60891;
}

.cls-131 {
  fill: #c9078d;
}

.cls-132 {
  fill: #e50769;
}

.cls-133 {
  fill: #c8078e;
}

.cls-134 {
  fill: #ae10b0;
}

.cls-135 {
  fill: #ae11b1;
}

.cls-136 {
  fill: #d6037c;
}

.cls-137 {
  fill: #d7027a;
}

.cls-138 {
  fill: #d6027b;
}

.cls-139 {
  fill: #dd0072;
}

.cls-140 {
  fill: #e2056c;
}

.cls-141 {
  fill: #dc0073;
}

.cls-142 {
  fill: #dc0174;
}

.cls-143 {
  fill: #b10fac;
}

.cls-144 {
  fill: #b110ad;
}

.cls-145 {
  fill: #c70890;
}

.cls-146 {
  fill: #c7078f;
}

.cls-147 {
  fill: #de0171;
}

.cls-148 {
  fill: #e1046d;
}

.cls-149 {
  fill: #e4066a;
}

.cls-150 {
  fill: #df0270;
}

.cls-151 {
  fill: #cb068a;
}

.cls-152 {
  fill: #ca068b;
}

.cls-153 {
  fill: #d90177;
}

.cls-154 {
  fill: #d90278;
}

.cls-155 {
  fill: #e3056b;
}

.cls-156 {
  fill: #d80279;
}

.cls-157 {
  fill: #13202a;
}

.cls-158 {
  fill: #9613d0;
}

.cls-159 {
  fill: #a514bd;
}

.cls-160 {
  fill: #6f12e3;
}

.cls-161 {
  fill: #720ee5;
}

.cls-162 {
  fill: #9714cf;
}

.cls-163 {
  fill: #9714ce;
}

.cls-164 {
  fill: #a513bc;
}

.cls-165 {
  fill: #a414be;
}

.cls-166 {
  fill: #542dd7;
}

.cls-167 {
  fill: #9c16c8;
}

.cls-168 {
  fill: #9c17c9;
}

.cls-169 {
  fill: #8b0cdb;
}

.cls-170 {
  fill: #8a0bdc;
}

.cls-171 {
  fill: #890ade;
}

.cls-172 {
  fill: #890add;
}

.cls-173 {
  fill: #9d16c7;
}

.cls-174 {
  fill: #710fe4;
}

.cls-175 {
  fill: #7110e4;
}

.cls-176 {
  fill: #7011e3;
}

.cls-177 {
  fill: #6e13e2;
}

.cls-178 {
  fill: #8205e6;
}

.cls-179 {
  fill: #8205e5;
}

.cls-180 {
  fill: #562bd7;
}

.cls-181 {
  fill: #651bdf;
}

.cls-182 {
  fill: #641cde;
}

.cls-183 {
  fill: #5c24db;
}

.cls-184 {
  fill: #5b25da;
}

.cls-185 {
  fill: #5928d9;
}

.cls-186 {
  fill: #5927d9;
}

.cls-187 {
  fill: #5d23db;
}

.cls-188 {
  fill: #5a26da;
}

.cls-189 {
  fill: #5829d8;
}

.cls-190 {
  fill: #572ad8;
}

.cls-191 {
  fill: #4d34d3;
}

.cls-192 {
  fill: #621edd;
}

.cls-193 {
  fill: #4c35d3;
}

.cls-194 {
  fill: #5f21dc;
}

.cls-195 {
  fill: #631dde;
}

.cls-196 {
  fill: #611fdd;
}

.cls-197 {
  fill: #6020dc;
}

.cls-198 {
  fill: #552cd7;
}

.cls-199 {
  fill: #760ae6;
}

.cls-200 {
  fill: #7709e7;
}

.cls-201 {
  fill: #7907e8;
}

.cls-202 {
  fill: #7a06e8;
}

.cls-203 {
  fill: #5e22db;
}

.cls-204 {
  fill: #7d03ea;
}

.cls-205 {
  fill: #532ed6;
}

.cls-206 {
  fill: #7808e7;
}

.cls-207 {
  fill: #5130d5;
}

.cls-208 {
  fill: #8507e2;
}

.cls-209 {
  fill: #5031d5;
}

.cls-210 {
  fill: #8406e3;
}

.cls-211 {
  fill: #7c04e9;
}

.cls-212 {
  fill: #4e33d4;
}

.cls-213 {
  fill: #522fd6;
}

.cls-214 {
  fill: #7b05e9;
}

.cls-215 {
  fill: #4f32d4;
}
</style>
>
