<template>
    <div class="flex justify-between items-center">
        <button type="button" @click="navigateBefore" class="flex gap-2 items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2
        focus:ring-offset-2 focus:ring-green-500">
            <ChevronLeftIcon class="w-5 h-5" />
            {{ this.$t('wizard.order.navigation.before') }}
        </button>
        <button type="button" @click="navigateNext" class="flex gap-2 items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2
            focus:ring-offset-2 focus:ring-green-500">
            {{ this.$t('wizard.order.navigation.next') }}
            <ChevronRightIcon class="w-5 h-5" />
        </button>
    </div>
</template>

<script>

import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'

export default {
    components: {
        ChevronRightIcon,
        ChevronLeftIcon,
    },
    props: {
        navigateBefore: Function,
        navigateNext: Function,
    },
}
</script>
