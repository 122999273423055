<template>
  <div :class="externalClass"
    class="flex flex-col justify-center items-center absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
    <img class="mb-5" src="@/assets/images/no-data.png" alt="No Data" />
    <slot></slot>
  </div>
  <div class="bottom-0 left-0 absolute hidden lg:block">
    <img src="@/assets/images/no-data-bg.png" />
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    externalClass: {}
  }
}
</script>
