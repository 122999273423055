const {VUE_APP_BACKEND, VUE_APP_DOMAIN, VUE_APP_LOCAL_DOMAIN, VUE_APP_AUTH_AUTHORITY_CLIENT_ID, VUE_APP_AUTH_AUTHORITY_DOMAIN} = process.env
let domain;
if(process.env.NODE_ENV === 'production'){
    domain = VUE_APP_DOMAIN;
} else {
    domain = VUE_APP_LOCAL_DOMAIN;
}
const VUE_BASE_URL = domain;
const API_BASE_URL = VUE_APP_BACKEND;
const OAUTH_AUTHORITY_URL = VUE_APP_AUTH_AUTHORITY_DOMAIN;
const OAUTH_CLIENT_ID = VUE_APP_AUTH_AUTHORITY_CLIENT_ID;
const OAUTH_REDIRECT_URL = domain.concat("/signin-oidc");
// console.log("DEBUG CONSTS PROD: ", domain)
export {API_BASE_URL, VUE_BASE_URL, OAUTH_AUTHORITY_URL, OAUTH_CLIENT_ID, OAUTH_REDIRECT_URL};