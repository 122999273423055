<template>
  <template v-if="basket.paymentPageOpenedAtIsValidUntil != null">
    <alert-wrapper :alertType="'info'" :messageKey="'user.basket.paymentPageOpenedDescription'" />
  </template>

  <div>

    <!--<template v-if="getStockQuantityValidation != null"> {{ getStockQuantityValidation }} </template>-->
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="p-4">
            {{ $t("user.basket.productType") }} -
            {{ $t("user.basket.name") }}
          </th>
          <th scope="col" class="p-4">
            {{ $t("user.basket.firstNameLastName") }}
          </th>
          <th scope="col" class="p-4">
            {{ $t("user.basket.price") }}
          </th>
          <th scope="col" class="p-4">
            {{ $t("user.basket.vat") }}
          </th>
          <th scope="col" class="p-4">
            {{ $t("user.basket.salePrice") }}
          </th>
          <th v-if="basket.paymentPageOpenedAtIsValidUntil == null" scope="col" class="py-3 w-12"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in basket.items" :key="item.id"
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          :style="getColorForItemGroup(item.id)">
          <td class="p-4 font-medium text-gray-900 dark:text-white">
            <p class="flex-1 truncate w-[180px] 2xl:w-auto" :title="`${getLang === 'TR' ? item.name : item.nameEn}`">
              <span v-if="item.productType === 1">{{ $t("shared.inside") }}</span>
              <span v-if="item.productType === 2">
                {{ $t("shared.outside") }}
              </span>
              <span v-if="item.productType === 3">
                {{ $t("shared.accommodation") }}
              </span>
              <span v-if="item.productType === 4">
                {{ $t("shared.transfer") }}
              </span>
              <span v-if="item.productType === 5">{{ $t("shared.course") }}</span>
              -
              <span v-if="getLang === 'TR'">
                {{ item.name }}
              </span>
              <span v-if="getLang === 'EN'">
                {{ item.nameEn }}
              </span>
            </p>
          </td>
          <th class="p-4">{{ item.firstName }} {{ item.surname }}</th>
          <th class="p-4">
            {{
              toCurrency(
                item.price,
                "tr-TR",
                "currency",
                getCongressProductCurrency(basket.productCurrency)
              )
            }}
          </th>
          <th class="p-4">
            <span v-if="item.vat != 0">{{
              toCurrency(
                item.vat,
                "tr-TR",
                "currency",
                getCongressProductCurrency(basket.productCurrency)
              )
            }}</span>
          </th>
          <th class="p-4">
            {{
              toCurrency(
                item.salePrice,
                "tr-TR",
                "currency",
                getCongressProductCurrency(basket.productCurrency)
              )
            }}
          </th>
          <td class="py-4 w-12">
            <div class="flex justify-center">


              <template v-if="this.getStockQuantityValidation != null && warnRemove(item.id)">
                <Popper :hover="true" :arrow="true" placement="top">
                  <button>
                    <ExclamationTriangleIcon class="w-6 h-6 text-[#f2841d]" />
                  </button>
                  <template #content>
                    <span class="text-xs whitespace-nowrap">
                      {{ $t("user.basket.stockInfo.warn") }} {{ getRequriedRemovedCount(item.id) }}
                    </span>
                  </template>
                </Popper>
              </template>

              <a @click="deleteBasketById(item.id)"
                class="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline">
                <XCircleIcon class="w-6 h-6" />
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { isProxy, toRaw } from "vue";
import moment from "moment";
import { useResponseToast, useToast } from "@/common/hooks";
import { service } from "@/common/services";
import { mapActions, mapGetters } from "vuex";
import { XCircleIcon, ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import { toCurrency } from "@/common/utils";
import Popper from "vue3-popper";

import AlertWrapper from "@/components/Alert/AlertWrapper.vue";

export default {
  name: "BasketTableList",
  components: { XCircleIcon, Popper, ExclamationTriangleIcon, AlertWrapper },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      moment: moment,
      stockQuantityValidation: null,
    };
  },
  props: {
    basket: Object,
  },
  methods: {
    getstockQuantityValidation() {
      if (this.getStockQuantityValidation == null) return null;
      if (this.stockQuantityValidation != null) return this.stockQuantityValidation;


      let rawData = this.getStockQuantityValidation;

      if (isProxy(this.getStockQuantityValidation)) {
        rawData = toRaw(this.getStockQuantityValidation)
      }

      this.stockQuantityValidation = rawData;
      return this.stockQuantityValidation;
    },
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    ...mapActions(["fetchBasket"]),
    deleteBasketById(id) {
      service.delete(`basket/${id}`).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.fetchBasket();
            this.removeItemFromStockQuantityValidation(id);
          },
          errorCallback: () => { },
          isSaveMessage: true,
        });
      });
    },
    warnRemove(id) {
      if (this.getStockQuantityValidation == null)
        return false;

      let _stockQuantityValidation = this.getstockQuantityValidation();

      var found = _stockQuantityValidation.some(f => {
        return f.BasketItems.some(ff => ff.BasketDetailId == id);
      });

      if (!found) {
        return false;
      }

      var restCount = this.getRequriedRemovedCount(id);
      return restCount > 0;
    },
    getRemoveItemGroup(id) {
      let _stockQuantityValidation = this.getstockQuantityValidation();
      let found;

      _stockQuantityValidation.forEach(f => {
        let has = f.BasketItems.some(ff => ff.BasketDetailId == id);
        if (has)
          found = f;
      });

      return found;
    },
    getRequriedRemovedCount(id) {
      var itemGroup = this.getRemoveItemGroup(id);

      return Math.abs(itemGroup.AfterStock);
    },
    getColorForItemGroup(id) {
      if (this.getStockQuantityValidation == null)
        return "";
      let _stockQuantityValidation = this.getstockQuantityValidation();

      let found;
      let index;

      _stockQuantityValidation.forEach((f, i) => {
        let has = f.BasketItems.some(ff => ff.BasketDetailId == id);
        if (has) {
          found = f;
          index = i;
        }
      });

      if (found == null)
        return "";

      var restCount = this.getRequriedRemovedCount(id);
      let isWarnRequired = restCount > 0;

      if (!isWarnRequired)
        return "";

      return this.getColor(index);
    },
    getColor(i) {
      switch (i) {
        case 0:
          return "background-color: #f8d7da; !important";
        case 1:
          return "background-color: #fff3cd; !important";
        case 2:
          return "background-color: #d4edda; !important";
        case 3:
          return "background-color: #cce5ff; !important";
        case 4:
          return "background-color: #d6d8d9; !important";
        case 5:
          return "background-color: #f8d7da; !important";
        default:
          return "";
      }
    },
    removeItemFromStockQuantityValidation(id) {
      let _stockQuantityValidation = this.getstockQuantityValidation();

      var newstockQuantityValidation =
        _stockQuantityValidation.filter(f => {
          var found = f.BasketItems.some(s => s.BasketDetailId == id);
          if (found == true) {
            f.AfterStock = f.AfterStock + 1;
            f.BasketItems = f.BasketItems.filter(ff => ff.BasketDetailId != id);
          }

          return f;
        });

      this.$store.commit('setStockQuantityValidation', newstockQuantityValidation)
      this.stockQuantityValidation = newstockQuantityValidation;
    },
    toCurrency,
  },
  computed: {
    ...mapGetters(["getLang", "getStockQuantityValidation"]),
  },
};
</script>



<style scoped>
.disabled {
  opacity: 0.5;
}
</style>