<template>
  <slot></slot>
</template>

<script>
import { i18n } from '@/common/locale'
import { mapGetters } from 'vuex'
export default {
  name: 'PublicLayout',
  created() {
    i18n.global.locale = this.getLang
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>
