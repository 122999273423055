<template>
  <div
    class="flex flex-col sm:flex-row justify-between p-4 bg-white dark:bg-gray-900"
  >
    <div class="block mb-4 lg:mb-0">
      <div class="relative">
        <div
          class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
        >
          <MagnifyingGlassIcon
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
          />
        </div>
        <input
          type="text"
          id="table-search"
          v-model="formData.searchText"
          @keyup.enter="search($event.target.value)"
          class="block p-2 pl-10 w-full lg:w-48 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :placeholder="$t('shared.search')"
        />
      </div>
    </div>
    <div class="block">
      <button
        type="button"
        @click="addCongress"
        class="inline-flex gap-1 justify-center items-center w-full lf:w-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        <PlusIcon class="h-5 w-5 text-white font-bold" />
        <span>{{ $t('admin.congress.add') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/vue/20/solid'
import router from '@/router'
export default {
  name: 'CongressAction',
  components: { MagnifyingGlassIcon, PlusIcon },
  props: {
    search: Function,
    payload: {},
  },
  data() {
    return {
      formData: {
        searchText: '',
      },
    }
  },
  watch: {
    payload: function (data) {
      this.formData = data
    },
  },
  mounted() {
    this.formData = this.payload
  },
  methods: {
    addCongress() {
      router.push({ name: 'congress/edit' })
    },
  },
}
</script>
