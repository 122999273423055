<template>
    <wrapper-with-loader :isLoading="isLoading">

        <participant-info :step="Number(this.step)" :accountPerson="accountPerson" />

        <step-wizard :step="Number(this.step)" :stepList="this.steps" />

        <step-participant v-if="this.getStep(this.step).tag === 'participantType'" :selectAccount="selectAccount"
            :accountSubId="accountSubId" :accountPerson="accountPerson"
            :productTypesAccomHasBoth="this.productTypesAccomHasBoth(this.congressItems)"
            :isAccommodationRequested="this.isAccommodationRequested(this.congressItems)"
            @answered="participantSelected" ref="stepParticipant" />

        <step-registration v-if="this.getStep(this.step).tag === 'inside' || this.getStep(this.step).tag === 'outside'"
            :step="this.getStep(this.step)" :dispatchBasket="dispatchBasket" :congress="this.congress"
            :participantTypes="this.getParticipantTypesByCongressItem()" :selectedAccount="selectedAccount" />

        <step-accommodation v-if="this.getStep(this.step).tag === 'accommodation'" :step="this.getStep(this.step)"
            :dispatchBasket="dispatchBasket" :participantTypes="this.getParticipantTypesByCongressItem()"
            :congress="this.congress" :selectedAccount="selectedAccount" />

        <step-transfer v-if="this.getStep(this.step).tag === 'transfer'" :step="this.getStep(this.step)"
            :dispatchBasket="dispatchBasket" :participantTypes="this.getParticipantTypesByCongressItem()"
            :congress="this.congress" :selectedAccount="selectedAccount" />

        <step-course-other v-if="this.getStep(this.step).tag === 'course'" :step="this.getStep(this.step)"
            :dispatchBasket="dispatchBasket" :participantTypes="this.getParticipantTypesByCongressItem()"
            :congress="this.congress" :selectedAccount="selectedAccount" />


        <step-navigation
            v-if="this.getStep(this.step).tag !== 'finish' && this.getStep(this.step).tag !== 'participantType'"
            :navigateNext="this.nextStep" :navigateBefore="this.beforeStep" />

        <step-final v-if="this.getStep(this.step).tag === 'finish'" :navigateBefore="this.beforeStep"
            :newStep="this.newStep" :setCurrentStepFromTypeId="this.SetCurrentStepFromProductTypeId"
            :accountId="this.accountSubId" :congress="this.congress" />

        <!--
        <div class="justify-center py-4 mb-6">
            <p>current step : {{ this.step }} tag : {{ this.getStep(this.step).tag }}</p>
            <p>Account Person : {{ this.accountPerson }}</p>
            <p>Account Sub Id : {{ this.accountSubId }}</p>

            <p>Accommodation Answer : {{ this.accomodationAnswer }}</p>

            <p v-for="(item, key) in congressItems" :key="key">
                {{ item.productType }}
            </p>
        </div>
        -->


    </wrapper-with-loader>

</template>

<script>
import { useToast, useMeta } from "@/common/hooks";
import { WrapperWithLoader, StepWizard } from "@/components";
import { computed } from "vue";
import { mapGetters } from "vuex";


import StepParticipant from "./StepParticipant.vue";
import ParticipantInfo from "./ParticipantInfo.vue";
import StepRegistration from "./StepRegistration.vue";
import StepNavigation from "./StepNavigation.vue";
import StepAccommodation from "./StepAccommodation.vue";
import StepTransfer from "./StepTransfer.vue";
import StepCourseOther from "./StepCourseOther.vue";
import StepFinal from "./StepFinal.vue";

export default {
    components: {
        WrapperWithLoader,
        ParticipantInfo,
        StepWizard,
        StepNavigation,
        StepParticipant,
        StepRegistration,
        StepAccommodation,
        StepTransfer,
        StepCourseOther,
        StepFinal,
    },
    props: {
        dispatchBasket: Function,
        fetchCongressDetailActiveList: Function,
        isLoading: Boolean,
        congress: Object,
        congressItems: Array,
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            modal: null,
            isBasketPopupLoading: false,
            congressDetailId: null,
            accountSubId: null,
            accountPerson: null,
            congressId: this.$route.params.id,
            accommodationRequested: null,
            accomodationAnswer: null,
            step: 1,
            selectedAccount: null,
            /*
                "inside": "Konaklamalı Kayıt",
                "outside": "Konaklamasız Kayıt",
            */
            stepList: [
                {
                    key: 1,
                    value: 'wizard.order.participantType',
                    tag: "participantType",
                    hide: false,
                },
                {
                    key: 5,
                    value: 'shared.finish',
                    tag: "finish",
                    hide: false,
                },
            ],
        };
    },
    watch: {
        isLoading: function () {
            this.geatherSteps(this.congressItems);
            return this.isLoading;
        },
    },
    created() {
        useMeta(
            computed(() => ({
                title: `${this.$t("metaTitle.congress")} - ${this.getLang === "TR" ? this.congress?.name : this.congress?.nameEn}`,
            }))
        );
    },
    mounted() {
        this.initSteps();

        this.fetchCongressDetailActiveList();
    },
    methods: {
        selectAccount(id, person) {
            console.debug("selectAccount", id, person);
            if (id === "null") {
                this.accountSubId = null;
            } else {
                this.accountSubId = id;
            }

            this.accountPerson = person;
        },
        initSteps() {
            this.steps = this.stepList;
        },
        productTypesAccomHasBoth(data) {
            return data.some(item => item.productType === "Inside") && data.some(item => item.productType === "Outside");
        },
        isAccommodationRequested(data) {
            return data.some(item => item.productType === "Inside");
        },
        geatherSteps(data) {
            let congressItems = data;
            let steps = [];

            steps.push({
                key: 1,
                value: 'wizard.order.participantType',
                tag: "participantType",
                hide: false,
            });

            congressItems.forEach(item => {
                let val = `admin.congressDetail.${item.productType.toLowerCase()}`;

                if (this.productTypesAccomHasBoth(data) && (item.productType === "Inside" || item.productType === "Outside")) {
                    if (steps.filter(step => step.key === 2).length === 0) {
                        steps.push({
                            key: 2,
                            value: `wizard.order.registration`,
                            hide: false,
                        });
                    }
                } else {
                    steps.push({
                        key: steps.length + 1,
                        value: val,
                        tag: item.productType.toLowerCase(),
                        hide: true,
                    });
                }
            });

            steps.push({
                key: steps.length + 1,
                value: 'shared.finish',
                tag: "finish",
                hide: false,
            });

            this.steps = steps;
        },
        getStep(stepKey) {
            return this.steps.find(item => item.key === stepKey);
        },
        updateRegistrationStep(answer) {
            let val = answer ? `admin.congressDetail.inside` : `admin.congressDetail.outside`;

            let steps = this.steps.map(step => {
                if (step.key === 2) {
                    step.value = val;
                    step.tag = answer ? "inside" : "outside";
                }
                return step;
            });

            if (!answer) {
                steps = steps.filter(step => step.tag !== "accommodation");
            }

            steps.forEach((step, index) => {
                step.key = index + 1;
            });

            this.steps = steps;

        },
        participantSelected(answer) {
            if (this.accountPerson == "" || this.accountPerson == null) {
                this.toast.error(this.$t('wizard.order.participant.error'));
                return;
            }

            this.updateRegistrationStep(answer);

            this.nextStep();
            this.accomodationAnswer = answer;

            this.selectedAccount = this.$refs.stepParticipant.getSelectedAccount();
        },
        getSelectedAccount() {
            return this.selectedAccount;
        },
        nextStep() {
            this.step++;
            console.debug("step++" + this.step);
        },
        beforeStep() {
            this.step--;
            console.debug("step--" + this.step);
            if (this.step === 1) {
                this.selectAccount("null", null);
            }
        },
        newStep() {
            this.step = 1;
        },
        setCurrentStep(tag) {
            this.step = this.steps.find(step => step.tag === tag).key;
        },
        SetCurrentStepFromProductTypeId(productType) {
            let productTypeTag = "";
            switch (productType) {
                case 0:
                    productTypeTag = "participantType";
                    break;
                case 1:
                    productTypeTag = "inside";
                    break;
                case 2:
                    productTypeTag = "outside";
                    break;
                case 3:
                    productTypeTag = "accommodation";
                    break;
                case 4:
                    productTypeTag = "transfer";
                    break;
                case 5:
                    productTypeTag = "course";
                    break;
            }
            this.setCurrentStep(productTypeTag);
        },
        getParticipantTypesByCongressItem() {
            let tag = this.getStep(this.step).tag;
            if (this.congressItems.length === 0) return [];
            if (this.congressItems.some(item => item.productType.toLowerCase() === tag)) {
                return this.congressItems.find(item => item.productType.toLowerCase() === tag).participantTypes;
            }

            return [];
        },
    },
    computed: {
        ...mapGetters(["getLang"]),
    },
};
</script>

<style scoped lang="scss">
.congress {
    @apply h-full;
}
</style>
