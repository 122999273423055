<template>
  <component :is="iconComponent" role="img" />
</template>
  
<script>
import { LogoIcon,LogoWithText, TrFlagIcon, UsFlagIcon,LogoutIcon,SpinnerIcon,SaveIcon } from "@/assets/icons";
const icons = {
  logo: LogoIcon,
  "logo-with-text":LogoWithText,
  "tr-flag-icon": TrFlagIcon,
  "us-flag-icon": UsFlagIcon,
  "logout-icon":LogoutIcon,
  "spinner-icon": SpinnerIcon,
  "save-icon":SaveIcon
}

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    }
  },

  computed: {
    iconComponent() {
      return icons[this.name]
    }
  }
}
</script>