<template>
  <form @submit.prevent="login" class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <span class="text-sm">{{ $t('user.login.email') }}</span>
      <input
        type="email"
        required
        v-model="formData.email"
        class="border-gray-300 border-2 bg-gray-100 rounded-md px-4 py-2 font-light text-black"
        :placeholder="$t('user.login.email')"
      />
    </div>
    <div class="flex flex-col gap-2">
      <span class="text-sm">{{ $t('user.login.password') }}</span>
      <input
        type="password"
        required
        v-model="formData.password"
        class="border-gray-300 border-2 bg-gray-100 rounded-md px-4 py-2 font-light text-black"
        :placeholder="$t('user.login.password')"
      />
    </div>
    <div class="flex flex-row justify-between">
      <!-- <div class="flex gap-x-2 items-center">
                <input type="checkbox">
                <span class="text-gray-500 text-sm">Remember me</span>
              </div> -->
      <div class="flex gap-x-2 items-center">
        <a @click="openModal" class="cursor-pointer text-blue-700 text-sm">
          {{ $t('user.login.forgetPassword') }}
        </a>
      </div>
    </div>
    <div class="flex my-5">
      <button-with-loader
        :isLoading="isLoading"
        type="submit"
        class="p-4 bg-blue-600 text-white w-full rounded-xl"
      >
        {{ $t('user.login.login') }}
      </button-with-loader>
    </div>

    <div class="mt-4 text-sm">
      <span>
        {{ $t('user.login.notMember') }}
        <router-link :to="{ name: 'register' }" class="text-blue-600">
          {{ $t('user.login.register') }}
        </router-link>
      </span>
    </div>
  </form>
  <div
    id="forgot-password-form-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
  >
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600"
        >
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ $t('user.login.forgetPassword') }}
          </h3>
          <button
            type="button"
            @click="closeModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <XMarkIcon class="w-5 h-5" />
          </button>
        </div>
        <!-- Modal body -->
        <forgot-password-form
          :closeModal="closeModal"
          :propEmail="formData.email"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useResponseToast, useToast } from '@/common/hooks'
import {authService} from '@/common/services'
import {ButtonWithLoader} from '@/components'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import ForgotPasswordForm from './ForgotPasswordForm.vue'
export default {
  name: 'LoginForm',
  components: { ButtonWithLoader, XMarkIcon, ForgotPasswordForm },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      modal: null,
      formData: {},
      isLoading: false,
    }
  },
  mounted() {
    this.initModal()
  },
  methods: {
    login() {
      if (this.validateEmail(this.formData.email)) {
        this.isLoading = true
        const payload ={ ...this.formData }
        authService.login(payload)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {},
              errorCallback: () => {},
              isSaveMessage: false,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    closeModal() {
      this.modal.hide()
    },
    openModal() {
      this.modal.show()
    },
    initModal() {
      const modalEl = document.getElementById('forgot-password-form-modal')
      const modalOptions = {
        placement: 'center-center',
        backdropClasses:
          'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-5',
      }
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions)
      }
    },
    validateEmail(email) {
      if (email !== null) {
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true
        this.toast.error(this.$t('shared.validateEmail'))
        return false
      }
    },
  },
}
</script>
