<template>
    <content-wrapper v-if="step != 1">
        <label class="block mb-2 text-sm font-medium text-gray-900">
            {{ this.$t('wizard.order.participant.info.label') }} {{ this.accountPerson }}  <!-- {{ step }} -->
        </label>
    </content-wrapper>
</template>


<script>

import {
    ContentWrapper,
} from '@/components'

export default {
    name: "ParticipantInfo",
    components: {
        ContentWrapper,
    },
    props: {
        accountPerson: String,
        step: Number,
    }
}

</script>