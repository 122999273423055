<template>
    <div v-if="this.called" class="timer">
        <div class="flex flex-row items-center border border-blue-200 rounded-lg">
            <div class="flex flex-row items-center gap-2 p-2">
                <div class="flex flex-row items-center gap-2">
                    <div class="flex flex-col items-center">
                        <clock-icon class="w-6 h-6 text-blue-400" />
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="text-lg text-blue-800">{{ minutes }}</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="text-lg text-blue-800">:</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="text-lg text-blue-800">{{ seconds }}</span>
                    </div>

                    <Popper :hover="true" placement="top" v-if="featureCancelPayment">
                        <div class="flex flex-col items-center">
                            <!-- button for canceling payment -->
                            <button @click="cancelPayment">
                                <x-circle-icon class="w-5 h-5 text-red-500" />
                            </button>
                        </div>
                        <template #content>
                            <div class="text-center">
                                {{ $t('user.basket.cancelPayment') }}
                            </div>
                        </template>
                    </Popper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { XCircleIcon, ClockIcon } from '@heroicons/vue/24/outline'
// import popover
import Popper from "vue3-popper";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
    name: "BasketTimer",
    components: {
        Popper,
        XCircleIcon,
        ClockIcon,
    },
    props: {
        deadline: {
            type: String,
            required: true,
        },
        speed: {
            type: Number,
            default: 1000,
        },
    },
    data() {
        return {
            currentTime: Date.parse(this.deadline) - Date.parse(new Date()),
            called: false,
            featureCancelPayment: false,
        };
    },
    mounted() {
        setTimeout(this.countdown, 100);
    },
    computed: {
        seconds() {
            return this.formatTime(Math.floor((this.currentTime / 1000) % 60));
        },
        minutes() {
            return this.formatTime(Math.floor((this.currentTime / 1000 / 60) % 60));
        },
        hours() {
            return this.formatTime(Math.floor((this.currentTime / (1000 * 60 * 60)) % 24));
        },
        days() {
            return this.formatTime(Math.floor(this.currentTime / (1000 * 60 * 60 * 24)));
        }
    },
    methods: {
        countdown() {
            if (!this.called)
                this.called = true;

            // utc to current
            var _deadline = this.toUtcDeadline();

            this.currentTime = _deadline - Date.parse(new Date());
            if (this.currentTime > 0) {
                setTimeout(this.countdown, this.speed);
            } else {
                this.currentTime = null;
            }

            if (this.currentTime == null) {
                this.timeout();
            }
        },
        toUtcDeadline() {
            let _deadlineUTC = this.deadline.split(' ').join('T') + 'Z';
            let _deadline = new Date(_deadlineUTC);
            return _deadline;
        },
        formatTime(value) {
            if (value < 10) {
                return "0" + value;
            }
            return value;
        },
        timeout() {
            toast.error(this.$t('user.basket.paymentTimeout'));
            this.fetchBasket().finally(() => {
                this.$router.push({ name: "basket" });
            });
        },
        cancelPayment() {
            this.sendResetBasketPayment().finally(() => {
                this.fetchBasket().finally(() => {
                    this.$router.push({ name: "basket" });
                });
            });
        },
        ...mapActions(["sendResetBasketPayment", "fetchBasket"]),
    }
}
</script>

<style scoped>
.timer {
    position: absolute;
    right: 0;
    top: 6vh;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8);
}
</style>