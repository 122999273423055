<template>
  <div class="bg-gray-100 p-5 flex justify-between items-center text-xs">
    <div class="w-5">{{ $t('admin.congress.no') }}</div>
    <div class="flex-1">{{ $t('admin.congress.name') }}</div>
    <div class="w-5"></div>
    <div class="w-5"></div>
  </div>
  <div v-for="(item, index) in congress.items" :key="item">
    <div
      :id="`accordion-${item.id}-header`"
      class="accordion-header flex border-b-2 p-5 bg-white dark:bg-gray-800"
    >
      <div :aria-controls="`accordion-${item.id}-header`" class="w-full">
        <div class="flex justify-between items-center text-xs">
          <div class="w-5">
            {{
              congress.pageNumber === 1
                ? congressIndex + index
                : pageSize + congressIndex + index
            }}
          </div>
          <div class="flex-1 truncate">
            <span v-if="getLang === 'TR'">
              {{ item.name }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.nameEn }}
            </span>
          </div>
          <div class="w-5 mx-4">
            <span
              v-if="item.isActive"
              class="block rounded bg-green-100 p-1 w-6 h-6"
            >
              <CheckCircleIcon class="h-4 w-4 text-green-800" />
            </span>
            <span v-else class="block rounded bg-red-100 p-1 w-6 h-6">
              <MinusCircleIcon class="h-4 w-4 text-red-800" />
            </span>
          </div>
          <div class="w-5">
            <div class="up-icon">
              <ChevronUpIcon class="h-6 w-6 text-blue-500" />
            </div>
            <div class="down-icon">
              <ChevronDownIcon class="h-6 w-6 text-blue-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="`accordion-${item.id}-body`"
      class="hidden"
      :aria-labelledby="`accordion-${item.id}-body`"
    >
      <div
        class="p-5 text-sm bg-gray-200 border-gray-200 dark:border-gray-700 dark:bg-gray-900"
      >
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('admin.congress.startDate') }}:
          </span>
          <span class="text-gray-900">
            {{ moment(item.startDate).format('DD.MM.YYYY') }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('admin.congress.endDate') }}:
          </span>
          <span class="text-gray-900">
            {{ moment(item.endDate).format('DD.MM.YYYY') }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('admin.congress.email') }}:
          </span>
          <span class="text-gray-900">{{ item.contactEmail }}</span>
        </div>
        <div class="flex mb-2">
          <router-link
            :to="{ name: 'congress/contents/id', params: { id: item.id } }"
            class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t('shared.contents') }}
          </router-link>
          <span class="px-2">|</span>
          <router-link
            :to="{ name: 'congress/edit/id', params: { id: item.id } }"
            class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t('shared.edit') }}
          </router-link>
          <span class="px-2">|</span>
          <a
            @click="deleteCongress(item.id)"
            class="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t('shared.delete') }}
          </a>
          <span class="px-2">|</span>
          <router-link
            :to="{ name: 'congress/detail', params: { id: item.id } }"
            class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t('shared.details') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { service } from '@/common/services'
import {
  CheckCircleIcon,
  MinusCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/vue/20/solid'
import moment from 'moment'
import { useToast, useResponseToast } from '@/common/hooks'
import { mapGetters } from 'vuex'

export default {
  name: 'CongressAccordionList',
  components: {
    CheckCircleIcon,
    MinusCircleIcon,
    ChevronUpIcon,
    ChevronDownIcon,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      moment: moment,
      congressIndex: 1,
      accordion: null,
    }
  },
  props: {
    congress: Object,
    pageSize: Number,
    payload: Object,
    dispatchCongressList: Function,
  },
  mounted() {
    this.initAccordion(this.congress.items)
  },
  methods: {
    deleteCongress(id) {
      service.delete('congress', id).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.dispatchCongressList(this.payload)
          },
          errorCallback: () => {},
          isSaveMessage: true,
        })
      })
    },
    initAccordion(items) {
      let accordionItems = []
      if (
        items !== undefined &&
        document.querySelector('.accordion-header') !== null
      ) {
        items.map((item, index) => {
          if (index === 0) {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: true,
            })
          } else {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: false,
            })
          }
        })
        const options = {
          alwaysOpen: false,
          activeClasses: 'text-gray-900 dark:text-white',
          inactiveClasses: 'text-gray-900 dark:text-white',
        }
        // eslint-disable-next-line
        this.accordion = new Accordion(accordionItems, options)
      }
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.accordion-header[aria-expanded='true'] {
  .up-icon {
    @apply block;
  }

  .down-icon {
    @apply hidden;
  }
}

.accordion-header[aria-expanded='false'] {
  .up-icon {
    @apply hidden;
  }

  .down-icon {
    @apply block;
  }
}
</style>
