<template>
  <form @submit.prevent="onRegister" class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <span class="text-sm">{{ $t('user.register.firstname') }}</span>
      <input
        type="text"
        required
        v-model="formData.firstName"
        class="border-gray-300 border-2 bg-gray-100 rounded-md px-4 py-2 font-light text-black"
      />
    </div>
    <div class="flex flex-col gap-2">
      <span class="text-sm">{{ $t('user.register.lastname') }}</span>
      <input
        type="text"
        required
        v-model="formData.lastName"
        class="border-gray-300 border-2 bg-gray-100 rounded-md px-4 py-2 font-light text-black"
      />
    </div>
    <div class="flex flex-col gap-2">
      <span class="text-sm">{{ $t('user.login.email') }}</span>
      <input
        type="email"
        required
        v-model="formData.email"
        class="border-gray-300 border-2 bg-gray-100 rounded-md px-4 py-2 font-light text-black"
      />
    </div>
    <div class="flex flex-col gap-2">
      <span class="text-sm">{{ $t('user.login.password') }}</span>
      <input
        type="password"
        required
        v-model="formData.password"
        class="border-gray-300 border-2 bg-gray-100 rounded-md px-4 py-2 font-light text-black"
      />
    </div>
    <div class="flex flex-row justify-between"></div>
    <div class="flex my-5">
      <button-with-loader
        :isLoading="isLoading"
        type="submit"
        class="p-4 bg-blue-600 text-white w-full rounded-xl"
      >
        {{ $t('user.register.register') }}
      </button-with-loader>
    </div>

    <div class="mt-4 text-sm">
      <span>
        <router-link :to="{ name: 'login' }" class="text-blue-600">
          {{ $t('user.login.loginLabel') }}
        </router-link>
      </span>
    </div>
  </form>
</template>

<script>
import { useResponseToast, useToast } from '@/common/hooks'
import router from '@/router'
import { authService } from '@/common/services'
import { ButtonWithLoader } from '@/components'
export default {
  name: 'RegisterForm',
  components: { ButtonWithLoader },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      formData: {},
      isLoading: false,
    }
  },
  methods: {
    onRegister() {
      this.isLoading = true
      const payload = { ...this.formData }
      authService
        .register(payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              router.push({ name: 'login' })
            },
            errorCallback: () => {},
            isSaveMessage: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
