<template>
  <div class="order sm:hidden block">
    <no-data externalClass="relative pt-12">
      <div class="text-2xl font-bold mb-2 text-gray-900">
        {{ $t("shared.noData") }}
      </div>
      <div class="text-sm font-normal text-gray-500 text-center">
        {{ $t("disabled.reportsView") }}
      </div>
    </no-data>
  </div>

  <div class="order hidden sm:block">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />

      <div class="flex flex-col sm:flex-row justify-between p-4 bg-white dark:bg-gray-900">
        <div class="w-full flex flex-col lg:flex-row flex-wrap 2xl:justify-between items-center gap-4">
          <div class="w-full lg:w-auto">
            <span class="text-gray-500 dark:text-gray-400">{{ $t("user.order.congressName") }}</span>
            <select v-model="this.selectedCongress" @change="filterSelectedCongresses($event)"
              class="w-full lg:w-[200px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="0">{{ $t("user.congress.all") }}</option>
              <option v-for="option in congresses" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="w-full lg:w-auto">
            <span class="text-gray-500 dark:text-gray-400">{{ $t("user.order.orderStatus") }}</span>
            <select id="orderStatus" v-model="this.orderStatus" @change="filterOrderStatus($event)"
              class="w-full lg:w-[200px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="0">
                {{ $t("shared.all") }}
              </option>

              <option value="2">
                {{ $t("user.order.waitingfortransfer") }}
              </option>
              <option value="3">
                {{ $t("user.order.paymentcompleted") }}
              </option>
            </select>
          </div>
          <div class="w-full lg:w-auto">
            <span class="text-gray-500 dark:text-gray-400">{{ $t("user.order.paymentType") }}</span>
            <select id="paymentType" v-model="this.paymentType" @change="filterPaymentType($event)"
              class="w-full lg:w-[200px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="0">
                {{ $t("shared.all") }}
              </option>
              <option value="1">
                {{ $t("user.order.creditcard") }}
              </option>
              <option value="2">
                {{ $t("user.order.transfer") }}
              </option>
            </select>
          </div>
          <div class="w-full lg:w-auto">
            <download-excel
              class="inline-flex gap-1 cursor-pointer justify-center items-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              worksheet="My Worksheet" type="csv" :fields="excelColumn" :name="`${$t('sidebar.order')} - ${moment(new Date()).format(
                'DD.MM.YYYY'
              )}.csv`" :fetch="downloadExcel">
              <CloudArrowDownIcon class="w-5 h-5 text-white" />
              <span>{{ $t("user.order.exportExcel") }}</span>
            </download-excel>
          </div>
          <div v-if="isClearFilter" class="whitespace-nowrap w-full lg:w-auto">
            <a @click="filterReset" class="cursor-pointer underline text-sm text-blue-500">
              {{ $t("user.order.filterReset") }}
            </a>
          </div>
        </div>
      </div>

      <template v-if="order.items.length">
        <content-wrapper>
          <table-wrapper-with-loader :isLoading="isTableLoading">
            <order-detailed-table-list :order="order" :payload="payload" :pageSize="payload.pageSize" />
          </table-wrapper-with-loader>
          <OrderPagination v-if="order.items" :order="order" :changePage="changePage" />
        </content-wrapper>
      </template>
      <template v-else>
        <no-data externalClass="relative pt-12">
          <div class="text-2xl font-bold mb-2 text-gray-900"></div>
        </no-data>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from "vue";
import {
  useToast,
  useMediaQuery,
  useResponseToast,
  useMeta,
} from "@/common/hooks";
import { service } from "@/common/services";
import moment from "moment";
import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  ContentWrapper,
  NoData,
  TableWrapperWithLoader,
} from "@/components";

import OrderDetailedTableList from "./OrderDetailedTableList.vue";
import OrderPagination from "../Order/OrderPagination.vue";
import DownloadExcel from "vue-json-excel3";

export default {
  name: "DetailedOrder",
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    ContentWrapper,
    NoData,
    OrderDetailedTableList,
    OrderPagination,
    TableWrapperWithLoader,
    DownloadExcel,
  },
  data() {
    return {
      moment: moment,
      breadcrumbList: [{ name: this.$t("sidebar.detailedOrder"), key: "sidebar.detailedOrder" }],
      pageTitle: this.$t("sidebar.detailedOrder"),
      order: Object,
      congresses: [],
      selectedCongress: String,
      isLoading: true,
      isTableLoading: false,
      payload: {
        pageNumber: 1,
        pageSize: 10,
      },
      excelColumn: {
        [this.$t("user.order.orderNo")]: "orderNo",
        [this.$t("user.order.operationDate")]: "operationDate",
        [this.$t("user.order.congressName")]: "congressName",
        [this.$t("admin.congressDetail.productType")]: "productType",
        [this.$t("user.order.packageName")]: "packageName",
        [this.$t("user.order.packageType")]: "packageType",
        [this.$t("user.order.orderNote")]: "orderNote",
        [this.$t("user.excelColumn.paymentType")]: "paymentType",
        [this.$t("user.excelColumn.orderStatus")]: "orderStatus",
        [this.$t("user.order.startDate")]: "startDate",
        [this.$t("user.order.endDate")]: "endDate",
        [this.$t("user.order.daysNights")]: "daysAndNigths",
        [this.$t("user.order.buyerName")]: "buyerName",
        [this.$t("user.order.participantName")]: "participantName",
        [this.$t("user.order.price")]: "price",
        [this.$t("user.order.vat")]: "vat",
        [this.$t("user.order.vatRatio")]: "vatRatio",
        [this.$t("user.order.totalPrice")]: "totalPrice",
        ["Currency"]: "productCurrency",
        [this.$t("admin.congressDetail.transferDetails.Heading")]: {
          field: 'transferDetails',
          callback: (value) => {
            // range of tranfer details
            var data = "";
            var flightDetails = value?.flightDetails;
            if (flightDetails == undefined || flightDetails == null || flightDetails.length == 0)
              return;

            var count = flightDetails.length;
            var isOneWay = count == 1;

            flightDetails?.forEach((element, i) => {
              if (element.city == undefined || element.flightNumber == undefined || element.dateTime == undefined)
                return;

              var cityLabel = isOneWay ? this.$t("admin.congressDetail.transferDetails.City") : (i == 0 ? this.$t("admin.congressDetail.transferDetails.DepartureCity") : this.$t("admin.congressDetail.transferDetails.ArrivalCity"));
              var flightNumberLabel = isOneWay ? this.$t("admin.congressDetail.transferDetails.FlightNumber") : (i == 0 ? this.$t("admin.congressDetail.transferDetails.DepartureFlightNumber") : this.$t("admin.congressDetail.transferDetails.ArrivalFlightNumber"));
              var dateLabel = isOneWay ? this.$t("admin.congressDetail.transferDetails.DateTime") : (i == 0 ? this.$t("admin.congressDetail.transferDetails.DepartureDateTime") : this.$t("admin.congressDetail.transferDetails.ArrivalDateTime"));
              var noteLabel = this.$t("admin.congressDetail.transferDetails.Notes");

              var titleLabel = isOneWay ? this.$t("admin.congressDetail.transferDetails.Heading") : (i == 0 ? this.$t("admin.congressDetail.transferDetails.DepartureDetails") : this.$t("admin.congressDetail.transferDetails.ArrivalDetails"));

              if (i != 0)
                titleLabel = `
            \n${titleLabel}`;

              data += `${titleLabel}
            \n${cityLabel} : ${element.city} 
            \n${flightNumberLabel} : ${element.flightNumber} 
            \n${dateLabel} : ${moment(element.dateTime).format("DD.MM.YYYY hh:mm")} 
            \n${noteLabel} : ${element.notes}
            \n`;
            });

            return data;
          }
        },
      },
    };
  },
  setup() {
    const { downXl } = useMediaQuery();
    const toast = useToast();
    return { toast, downXl };
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t("metaTitle.orders"),
      }))
    );
  },
  mounted() {
    this.dispatchOrderList(this.payload);
  },
  watch: {
    order: function (data) {
      return (this.order = data);
    },
  },
  methods: {
    dispatchOrderList(payload) {
      this.isTableLoading = true;
      service
        .dispatch("reports/order/detailed", payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              console.log(response);
              if (response.items.length) {
                this.order = response;
              } else {
                this.order = [];
              }
              if (response.congresses.length) {
                this.congresses = response.congresses;
              }
            },
            errorCallback: () => {
              response.messages.map((message) => {
                if (message.code === "Data is empty") {
                  this.payload = { ...payload };
                  this.order.items = [];
                }
              });
            },
            isSaveMessage: false,
            noToastMessageCodes: ["Data is empty"],
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.isTableLoading = false;
        });
    },
    changePage(pageNumber) {
      this.payload = { ...this.payload, pageNumber };
      this.dispatchOrderList(this.payload);
    },
    filterReset() {
      this.payload = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.dispatchOrderList(this.payload);
    },
    filterSelectedCongresses($event) {
      var selectedCongres = $event.target.value;

      if (selectedCongres == "0") {
        selectedCongres = null;
      }

      this.payload = {
        ...this.payload,
        pageNumber: 1,
        selectedCongress: selectedCongres,
      };
      this.dispatchOrderList(this.payload);
    },
    getSelectedCongres() {
      if (this.payload.selectedCongress) {
        return this.payload.selectedCongress;
      } else {
        return null;
      }
    },
    async downloadExcel() {
      const response = await service.dispatch("reports/order/detailed", {
        pageNumber: 1,
        pageSize: 0,
        selectedCongress: this.getSelectedCongres(),
      });
      return response.items.map((item) => {
        let orderStatus = "";
        let paymentType = "";
        switch (item.orderStatus) {
          case 1:
            orderStatus = this.$t("user.excelRowConvert.waitingforpayment");
            break;
          case 2:
            orderStatus = this.$t("user.excelRowConvert.waitingfortransfer");
            break;
          case 3:
            orderStatus = this.$t("user.excelRowConvert.paymentcompleted");
            break;
          case 4:
            orderStatus = this.$t("user.excelRowConvert.canceled");
            break;
        }
        switch (item.paymentType) {
          case 1:
            paymentType = this.$t("user.excelRowConvert.creditcard");
            break;
          case 2:
            paymentType = this.$t("user.excelRowConvert.transfer");
            break;
        }
        var _currency;
        switch (item.productCurrency) {
          case 1:
            _currency = "EUR";
            break;
          case 2:
            _currency = "USD";
            break;
          default:
            _currency = "TRY";
            break;
        }
        item.productCurrency = _currency;
        item.orderStatus = orderStatus;
        item.paymentType = paymentType;
        return {
          ...item,
          // selected congress
        };
      });
    },
    filterPaymentType($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        paymentType: Number($event.target.value),
      };
      this.dispatchOrderList(this.payload);
    },
    filterOrderStatus($event) {
      this.payload = {
        ...this.payload,
        pageNumber: 1,
        orderStatus: Number($event.target.value),
      };
      this.dispatchOrderList(this.payload);
    },
  },
};
</script>

<style scoped lang="scss">
.order {
  @apply h-full;
}
</style>
