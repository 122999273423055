<template>
  <form>
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-1/2 lg:pr-2">
        <div class="flex mb-6">
          <div class="w-36 pr-4">
            <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.socialTitle') }}
            </label>
            <input type="text" id="title" v-model="formData.title"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
          <div class="flex-auto">
            <label for="firstName" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.firstName') }}
            </label>
            <template v-if="!formData.serenasId">
              <input type="text" id="firstName" v-model="formData.firstName"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
            </template>
            <template v-else>
              <span type="text"
                class="h-[42px] shadow-sm bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                {{ formData.firstName }}
              </span>
            </template>
          </div>
        </div>
        <div class="flex-auto mb-6">
          <label for="companyName" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.companyName') }} *
          </label>
          <input type="text" id="companyName" v-model="formData.companyName" required
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="address" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.address') }} *
          </label>
          <input type="text" id="address" v-model="formData.address" required
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="IdentityNumber" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.identityNumber') }} *
          </label>
          <input type="text" id="IdentityNumber" v-model="formData.identityNumber" required
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="countryId" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.country') }}
          </label>
          <select id="countryId" v-model="formData.countryId" @change="fetchStateById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseCountry') }}
            </option>
            <option v-for="item in countryList" :value="item.id" :key="item">
              {{ getLang === 'TR' ? item.translations.tr : item.name }}
            </option>
          </select>
        </div>
        <div class="flex-auto mb-6">
          <template v-if="countryCode === 'TR'">
            <label for="stateId" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.city') }}
            </label>
          </template>
          <template v-else>
            <label for="stateId" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.state') }}
            </label>
          </template>
          <select id="stateId" v-model="formData.stateId" @change="fetchCityById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="null" selected disabled>
              {{
                countryCode !== 'TR'
                ? $t('user.profile.chooseState')
                : $t('user.profile.chooseCity')
              }}
            </option>
            <option v-for="item in stateList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="w-full lg:w-1/2 lg:pl-2">
        <div class="flex-auto mb-6">
          <label for="lastName" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.lastName') }}
          </label>
          <template v-if="!formData.serenasId">
            <input type="text" id="lastName" v-model="formData.lastName"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </template>
          <template v-else>
            <span type="text"
              class="h-[42px] shadow-sm bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              {{ formData.lastName }}
            </span>
          </template>
        </div>
        <div class="flex-auto mb-6">
          <label for="email" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.email') }}
          </label>
          <span type="text"
            class="h-[42px] shadow-sm bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            {{ formData.email }}
          </span>
        </div>
        <div class="flex-auto mb-6">
          <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.phoneNumber') }} *
          </label>
          <template v-if="!formData.serenasId">
            <input type="text" id="phoneNumber" v-maska="'0(5##) ### ## ##'" v-model="formData.phoneNumber"
              placeholder="0(599) 999 99 99" required
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </template>
          <template v-else>
            <span type="text"
              class="h-[42px] shadow-sm bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              {{ formData.phoneNumber }}
            </span>
          </template>
        </div>

        <div class="flex-auto mb-6">
          <label for="birthDate" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.birthDate') }} *
          </label>
          <input type="date" id="birthDate" v-model="birthDate" required
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>

        <div class="flex-auto mb-6">
          <label for="zipCode" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.postalCode') }}
          </label>
          <input type="text" id="zipCode" v-model="formData.zipCode" v-maska="'#####'" placeholder="34000"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>

        <div v-if="countryCode !== 'TR'" class="flex-auto mb-6">
          <label for="cityId" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.city') }}
          </label>
          <select id="cityId" v-model="formData.cityId"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseCity') }}
            </option>
            <option v-for="item in cityList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>

        <!-- <div class="flex-auto mb-6">

                        <label class="block mb-2 text-sm font-medium text-gray-900  " for="profilePhoto">{{
                        $t('user.profile.profilePhoto')
                        }}</label>
                        <input
                            class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer"
                            id="profilePhoto" type="file">

                    </div> -->
      </div>
    </div>
    <div class="text-xl font-bold mb-6">
      {{ $t('user.profile.invoiceDetails') }}
    </div>
    <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg" role="alert">
      <InformationCircleIcon class="w-6 h-6 mr-3" />

      <span class="sr-only">{{ $t('user.profile.importantNote') }}</span>
      <div>
        <span class="font-medium">
          {{ $t('user.profile.importantNote') }}
        </span>
        {{ $t('user.profile.importantNoteDesc') }}
      </div>
    </div>
    <div class="flex flex-col lg:flex-row">
      <label class="inline-flex relative items-center mb-4 cursor-pointer">
        <input type="checkbox" value="" @change="toggleCorporateInvoice" class="sr-only peer" />
        <div
          class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
        </div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {{ $t('user.profile.corporateInvoice') }}
        </span>
      </label>
    </div>
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-1/2 lg:pr-2">
        <div class="flex-auto mb-6">
          <label for="invoice" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.invoice') }}
          </label>
          <input type="text" id="invoiceName" v-model="formData.invoiceName"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="address" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.address') }}
          </label>
          <input type="text" id="invoiceAddress" v-model="formData.invoiceAddress"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="invoiceCountryId" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.profile.country') }}
          </label>
          <select id="invoiceCountryId" v-model="formData.invoiceCountryId" @change="fetchInvoiceStateById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseCountry') }}
            </option>
            <option v-for="item in invoiceCountryList" :value="item.id" :key="item">
              {{ getLang === 'TR' ? item.translations.tr : item.name }}
            </option>
          </select>
        </div>

        <div class="flex-auto mb-6">
          <template v-if="invoiceCountryCode === 'TR'">
            <label for="invoiceStateId" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.city') }}
            </label>
          </template>
          <template v-else>
            <label for="invoiceStateId" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.state') }}
            </label>
          </template>
          <select id="invoiceStateId" v-model="formData.invoiceStateId" @change="fetchInvoiceCityById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="null" selected disabled>
              {{
                getLang === 'TR'
                ? $t('user.profile.chooseCity')
                : $t('user.profile.chooseState')
              }}
            </option>
            <option v-for="item in invoiceStateList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>

        <template v-if="invoiceCountryCode !== 'TR'">
          <div class="flex-auto mb-6">
            <label for="invoiceCityId" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.city') }}
            </label>
            <select id="invoiceCityId" v-model="formData.invoiceCityId"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              <option value="null" selected disabled>
                {{ $t('user.profile.chooseCity') }}
              </option>
              <option v-for="item in invoiceCityList" :value="item.id" :key="item">
                {{ item.name }}
              </option>
            </select>
          </div>
        </template>
      </div>


      <template v-if="isCorporateInvoice">
        <div class="w-full lg:w-1/2 lg:pl-2">
          <div class="flex-auto mb-6">
            <label for="invoiceTaxNumber" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.taxNumber') }}
            </label>
            <input type="text" id="invoiceTaxNumber" v-model="formData.invoiceTaxNumber" v-maska="'##########'"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
          <div class="flex-auto mb-6">
            <label for="invoiceTaxOffice" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t('user.profile.taxDepartment') }}
            </label>
            <input type="text" id="invoiceTaxOffice" v-model="formData.invoiceTaxOffice"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
        </div>
      </template>
    </div>
    <div class="flex items-center mb-4">
      <input type="checkbox" :checked="isAgreement" @change="isAgreement = !isAgreement"
        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
      <label @click="openModal" class="ml-2 text-sm font-medium text-gray-900">
        <span class="cursor-pointer underline">
          {{ $t('user.profile.distanceSalesAgreementClick') }}
        </span>
        <span>{{ $t('user.profile.distanceSalesAgreement') }}</span>
      </label>
    </div>

    <div class="flex justify-end">
      <button-with-loader :isLoading="isLoading" type="button" @click="updateAccount"
        class="w-32 inline-flex gap-1 items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
        <CustomIcon name="save-icon" />
        {{ $t('shared.save') }}
      </button-with-loader>
    </div>
  </form>
  <distance-sales-modal :closeModal="closeModal" />
</template>

<script>
/* eslint-disable */
import { useResponseToast, useToast } from '@/common/hooks'
import moment from 'moment'
import { service } from '@/common/services'
import { DistanceSalesModal, ButtonWithLoader, CustomIcon } from '@/components'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileForm',
  components: {
    DistanceSalesModal,
    ButtonWithLoader,
    InformationCircleIcon,
    CustomIcon,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      modal: null,
      formData: {
        invoiceCountryId: null,
        invoiceCityId: null,
        invoiceStateId: null,
        cityId: null,
        countryId: null,
        stateId: null,
      },
      countryCode: '',
      invoiceCountryCode: '',
      countryList: [],
      stateList: [],
      cityList: [],
      invoiceCountryList: [],
      invoiceStateList: [],
      invoiceCityList: [],
      isAgreement: false,
      isLoading: false,
      isCorporateInvoice: false,
    }
  },
  props: {
    propFormData: {},
  },
  mounted() {
    this.initModal()
    this.formData = this.propFormData
    this.fetchCountry()
    this.formData?.countryId && this.fetchStateById()
    this.formData?.stateId && this.fetchCityById()
    this.formData?.invoiceCountryId && this.fetchInvoiceStateById()
    this.formData?.invoiceStateId && this.fetchInvoiceCityById()
  },
  methods: {
    updateAccount() {
      if (this.validateIsCorporate()) {
        this.isLoading = true
        const payload = {
          inputModel: {
            ...this.formData,
            invoiceTaxNumber: Number(this.formData.invoiceTaxNumber),
          },
        }
        var clientValidationStatus = this.clientValidation()
        if (!clientValidationStatus) {
          this.isLoading = false
          this.toast.error(this.$t('shared.validateUserForm'))
          return
        }
        service
          .update('account', payload)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => { },
              errorCallback: () => { },
              isSaveMessage: true,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    fetchCountry() {
      service.fetch('country').then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.countryList =
              this.getLang === 'TR'
                ? response.items.sort((a, b) =>
                  a.translations.tr.localeCompare(b.translations.tr),
                )
                : response.items
            this.invoiceCountryList =
              this.getLang === 'TR'
                ? response.items.sort((a, b) =>
                  a.translations.tr.localeCompare(b.translations.tr),
                )
                : response.items
            this.formData.stateId && this.fetchStateById()
            this.formData.cityId && this.fetchCityById()
          },
          errorCallback: () => { },
          isSaveMessage: false,
        })
      })
    },
    fetchStateById() {
      if (this.formData.countryId) {
        service.fetch(`state/${this.formData.countryId}`).then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.stateList = response.items
              if (response.items.length > 0) {
                this.countryCode = response.items[0].countryCode
                this.formData.stateId = response.items[0].id
              }
              this.formData.stateId && this.fetchCityById()
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
      }
    },
    fetchCityById() {
      if (this.formData.stateId) {
        service
          .fetch(`city/${this.formData.stateId}/by-state`)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {
                this.cityList = response.items
                if (response.items.length > 0) {
                  this.formData.cityId = response.items[0].id
                }
              },
              errorCallback: () => { },
              isSaveMessage: false,
            })
          })
      }
    },

    fetchInvoiceStateById() {
      if (this.formData.invoiceCountryId) {
        service
          .fetch(`state/${this.formData.invoiceCountryId}`)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {
                this.invoiceStateList = response.items
                if (response.items.length > 0) {
                  this.invoiceCountryCode = response.items[0].countryCode
                  this.formData.invoiceStateId = response.items[0].id
                }
                this.formData.invoiceStateId && this.fetchInvoiceCityById()
              },
              errorCallback: () => { },
              isSaveMessage: false,
            })
          })
      }
    },
    fetchInvoiceCityById() {
      if (this.formData.invoiceStateId) {
        service
          .fetch(`city/${this.formData.invoiceStateId}/by-state`)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {
                this.invoiceCityList = response.items
                if (response.items.length > 0) {
                  this.formData.invoiceCityId = response.items[0].id
                }
              },
              errorCallback: () => { },
              isSaveMessage: false,
            })
          })
      }
    },

    validateEmail(email) {
      if (email !== null) {
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true
        this.toast.error(this.$t('shared.validateEmail'))
        return false
      }
    },
    clientValidation() {
      console.log(this.formData)
      if (this.formData.companyName == null || this.formData.companyName === "") {
        return false
      }
      if (this.formData.address == null || this.formData.address === "") {
        return false
      }
      if (this.formData.identityNumber == null || this.formData.identityNumber === "") {
        return false
      }
      if (this.formData.phoneNumber == null || this.formData.phoneNumber === "") {
        return false
      }
      if (this.formData.birthDate == null || this.formData.birthDate === "") {
        return false
      }

      return true
    },
    validateIsCorporate() {
      if (this.isCorporateInvoice) {
        if (
          this.formData.invoiceTaxNumber === '' ||
          this.formData.invoiceTaxOffice === ''
        ) {
          !this.formData.invoiceTaxNumber &&
            this.toast.error(this.$t('shared.validateTaxNumber'))
          !this.formData.invoiceTaxOffice &&
            this.toast.error(this.$t('shared.validateTaxOffice'))
          return false
        }
        return true
      } else {
        return true
      }
    },
    closeModal() {
      this.modal.hide()
    },
    openModal() {
      this.modal.show()
    },
    toggleCorporateInvoice() {
      this.isCorporateInvoice = !this.isCorporateInvoice
    },
    initModal() {
      const modalEl = document.getElementById('distance-sales-modal')
      const modalOptions = {
        placement: 'center-center',
        backdropClasses:
          'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        onHide: () => {
          this.isAgreement = true
        },
      }
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions)
      }
    },
  },
  computed: {
    ...mapGetters(['getLang']),
    birthDate: {
      get() {
        try {
          return moment(this.formData.birthDate).format('YYYY-MM-DD')
        } catch {
          return null
        }

      },
      set(value) {
        this.formData.birthDate = value
      }
    }
  }
}
</script>

<style scoped></style>
