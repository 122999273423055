<template>
  <div class="congress">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <select-account :selectAccount="selectAccount" :accountSubId="accountSubId" />
      <div class="tab">
        <tabs variant="underline" v-model="activeTab">
          <tab v-for="(item, key) in congressItems" :name="`tab-${key}`" :key="key" :title="$t(`admin.congressDetail.${item.productType.toLowerCase()}`)
            ">
            <component :is="`${item.productType.toLowerCase()}-tab`" :item="item" :key="item"
              :participantTypes="item.participantTypes" :congress="congress" :dispatchBasket="dispatchBasket" />
          </tab>
        </tabs>
      </div>
    </wrapper-with-loader>
    <basket-popup :isLoading="isBasketPopupLoading" :addAnotherCongress="addAnotherCongress"
      :noCurrentlyCongress="noCurrentlyCongress" />
  </div>
</template>

<script>
import { service } from "@/common/services";
import { useResponseToast, useToast, useMeta } from "@/common/hooks";
import { BreadcrumbList, PageTitle, WrapperWithLoader } from "@/components";
import SelectAccount from "./SelectAccount.vue";
import InsideTab from "./Inside.vue";
import OutsideTab from "./Outside.vue";
import AccommodationTab from "./Accommodation.vue";
import TransferTab from "./Transfer.vue";
import CourseTab from "./Course.vue";
import BasketPopup from "./BasketPopup.vue";
import { ref, computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Tabs, Tab } from "flowbite-vue";

export default {
  name: "UserCongress",
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    SelectAccount,
    InsideTab,
    OutsideTab,
    AccommodationTab,
    TransferTab,
    CourseTab,
    Tabs,
    Tab,
    BasketPopup,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      modal: null,
      breadcrumbList: [
        { name: this.$t("sidebar.congress"), url: "congress/id", key: "sidebar.congress" },
      ],
      pageTitle: null,
      isLoading: true,
      isBasketPopupLoading: false,
      congress: {},
      congressItems: [],
      congressDetailId: null,
      accountSubId: null,
      congressId: this.$route.params.id,
      activeTab: ref("tab-0"),
    };
  },
  watch: {
    congress: function (data) {
      return (this.congress = data);
    },
    congressItems: function (data) {
      return (this.congressItems = data);
    },
  },
  created() {
    useMeta(
      computed(() => ({
        title: `${this.$t("metaTitle.congress")} - ${this.getLang === "TR" ? this.congress?.name : this.congress?.nameEn
          }`,
      }))
    );
  },
  mounted() {
    this.initModal();

    this.fetchCongressDetailActiveList();
  },
  methods: {
    ...mapActions(["fetchBasket"]),
    fetchCongressDetailActiveList() {
      service
        .fetch(`congress-detail/active-list/${this.congressId}`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              if (response.items.length) {
                this.congress = response.congress;
                this.congressItems = response.items;
                this.pageTitle =
                  this.getLang === "TR"
                    ? response.congress.name
                    : response.congress.nameEn;
                this.breadcrumbList.push({
                  name:
                    this.getLang === "TR"
                      ? response.congress.name
                      : response.congress.nameEn,
                });
              } else {
                this.congress = {};
                this.congressItems = [];
              }
            },
            errorCallback: () => { },
            isSaveMessage: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    dispatchBasket(id, accommodationNotes = null, dates = null, transferDetails = null) {
      if (id) {
        let _transferDetails = [];
        if (transferDetails) {
          _transferDetails = transferDetails.filter((item) => Object.keys(item).length > 0);
        }

        this.congressDetailId = id;
        const payload = {
          congressId: this.$route.params.id,
          congressDetailId: id,
          accountSubId: this.accountSubId !== null ? this.accountSubId : null,
          bookDates: dates,
          notes: accommodationNotes,
          transferDetails: _transferDetails,
        };
        
        console.log(payload)

        service.dispatch("basket", payload).then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.toast.success(this.$t("user.congress.successBasket"));
              this.fetchBasket();
            },
            errorCallback: () => {
              response.messages.map((message) => {
                if (
                  message.code ===
                  "FEATURE_VALIDATION_BASKET_CANNOT_CREATE_ANOTHER_BASKET"
                ) {
                  this.modal.show();
                }
              });
            },
            isSaveMessage: false,
            noToastMessageCodes: [
              "FEATURE_VALIDATION_BASKET_CANNOT_CREATE_ANOTHER_BASKET",
            ],
          });
        });
      } else {
        this.toast.error(this.$t("user.congress.selectPackage"));
      }
    },
    selectAccount(id) {
      if (id === "null") {
        this.accountSubId = null;
      } else {
        this.accountSubId = id;
      }
    },
    addAnotherCongress() {
      this.isBasketPopupLoading = true;
      service
        .delete("basket")
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.dispatchBasket(this.congressDetailId);
            },
            errorCallback: () => { },
            isSaveMessage: false,
          });
        })
        .finally(() => {
          this.isBasketPopupLoading = false;
          this.modal.hide();
        });
    },
    noCurrentlyCongress() {
      this.modal.hide();
      this.toast.error(
        this.$t("error.FEATURE_VALIDATION_BASKET_CANNOT_CREATE_ANOTHER_BASKET")
      );
    },
    initModal() {
      const modalEl = document.getElementById("basket-popup-modal");
      const modalOptions = {
        placement: "top-center",
        backdropClasses:
          "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        onHide: () => { },
      };
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions);
      }
    },
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped lang="scss">
.congress {
  @apply h-full;
}
</style>
