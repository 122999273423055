<template>
  <div class="congress">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <template v-if="congress.items.length">
        <content-wrapper>
          <congress-action :search="search" :payload="payload" />
          <table-wrapper-with-loader :isLoading="isTableLoading">
            <template v-if="downXl">
              <congress-table-list
                :congress="congress"
                :payload="payload"
                :dispatchCongressList="dispatchCongressList"
                :pageSize="payload.pageSize"
              />
            </template>
            <template v-else>
              <congress-accordion-list
                :congress="congress"
                :payload="payload"
                :dispatchCongressList="dispatchCongressList"
                :pageSize="payload.pageSize"
              />
            </template>
          </table-wrapper-with-loader>
          <congress-pagination
            v-if="congress.items"
            :congress="congress"
            :changePage="changePage"
          />
        </content-wrapper>
      </template>
      <template v-else>
        <content-wrapper>
          <congress-action :search="search" :payload="payload" />
        </content-wrapper>
        <no-data externalClass="relative pt-12">
          <div class="text-2xl font-bold mb-2 text-gray-900">
            {{ $t('shared.noData') }}
          </div>
          <div class="text-sm font-normal text-gray-500">
            {{ $t('shared.noDataDescription') }}
          </div>
        </no-data>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
  import { computed } from 'vue'
import moment from 'moment'
import { useToast, useMediaQuery, useResponseToast,useMeta } from '@/common/hooks'
import { service } from '@/common/services'

import {
  WrapperWithLoader,
  TableWrapperWithLoader,
  NoData,
  ContentWrapper,
  BreadcrumbList,
  PageTitle,
} from '@/components'
import CongressAction from './CongressAction.vue'
import CongressTableList from './CongressTableList.vue'
import CongressAccordionList from './CongressAccordionList.vue'
import CongressPagination from './CongressPagination.vue'

export default {
  name: 'AdminCongressPage',
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    TableWrapperWithLoader,
    ContentWrapper,
    NoData,
    CongressAction,
    CongressTableList,
    CongressAccordionList,
    CongressPagination,
  },
  data() {
    return {
      moment: moment,
      breadcrumbList: [{ name: this.$t('sidebar.congress') }],
      pageTitle: this.$t('sidebar.congress'),
      congress: Object,
      isLoading: true,
      isTableLoading: false,
      payload: {
        searchText: '',
        pageNumber: 1,
        pageSize: 10,
        sortColumn: '',
        sortDescending: true,
        isActive: null,
      },
    }
  },
  setup() {
    const { downXl } = useMediaQuery()
    const toast = useToast()
    return { toast, downXl }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.congress'),
      })),
    )
  },
  mounted() {
    this.dispatchCongressList(this.payload)
  },
  watch: {
    congress: function (data) {
      return (this.congress = data)
    },
  },
  methods: {
    dispatchCongressList(payload) {
      this.isTableLoading = true
      service
        .dispatch('congress/list', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              if (response.items.length) {
                this.congress = response
              } else {
                this.congress = []
              }
            },
            errorCallback: () => {
              response.messages.map((message) => {
                if (message.code === 'Data is empty') {
                  this.payload = { ...payload }
                  this.congress.items = []
                }
              })
            },
            isSaveMessage: false,
            noToastMessageCodes: ['Data is empty'],
          })
        })
        .finally(() => {
          this.isLoading = false
          this.isTableLoading = false
        })
    },
    changePage(pageNumber) {
      this.payload = { ...this.payload, pageNumber }
      this.dispatchCongressList(this.payload)
    },
    search(searchText) {
      this.payload = { ...this.payload, searchText }
      this.dispatchCongressList(this.payload)
    },
  },
}
</script>

<style scoped lang="scss">
.congress {
  @apply h-full;
}
</style>
