<template>
  <div class="header">
    <div class="flex justify-between items-center h-[60px]">
      <router-link to="/" class="flex items-center focus:outline-none">
        <CustomIcon name="logo-with-text" class="ml-3 pl-2 h-8 pb-1.5 sm:hidden" />
      </router-link>

      <div class="flex items-center justify-between pr-6">
        <router-link :to="{ name: 'basket' }" class="relative">
          <ShoppingCartIcon class="w-8 h-8" />
          <div
            v-if="getBasket && getBasket.items.length > 0"
            class="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
          >
            {{ getBasket.items.length }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ShoppingCartIcon,
} from '@heroicons/vue/24/outline'
import CustomIcon from '@/components/Icon/CustomIcon.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'HeaderMenu',
  components: { ShoppingCartIcon, CustomIcon },
  computed: {
    ...mapGetters(['getBasket']),
  },
}
</script>
