<template>
  <div
    class="flex flex-col sm:flex-row justify-between p-4 bg-white dark:bg-gray-900"
  >
    <div
      class="w-full flex flex-col lg:flex-row flex-wrap 2xl:justify-between items-center gap-4"
    >
      <div class="w-full lg:w-auto">
        <div class="relative">
          <div
            class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
          >
            <MagnifyingGlassIcon
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
            />
          </div>
          <input
            type="text"
            id="table-search"
            v-model="formData.searchText"
            @keyup.enter="search($event.target.value)"
            class="block p-2 pl-10 w-full lg:w-48 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :placeholder="$t('shared.search')"
          />
        </div>
      </div>
      <div class="w-full lg:w-auto">
        <select
          id="orderStatus"
          v-model="formData.orderStatus"
          @change="filterOrderStatus($event)"
          class="w-full lg:w-[200px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="0">
            {{ $t("shared.all") }}
          </option>

          <option value="2">
            {{ $t("user.order.waitingfortransfer") }}
          </option>
          <option value="3">
            {{ $t("user.order.paymentcompleted") }}
          </option>
        </select>
      </div>
      <div class="w-full lg:w-auto">
        <select
          id="paymentType"
          v-model="formData.paymentType"
          @change="filterPaymentType($event)"
          class="w-full lg:w-[200px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="0">
            {{ $t("shared.all") }}
          </option>
          <option value="1">
            {{ $t("user.order.creditcard") }}
          </option>
          <option value="2">
            {{ $t("user.order.transfer") }}
          </option>
        </select>
      </div>
      <div class="w-full lg:w-auto">
        <DatePicker
          name="startDate"
          v-model="formData.startDate"
          @update:modelValue="filterStartDate"
          format="yyyy-MM-dd"
          locale="tr"
          :enableTimePicker="false"
          :placeholder="$t('admin.congressDetail.selectStartDate')"
          autoApply
        />
      </div>
      <div class="w-full lg:w-auto">
        <DatePicker
          name="endDate"
          v-model="formData.endDate"
          @update:modelValue="filterEndDate"
          format="yyyy-MM-dd"
          locale="tr"
          :enableTimePicker="false"
          :minDate="formData.startDate"
          :placeholder="$t('admin.congressDetail.selectEndDate')"
          autoApply
        />
      </div>
      <div class="w-full lg:w-auto">
        <download-excel
          class="inline-flex gap-1 cursor-pointer justify-center items-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          worksheet="My Worksheet"
          type="csv"
          :fields="excelColumn"
          :name="`${$t('sidebar.order')} - ${moment(new Date()).format(
            'DD.MM.YYYY'
          )}.csv`"
          :fetch="downloadExcel"
        >
          <CloudArrowDownIcon class="w-5 h-5 text-white" />
          <span>{{ $t("user.order.exportExcel") }}</span>
        </download-excel>
      </div>
      <div v-if="isClearFilter" class="whitespace-nowrap w-full lg:w-auto">
        <a
          @click="filterReset"
          class="cursor-pointer underline text-sm text-blue-500"
        >
          {{ $t("user.order.filterReset") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MagnifyingGlassIcon,
  CloudArrowDownIcon,
} from "@heroicons/vue/20/solid";
import DatePicker from "@vuepic/vue-datepicker";
import DownloadExcel from "vue-json-excel3";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
export default {
  name: "OrderAction",
  components: {
    MagnifyingGlassIcon,
    CloudArrowDownIcon,
    DatePicker,
    DownloadExcel,
  },
  data() {
    return {
      moment: moment,
      isClearFilter: false,
      excelColumn: {
        [this.$t("user.excelColumn.id")]: "id",
        [this.$t("user.excelColumn.congressName")]: "congressName",
        [this.$t("user.excelColumn.congressNameEn")]: "congressNameEn",
        [this.$t("user.excelColumn.orderStatus")]: "orderStatus",
        [this.$t("user.excelColumn.paymentType")]: "paymentType",
        [this.$t("user.excelColumn.totalPrice")]: "totalPrice",
        [this.$t("user.excelColumn.totalTax")]: "totalTax",
        [this.$t("user.excelColumn.invoiceName")]: "invoiceName",
        [this.$t("user.excelColumn.invoiceCountry")]: "invoiceCountry",
        [this.$t("user.excelColumn.invoiceAddress")]: "invoiceAddress",
        [this.$t("user.excelColumn.invoiceCity")]: "invoiceCity",
        [this.$t("user.excelColumn.invoiceTaxOffice")]: "invoiceTaxOffice",
        [this.$t("user.excelColumn.invoiceTaxNumber")]: "invoiceTaxNumber",
        [this.$t("user.excelColumn.startDate")]: "startDate",
        [this.$t("user.excelColumn.endDate")]: "endDate",
        [this.$t("user.excelColumn.documentPath")]: "documentPath",
        [this.$t("user.excelColumn.documentName")]: "documentName",
        [this.$t("user.excelColumn.orderNo")]: "orderNo",
        [this.$t("user.excelColumn.companyName")]: "companyName",
        [this.$t("user.excelColumn.firstName")]: "firstName",
        [this.$t("user.excelColumn.lastName")]: "lastName",
        [this.$t("user.excelColumn.createdDate")]: "createdDate",
        [this.$t("user.excelColumn.paymentApproveDate")]: "paymentApproveDate",
        [this.$t("user.excelColumn.isPaymentApproved")]: "isPaymentApproved",
      },
      formData: {
        searchText: "",
        startDate: null,
        endDate: null,
        paymentType: 0,
        orderStatus: 0,
      },
    };
  },
  props: {
    payload: {},
    search: Function,
    filterOrderStatus: Function,
    filterPaymentType: Function,
    filterStartDate: Function,
    filterEndDate: Function,
    filterReset: Function,
    downloadExcel: Function,
  },
  watch: {
    payload: function (data) {
      this.formData = data;
      this.isCheckClearFilter(data);
      return data;
    },
  },

  mounted() {
    this.formData = this.payload;
    this.isCheckClearFilter(this.payload);
  },
  methods: {
    isCheckClearFilter(data) {
      const { searchText, orderStatus, paymentType, startDate, endDate } = data;
      if (
        searchText !== "" ||
        orderStatus !== 0 ||
        paymentType !== 0 ||
        startDate !== null ||
        endDate !== null
      ) {
        this.isClearFilter = true;
      } else {
        this.isClearFilter = false;
      }
    },
  },
};
</script>
