<template>
    <select-account :selectAccount="selectAccount" :accountSubId="accountSubId" ref="selectAccount" />

    <!--StepParticipant.vue productTypesAccomHasBoth : {{ productTypesAccomHasBoth }}-->

    <template v-if="productTypesAccomHasBoth">
        <div class="flex justify-center py-4 mb-6">
            <content-wrapper>
                <label class="block mb-2 text-sm font-medium text-gray-900">
                    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {{ this.$t('wizard.order.accommodationRequested.title') }}
                    </h3>
                </label>
                <div class="flex items-center justify-between w-full">
                    <button @click="accommodationRequestedAnswer(true)" type="button"
                        class="inline-flex gap-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        {{ this.$t('wizard.order.accommodationRequested.yes') }}
                    </button>

                    <button @click="accommodationRequestedAnswer(false)" type="button"
                        class="inline-flex gap-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{{
                            this.$t('wizard.order.accommodationRequested.no') }}
                    </button>
                </div>
            </content-wrapper>
        </div>
    </template>


    <template v-else>
        <div class="flex justify-center py-4 mb-6">
            <content-wrapper>
                <label class="block mb-2 text-sm font-medium text-gray-900">
                    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {{ $t('wizard.order.participant.one.accom.title') }}
                    </h3>
                </label>
                <div class="w-full">
                    <div class="flex justify-center">
                        <!-- {{ isAccommodationRequested }}-->
                        <button-with-loader :isLoading="isLoading"
                            @click="accommodationRequestedAnswer(isAccommodationRequested)" type="button"
                            class="flex gap-1 items-center justify-center text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">
                            <CheckCircleIcon class="w-5 h-5" />
                            {{ $t('wizard.order.participant.one.accom.yes') }}
                        </button-with-loader>
                    </div>
                </div>
            </content-wrapper>
        </div>
    </template>

    <ParticipantPopup :accepted="participantSelected" :denied="participantDenied" ref="participantPoupCompRef" />

</template>


<script>
import SelectAccount from "/src/pages/Congress/SelectAccount.vue";
import ParticipantPopup from "./ParticipantPopup.vue";
import { useToast } from "@/common/hooks";
import { CheckCircleIcon } from '@heroicons/vue/24/outline'

import {
    ContentWrapper,
} from '@/components'

export default {
    name: "OrderWizardStep1",
    components: {
        SelectAccount,
        ParticipantPopup,
        ContentWrapper,
        CheckCircleIcon,
    },
    props: {
        selectAccount: Function,
        accountSubId: String,
        accountPerson: String,
        productTypesAccomHasBoth: Boolean,
        isAccommodationRequested: Boolean,
    },
    methods: {
        participantDenied() {
            this.modal.hide();
        },
        initModal() {
            const modalEl = document.getElementById("participant-popup-modal");
            const modalOptions = {
                placement: "top-center",
                backdropClasses:
                    "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
                onHide: () => { },
            };
            if (modalEl) {
                // eslint-disable-next-line
                this.modal = new Modal(modalEl, modalOptions);
            }
        },
        accommodationRequestedAnswer(answer) {
            if (this.accountSubId === null && (this.accountPerson === undefined || this.accountPerson === null)) {
                this.toast.error(this.$t('wizard.order.participant.error'));
                return;
            }
            console.log("accommodationRequestedAnswer");
            this.accommodationRequested = answer;

            this.$refs.participantPoupCompRef.updatePerson(this.accountPerson);

            this.modal.show();
        },
        participantSelected() {
            this.$emit('answered', this.accommodationRequested)
            this.modal.hide();
        },
        getSelectedAccount(){
            return this.$refs.selectAccount.getSelectedAccount();
        }
    },
    mounted() {
        this.initModal();
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
};
</script>