<template>
  <div class="flex flex-col w-full my-6">
    <content-wrapper>
      <h2 class="text-lg font-medium mb-2">
        {{ $t("user.orderCompleted.orderSummary") }}
      </h2>
      <ul class="mb-4">
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t("user.basket.congressName") }}</span>
          <span class="text-lg font-bold">{{ order.congressName }}</span>
        </li>

        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t("user.basket.totalVat") }}</span>
          <span class="text-lg font-bold">
            {{
              toCurrency(
                order.totalTax,
                "tr-TR",
                "currency",
                getCongressProductCurrency(order.productCurrency)
              )
            }}</span
          >
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t("user.basket.totalPrice") }}</span>
          <span class="text-lg font-bold">{{
            toCurrency(
              order.totalPrice,
              "tr-TR",
              "currency",
              getCongressProductCurrency(order.productCurrency)
            )
          }}</span>
        </li>
      </ul>
      <h2 class="text-lg font-medium mb-2">
        {{ $t("user.orderCompleted.orderBillingInfo") }}
      </h2>
      <ul class="mb-4">
        <li class="flex flex-wrap items-center border-b border-gray-200 p-4">
          <span>{{ $t("user.orderCompleted.invoiceName") }} :</span>
          <span>{{ order.invoiceName }}</span>
        </li>
        <li class="flex flex-wrap items-center border-b border-gray-200 p-4">
          <span>{{ $t("user.orderCompleted.invoiceAddress") }} :</span>
          <span>
            {{ order.invoiceAddress }} {{ order.invoiceCountry }} /
            {{ order.invoiceCity }} {{ order.invoiceTaxNumber }} /
            {{ order.invoiceTaxOffice }}
          </span>
        </li>
      </ul>
    </content-wrapper>
  </div>
</template>

<script>
import { ContentWrapper } from "@/components";
import { toCurrency } from "@/common/utils";

export default {
  name: "OrderCompletedSummary",
  components: { ContentWrapper },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    order: {},
  },
  methods: {
    toCurrency,
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
  },
};
</script>
