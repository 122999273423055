import tr from './tr.json'
import en from './en.json'

import {createI18n} from 'vue-i18n'

export const defaultLocale = 'TR'

export const languages = {
    EN: en,
    TR: tr,
}

const messages = Object.assign(languages)
export const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'EN',
    messages
})