<template>
  <div class="login">
    <div class="bg-gray-50 min-h-screen flex items-center justify-center">
      <content-wrapper
        externalClass="bg-white relative z-10 flex max-w-2xl w-full flex-col px-8 py-20 lg:py-20 lg:px-16  gap-8 rounded-lg shadow-2xl h-screen md:h-auto">
        <div>
          <div>
            <CustomIcon name="logo" class="w-8 h-8" />
          </div>
          <div class="flex justify-between items-center">
            <span class="text-3xl font-bold">
              {{ $t("user.login.loginLabel") }}
            </span>
          </div>

        </div>
        <div class="flex flex-row w-full text-center">
          <a @click="changeLanguage('EN')" :class="selectedLang === 'EN' && 'bg-gray-300'"
            class="w-1/2 cursor-pointer border-2 border-gray-300 rounded-l-md py-3 hover:bg-blue-100 hover:text-blue-600">
            <span>{{ $t("shared.english") }}</span>
          </a>
          <a @click="changeLanguage('TR')" :class="selectedLang === 'TR' && 'bg-gray-300'"
            class="w-1/2 cursor-pointer border-2 border-l-0 border-gray-300 rounded-r-md py-3 hover:bg-blue-100 hover:text-blue-600">
            <span>{{ $t("shared.turkish") }}</span>
          </a>
        </div>
        <login-form :formData="formData" :onLogin="onLogin" />
      </content-wrapper>
    </div>
    <div class="bottom-0 left-0 absolute hidden lg:block">
      <img src="@/assets/images/bottom-bg.png" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useMeta } from "vue-meta";
import { i18n } from "@/common/locale";
import { ContentWrapper, CustomIcon } from "@/components";
import LoginForm from "./LoginForm.vue";
// import LoginWithOid from "./LoginWithOID.vue";

export default {
  name: "LoginPage",
  components: { LoginForm, ContentWrapper, CustomIcon },
  data() {
    return {
      selectedLang: null,
    };
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t("metaTitle.login"),
      }))
    );
  },
  methods: {
    changeLanguage(val) {
      i18n.global.locale = val;
      if (val === "EN") {
        document.querySelector("html").setAttribute("lang", "en");
      } else {
        document.querySelector("html").setAttribute("lang", "tr");
      }
      localStorage.setItem("language", val);
      this.selectedLang = val;
    },
  },
};
</script>
