<template>
  <div class="bg-gray-100 p-5 flex justify-between items-center text-xs">
    <div class="min-w-[100px]">{{ $t('user.order.congressName') }}</div>
    <div class="min-w-[100px]">{{ $t('user.order.totalPrice') }}</div>
    <div class="w-5"></div>
  </div>
  <div v-for="item in order.items" :key="item">
    <div
      :id="`accordion-${item.id}-header`"
      class="accordion-header flex border-b-2 p-5 bg-white dark:bg-gray-800"
    >
      <div :aria-controls="`accordion-${item.id}-header`" class="w-full">
        <div class="flex justify-between items-center text-xs">
          <div class="w-[100px] min-w-[100px] sm:w-auto truncate">
            <span v-if="getLang === 'TR'">
              {{ item.congressName }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.congressNameEn }}
            </span>
          </div>
          <div class="min-w-[100px]">{{ toCurrency(item.totalPrice) }}</div>
          <div class="w-5">
            <div class="up-icon">
              <ChevronUpIcon class="h-6 w-6 text-blue-500" />
            </div>
            <div class="down-icon">
              <ChevronDownIcon class="h-6 w-6 text-blue-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="`accordion-${item.id}-body`"
      class="hidden"
      :aria-labelledby="`accordion-${item.id}-body`"
    >
      <div
        class="p-5 text-sm bg-gray-200 border-gray-200 dark:border-gray-700 dark:bg-gray-900"
      >
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.orderNo') }}:
          </span>
          <span class="text-gray-900">
            {{ item.orderNo }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.paymentType') }}:
          </span>
          <span class="text-gray-900">
            {{ $t(`user.order.${item.paymentType.toLowerCase()}`) }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.orderStatus') }}:
          </span>
          <span class="text-gray-900">
            {{ $t(`user.order.${item.orderStatus.toLowerCase()}`) }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.createdDate') }}:
          </span>
          <span class="text-gray-900">
            {{ moment(item.createdDate).format('DD.MM.YYYY HH:mm') }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t('user.order.totalTax') }}:
          </span>
          <span class="text-gray-900">{{ toCurrency(item.totalTax) }}</span>
        </div>
        <div class="flex mb-2">
          <router-link
            :to="{ name: 'userOrder/id', params: { id: item.id } }"
            class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t('shared.details') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import { toCurrency } from '@/common/utils'

export default {
  name: 'OrderAccordionList',
  components: { ChevronUpIcon, ChevronDownIcon },
  data() {
    return {
      moment: moment,
      accordion: null,
    }
  },
  props: {
    order: Object,
    pageSize: Number,
    payload: Object,
  },
  mounted() {
    this.initAccordion(this.order.items)
  },
  methods: {
    initAccordion(items) {
      let accordionItems = []
      if (
        items !== undefined &&
        document.querySelector('.accordion-header') !== null
      ) {
        items.map((item, index) => {
          if (index === 0) {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: true,
            })
          } else {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: false,
            })
          }
        })
        const options = {
          alwaysOpen: false,
          activeClasses: 'text-gray-900 dark:text-white',
          inactiveClasses: 'text-gray-900 dark:text-white',
        }
        // eslint-disable-next-line
        this.accordion = new Accordion(accordionItems, options)
      }
    },
    toCurrency
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.accordion-header[aria-expanded='true'] {
  .up-icon {
    @apply block;
  }

  .down-icon {
    @apply hidden;
  }
}

.accordion-header[aria-expanded='false'] {
  .up-icon {
    @apply hidden;
  }

  .down-icon {
    @apply block;
  }
}
</style>
