<template>
    <div v-if="downXl">
        <nav class="flex justify-between items-center p-4">
            <span class="text-sm font-normal text-gray-500 dark:text-gray-400">{{$t('shared.showing')}}
                <span class="font-semibold text-gray-900 dark:text-white">{{ order.pageNumber }}-{{
                order.pageCount }}</span> /
                <span class="font-semibold text-gray-900 dark:text-white">{{ order.totalItemCount}}</span></span>
            <ul v-if="order.pageCount > 1" class="inline-flex items-center -space-x-px">
                <li>
                    <a href="#" @click="changePage(order.pageNumber - 1)"
                        :class="[!order.hasPreviousPage && 'pointer-events-none bg-gray-200']"
                        class="disable block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <span class="sr-only">{{$t('shared.prev')}}</span>
                        <ChevronLeftIcon class="w-5 h-5" />
                    </a>
                </li>
                <li v-for="page in order.pageCount" :key="page" aria-current="page">
                    <a href="#" @click="changePage(parseInt(page) )"
                        :class="[order.pageNumber === page ? 'pointer-events-none leading-tight text-blue-600 bg-blue-50 border border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white':' text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white']"
                        class="py-2 px-3 leading-tight">{{ page}}</a>
                </li>
                <li v-if="order.pageNumber > 5">
                    <a href="#" @click="changePage(order.pageNumber  + 1)"
                        class="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">...</a>
                </li>

                <li>
                    <a href="#" @click="changePage(order.pageNumber +1)"
                        :class="[!order.hasNextPage && 'pointer-events-none bg-gray-200']"
                        class="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <span class="sr-only">{{$t('shared.next')}}</span>
                        <ChevronRightIcon class="w-5 h-5" />
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <div v-else>
        <div class="my-2">
            <span class="text-sm font-normal text-gray-500 dark:text-gray-400">{{$t('shared.showing')}}
                <span class="font-semibold text-gray-900 dark:text-white">{{ order.pageNumber }}-{{
                order.pageCount }}</span> /
                <span class="font-semibold text-gray-900 dark:text-white">{{ order.totalItemCount}}</span></span>
        </div>
        <div class="flex justify-between">
            <button type="button" @click="changePage(order.pageNumber - 1)"
                :class="[!order.hasPreviousPage && 'pointer-events-none bg-gray-200']"
                class="py-2.5 px-5 mr-2 text-sm w-1/2 flex justify-center items-center font-medium text-blue-500 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                <ChevronLeftIcon class="w-5 h-5" />
                <span>{{$t('shared.prev')}}</span>
            </button>
            <button type="button" @click="changePage(order.pageNumber +1)"
                :class="[!order.hasNextPage && 'pointer-events-none bg-gray-200']"
                class="py-2.5 px-5 mr-2 w-1/2 text-sm flex justify-center items-center font-medium text-blue-500 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                <span>{{$t('shared.next')}}</span>
                <ChevronRightIcon class="w-5 h-5" />
            </button>
        </div>
    </div>
</template>
    
<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { useMediaQuery } from "@/common/hooks";

export default {
    name: "OrderPagination",
    components: { ChevronLeftIcon, ChevronRightIcon },
    setup() {
        const { downXl } = useMediaQuery()
        return { downXl }
    },
    props: {
        order: Object,
        changePage: Function,
    }
};
</script>
    