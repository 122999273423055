<template>
  <form>
    <div class="flex flex-wrap flex-col lg:flex-row">
      <div class="w-full lg:w-1/2 lg:pr-2">
        <div class="flex-auto mb-6">
          <label for="name" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.name") }}
          </label>
          <input type="text" id="name" v-model="formData.name"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="name" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.shortName") }}
          </label>
          <input type="text" id="shortName" v-model="formData.shortName"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="start" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.startDate") }}
          </label>
          <div class="flex items-center gap-1">
            <div class="flex-auto">
              <DatePicker name="startDate" v-model="formData.startDate" format="yyyy-MM-dd" locale="tr"
                :enableTimePicker="false" :placeholder="$t('admin.congressDetail.selectStartDate')" autoApply />
            </div>
            <Popper :hover="true" :arrow="true" placement="top">
              <button>
                <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
              </button>
              <template #content>
                <span class="text-xs whitespace-nowrap">
                  {{ $t("admin.congress.startDateInfo") }}
                </span>
              </template>
            </Popper>
          </div>
        </div>
        <div class="flex-auto mb-6">
          <label for="totalDay" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.totalDay") }}
          </label>
          <div class="flex items-center gap-1">
            <input type="number" id="totalDay" v-model="formData.totalDay"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
            <Popper :hover="true" :arrow="true" placement="top">
              <button>
                <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
              </button>
              <template #content>
                <span class="text-xs whitespace-nowrap">
                  {{ $t("admin.congress.totalDayInfo") }}
                </span>
              </template>
            </Popper>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/2 lg:pl-2">
        <div class="flex-auto mb-6">
          <label for="nameEn" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.nameEn") }}
          </label>
          <input type="text" id="nameEn" v-model="formData.nameEn"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="contactEmail" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.contactEmail") }}
          </label>
          <input type="text" id="contactEmail" v-model="formData.contactEmail"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div class="flex-auto mb-6">
          <label for="totalNumberOfPeople" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.totalNumberOfPeople") }}
          </label>
          <input type="number" id="totalNumberOfPeople" v-model="formData.totalNumberOfPeople"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
      </div>
      <div class="w-full flex flex-col lg:flex-row">
        <div class="flex-auto mb-6">
          <label for="fileName" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressEdit.uploadImage") }}
          </label>
          <input
            class="file-upload block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            id="fileName" @change="changeImage" type="file" accept="image/png, image/jpeg" />
        </div>
        <div class="flex-auto flex justify-center mb-6">
          <figure class="max-w-lg flex flex-col items-center justify-center">
            <img v-if="formData.imagePath" class="max-w-full h-auto rounded-lg" :src="formData.imagePath"
              alt="image description" />
            <figcaption class="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">
              <a :href="formData.imagePath" target="blank">
                {{ formData.imagePath }}
              </a>
            </figcaption>
          </figure>
        </div>
      </div>
      <div class="w-full">
        <div class="flex-auto mb-6">
          <label for="description" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressDetail.description") }}
          </label>
          <textarea id="message" v-model="formData.description" rows="4"
            class="block p-2.5 w-full text-sm resize-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
        </div>
        <div class="flex-auto mb-6">
          <label for="descriptionEn" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressDetail.descriptionEn") }}
          </label>
          <textarea id="message" v-model="formData.descriptionEn" rows="4"
            class="block p-2.5 w-full text-sm resize-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
        </div>
      </div>
      <div class="w-full">
        <div class="flex-auto">
          <label for="productCurrency" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressDetail.productCurrencyType") }}
          </label>

          <select
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="productCurrency" :value="0">
            <option :value="0">TL</option>
            <option :value="1">EUR</option>
            <option :value="2">USD</option>
          </select>
        </div>
      </div>
      <div class="w-full">
        <div class="flex-auto mb-6">
          <label class="hidden mb-2 lg:block">&nbsp;</label>
          <label for="isActive" class="inline-flex relative items-center mb-4 cursor-pointer">
            <input type="checkbox" id="isActive" class="sr-only peer" v-model="formData.isActive" />
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
            </div>
            <span v-if="formData.isActive" class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              {{ $t("admin.congressEdit.active") }}
            </span>
            <span v-if="formData.isActive === false || formData.isActive === undefined
            " class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              {{ $t("admin.congressEdit.passive") }}
            </span>
          </label>
        </div>
      </div>

      <div class="w-full">
        <div class="flex-auto mb-6">
          <label class="hidden mb-2 lg:block">&nbsp;</label>
          <label for="wireInformations" class="block mb-2 text-sm font-medium text-gray-900">
            <div class="w-full">
              <label class="hidden mb-2 lg:block">Congress Wire Informations
                {{ $t("admin.congressDetail.productCurrencyType") }}</label>

              <button v-for="(wireInformation) in this.wireInformations" :key="wireInformation" :class="[
                'w-32 gap-1 justify-center text-white bg-gray-400 hover:bg-blue-700 font-medium text-sm px-5 py-2.5 text-center',
                { active: currentWireInformation === wireInformation },
              ]" @click.prevent="currentWireInformation = wireInformation">
                {{ wireInformation.Currency }}
              </button>

              <CongressWireInformation :wireInformation="this.currentWireInformation" :isEditable="true" />
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button-with-loader :isLoading="isLoading" type="button" @click="save"
        class="w-32 inline-flex gap-1 items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
        <CustomIcon name="save-icon" />
        {{ $t("shared.save") }}
      </button-with-loader>
    </div>
  </form>
</template>

<script>
/* eslint-disable */
import { useResponseToast, useToast } from "@/common/hooks";
import moment from "moment";
import router from "@/router";
import { service } from "@/common/services";
import { ButtonWithLoader, CustomIcon } from "@/components";
import DatePicker from "@vuepic/vue-datepicker";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import "@vuepic/vue-datepicker/dist/main.css";
import Popper from "vue3-popper";
import CongressWireInformation from "@/components/Congress/CongressWireInformation.vue";

export default {
  name: "CongressEditForm",
  components: {
    ButtonWithLoader,
    DatePicker,
    CustomIcon,
    InformationCircleIcon,
    Popper,
    CongressWireInformation,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      moment: moment,
      formData: {},
      wireInformations: [],
      currentWireInformation: undefined,
      productCurrency: 0,
      isLoading: false,
      congressId: this.$route.params.id,
    };
  },
  props: {
    propFormData: {},
  },
  mounted() {
    this.formData = this.propFormData;
    console.log("formData: " + JSON.stringify(this.formData));
    if (this.formData.productCurrency != null)
      this.productCurrency = this.formData.productCurrency;

    if (this.formData.wireTransferInformations != null)
      this.wireInformations = JSON.parse(
        this.formData.wireTransferInformations
      );
    else {
      this.wireInformations = [this.emptyWireInformation("TL"), this.emptyWireInformation("EUR"), this.emptyWireInformation("USD")];
    }

    this.currentWireInformation = this.wireInformations[0];
  },
  methods: {
    emptyWireInformation(currency) {
      currency = currency || "TL";
      return {
        Currency: currency,
        AccountName: "",
        BankName: "",
        SwitftCode: "",
        EFTCode: "",
        BranchName: "",
        BranchCode: "",
        BankAddress: "",
        PrimaryIban: "",
      };
      //return new WireInformation(AccountName="",BankName="",SwitftCode="",EFTCode="",BranchName="",BranchCode="",BankAddress="",PrimaryIban="",SecondaryIban="")
    },

    save() {
      var wireStatus = this.checkWires();

      if (!wireStatus) {
        this.toast.error(this.$t("shared.validateWireTransfer"));
        return false;
      }

      if (this.validateEmail(this.formData.contactEmail)) {
        (this.isLoading = true),
          (this.formData.productCurrency = this.productCurrency);
        if (this.congressId) {
          const payload = {
            ...this.formData,
            wireTransferInformations: JSON.stringify(this.wireInformations),
            startDate:
              this.formData.startDate !== null
                ? moment(this.formData.startDate).format("YYYY-MM-DD")
                : null,
            endDate:
              this.formData.endDate !== null
                ? moment(this.formData.endDate).format("YYYY-MM-DD")
                : null,
            congressId: this.congressId,
          };
          service
            .update("congress", payload)
            .then((response) => {
              useResponseToast({
                response: response,
                successCallback: () => {
                  router.push({ name: "congress" });
                },
                errorCallback: () => { },
                isSaveMessage: true,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          const payload = {
            ...this.formData,
            wireTransferInformations: JSON.stringify(this.wireInformations),
            startDate:
              this.formData.startDate !== null
                ? moment(this.formData.startDate).format("YYYY-MM-DD")
                : null,
            endDate:
              this.formData.endDate !== null
                ? moment(this.formData.endDate).format("YYYY-MM-DD")
                : null,
          };
          service
            .dispatch("congress", payload)
            .then((response) => {
              useResponseToast({
                response: response,
                successCallback: () => {
                  router.push({ name: "congress" });
                },
                errorCallback: () => { },
                isSaveMessage: true,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    checkWires() {
      var wireInformations

      if (this.formData.wireTransferInformations != null && this.formData.wireTransferInformations != undefined) {
        wireInformations = JSON.parse(
          this.formData.wireTransferInformations
        );
      } else {
        wireInformations = JSON.parse(JSON.stringify(this.wireInformations))
      }

      for (let i = 0; i < wireInformations.length; i++) {
        const element = wireInformations[i];
        if (element.AccountName != "" || element.BankName != "" || element.PrimaryIban != "")
          return true;
      }
      return false;
    },
    validateEmail(email) {
      if (email !== null) {
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true;
        this.toast.error(this.$t("shared.validateEmail"));
        return false;
      }
    },
    changeImage($event) {
      const selectedImage = $event.target.files[0];
      this.formData.fileName = selectedImage.name;
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result.split("base64,");
        this.formData.fileBase64String = base64String[1];
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>

<style scoped>
.active {
  @apply bg-blue-700 !important;
}
</style>
