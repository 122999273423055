<template>
  <slot></slot>
</template>

<script>

export default {
  name: "DefaultLayout",
}
</script>
