export const toCurrency = (value,locale="tr-TR",style="currency",currency="TRY") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: style,
    currency: currency,
    minimumFractionDigits: 2,
  }).format(value).toLocaleString()
  if(locale === "tr-TR"){
    const newFormat = formatter.split("₺");
    return formatter.replace("₺","").replace(newFormat[1],`${newFormat[1]} ₺`)
  }
  return formatter;
};
