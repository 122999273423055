<template>
  <form>
    <div>
      <div class="flex flex-wrap flex-col lg:flex-row">
        <div class="w-full">
          <div class="flex-auto mb-6">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.productType") }}
            </label>
            <select id="productType" v-model="formData.productType"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option selected>
                {{ $t("admin.congressDetail.chooseProductType") }}
              </option>
              <option value="1">{{ $t("admin.congressDetail.inside") }}</option>
              <option value="2">
                {{ $t("admin.congressDetail.outside") }}
              </option>
              <option value="3">
                {{ $t("admin.congressDetail.accommodation") }}
              </option>
              <option value="4">
                {{ $t("admin.congressDetail.transfer") }}
              </option>
              <option value="5">{{ $t("admin.congressDetail.course") }}</option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-1/2 lg:pr-2">
          <div class="flex-auto mb-6">
            <label for="participantType" class="block mb-2 text-sm font-medium text-gray-900">
              <span v-if="String(formData.productType) === '1'">
                {{ $t("admin.congressDetail.participantType") }}
              </span>
              <span v-if="String(formData.productType) === '2'">
                {{ $t("admin.congressDetail.participantType") }}
              </span>
              <span v-if="String(formData.productType) === '3'">
                {{ $t("admin.congressDetail.accommodationType") }}
              </span>
              <span v-if="String(formData.productType) === '4'">
                {{ $t("admin.congressDetail.transferType") }}
              </span>
              <span v-if="String(formData.productType) === '5'">
                {{ $t("admin.congressDetail.courseType") }}
              </span>
            </label>
            <input type="text" id="participantType" v-model="formData.participantType"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
          <div class="flex-auto mb-6">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.name") }}
            </label>
            <input type="text" id="name" v-model="formData.name"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>

          <div class="flex-auto mb-6">
            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.price") }}
              {{ productCurrency }}

              <span v-if="currency == 0"> ₺ </span>

              <span v-if="currency == 1"> € </span>

              <span v-if="currency == 2"> $ </span>
            </label>
            <input type="number" id="price" v-model="formData.price"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
        </div>
        <div class="w-full lg:w-1/2 lg:pl-2">
          <div class="flex-auto mb-6">
            <label for="participantTypeEn" class="block mb-2 text-sm font-medium text-gray-900">
              <span v-if="String(formData.productType) === '1'">
                {{ $t("admin.congressDetail.participantTypeEn") }}
              </span>
              <span v-if="String(formData.productType) === '2'">
                {{ $t("admin.congressDetail.participantTypeEn") }}
              </span>
              <span v-if="String(formData.productType) === '3'">
                {{ $t("admin.congressDetail.accommodationTypeEn") }}
              </span>
              <span v-if="String(formData.productType) === '4'">
                {{ $t("admin.congressDetail.transferTypeEn") }}
              </span>
              <span v-if="String(formData.productType) === '5'">
                {{ $t("admin.congressDetail.courseTypeEn") }}
              </span>
            </label>
            <input type="text" id="participantTypeEn" v-model="formData.participantTypeEn"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
          <div class="flex-auto mb-6">
            <label for="nameEn" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.nameEn") }}
            </label>
            <input type="text" id="nameEn" v-model="formData.nameEn"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
          <div class="flex-auto mb-6">
            <label for="vatRate" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.vatRate") }}
            </label>
            <input type="number" id="vatRate" v-model="formData.vatRate"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
        </div>
        <div class="w-full lg:w-1/2 custom-date-picker lg:pr-2">
          <div class="flex-auto mb-6">
            <label for="start" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressEdit.startDate") }}
            </label>
            <div class="flex items-center gap-1">
              <div class="flex-auto">
                <DatePicker name="startDate" v-model="formData.startDate" format="yyyy-MM-dd" locale="tr"
                  :enableTimePicker="false" :placeholder="$t('admin.congressDetail.selectStartDate')" autoApply />
              </div>
              <Popper :hover="true" :arrow="true" placement="top">
                <button>
                  <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                </button>
                <template #content>
                  <span class="text-xs whitespace-nowrap">
                    {{ $t("admin.congress.startDateInfo") }}
                  </span>
                </template>
              </Popper>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 custom-date-picker lg:pl-2">
          <div class="flex-auto mb-6">
            <label for="totalDay" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressEdit.totalDay") }}
            </label>
            <div class="flex items-center gap-1">
              <input type="number" id="totalDay" v-model="formData.totalDay"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
              <Popper :hover="true" :arrow="true" placement="top">
                <button>
                  <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                </button>
                <template #content>
                  <span class="text-xs whitespace-nowrap">
                    {{ $t("admin.congress.totalDayInfo") }}
                  </span>
                </template>
              </Popper>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 custom-date-picker lg:pr-2">
          <div class="flex-auto mb-6">
            <label for="start" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressEdit.mandatoryStartDate") }}
            </label>
            <div class="flex items-center gap-1">
              <div class="flex-auto">
                <DatePicker name="mandatoryStartDate" v-model="formData.mandatoryStartDate" format="yyyy-MM-dd"
                  locale="tr" :enableTimePicker="false" autoApply />
              </div>
              <Popper :hover="true" :arrow="true" placement="top">
                <button>
                  <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                </button>
                <template #content>
                  <span class="text-xs whitespace-nowrap">
                    {{ $t("admin.congressEdit.mandatoryStartDateInfo") }}
                  </span>
                </template>
              </Popper>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 custom-date-picker lg:pr-2">
          <div class="flex-auto mb-6">
            <label for="start" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressEdit.mandatoryEndDate") }}
            </label>
            <div class="flex items-center gap-1">
              <div class="flex-auto">
                <DatePicker name="mandatoryEndDate" v-model="formData.mandatoryEndDate" format="yyyy-MM-dd" locale="tr"
                  :enableTimePicker="false" autoApply />
              </div>
              <Popper :hover="true" :arrow="true" placement="top">
                <button>
                  <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                </button>
                <template #content>
                  <span class="text-xs whitespace-nowrap">
                    {{ $t("admin.congressEdit.mandatoryEndDateInfo") }}
                  </span>
                </template>
              </Popper>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 custom-date-picker lg:pr-2">
          <div class="flex-auto mb-6">
            <label for="lastRegistrationDate" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.lastRegistrationDate") }}
            </label>
            <div class="flex items-center gap-1">
              <div class="flex-auto">
                <DatePicker name="lastRegistrationDate" v-model="formData.lastRegistrationDate" format="yyyy-MM-dd"
                  locale="tr" :enableTimePicker="false" autoApply
                  :placeholder="$t('admin.congressDetail.selectDate')" />
              </div>
              <Popper :hover="true" :arrow="true" placement="top">
                <button>
                  <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                </button>
                <template #content>
                  <span class="text-xs whitespace-nowrap">
                    {{ $t("admin.congressDetail.lastRegistrationDateInfo") }}
                  </span>
                </template>
              </Popper>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 custom-date-picker lg:pr-2">
          <div class="flex-auto mb-6">
            <label for="vatRate" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.stockQuantity") }}
            </label>
            <div class="flex items-center gap-1">
              <div class="flex-auto">
                <input type="number" id="stockQuantity" v-model="formData.stockQuantity"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
              </div>

              <Popper :hover="true" :arrow="true" placement="top">
                <button>
                  <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                </button>
                <template #content>

                  <template v-if="propFormData != null && propFormData != undefined && propFormData.soldQuantity != undefined">
                    <span class="text-gray-500">
                      {{ $t("admin.congressDetail.stockSoldQuantity") }} : {{ propFormData.soldQuantity }}
                    </span>
                    <br>

                    <span class="text-gray-500">
                      {{ $t("admin.congressDetail.stockReservedQuantity") }} : {{ propFormData.reservedQuantity }}
                    </span>
                    <br>

                    <span class="text-gray-500" v-if="propFormData.stockQuantity != null && propFormData.stockQuantity != 0">
                      {{ $t("admin.congressDetail.stockLeft") }} :  {{ propFormData.stockQuantity - (propFormData.soldQuantity + propFormData.reservedQuantity) }}
                    </span>

                    <span v-else-if="propFormData.stockQuantity == 0">
                      {{ $t("admin.congressDetail.outOfStock") }}
                    </span>

                    <span v-else>
                      {{ $t("admin.congressDetail.stockUnlimited") }}
                    </span>

                    <br>
                  </template>

                  <span class="text-xs">
                    {{ $t("admin.congressDetail.stockInfo") }}
                  </span>
                </template>
              </Popper>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 custom-date-picker lg:pl-2" v-if="String(formData.productType) === '3'">
          <div class="flex-auto mb-6">
            <label for="pricePerDay" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.pricePerDay") }}

              <span v-if="currency == 0"> ₺ </span>

              <span v-if="currency == 1"> € </span>

              <span v-if="currency == 2"> $ </span>
            </label>

            <div class="flex items-center gap-1">
              <label style="margin: -12px -1px" for="pricePerDayIsActive"
                class="inline-flex relative items-center mb-4 cursor-pointer">
                <input type="checkbox" id="pricePerDayIsActive" class="sr-only peer" v-model="pricePerDayIsActive" />
                <div
                  class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                </div>
                <span v-if="pricePerDayIsActive" class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                </span>
                <span v-if="pricePerDayIsActive === false ||
                  pricePerDayIsActive === undefined
                " class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                </span>
              </label>

              <input :disabled="pricePerDayIsActive != true" type="number" id="pricePerDay"
                v-model="formData.pricePerDay"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
              <Popper :hover="true" :arrow="true" placement="top">
                <span>
                  <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                </span>
                <template #content>
                  <span class="text-xs whitespace-pre-line">
                    {{ $t("admin.congressDetail.pricePerDayDetail") }}
                  </span>
                </template>
              </Popper>
            </div>

          </div>
        </div>

        <div class="w-full lg:w-1/2 custom-date-picker lg:pl-2" v-if="String(formData.productType) === '4'">
          <div class="flex-auto mb-6">
            <label class="block mb-2 text-sm font-medium text-gray-900">&nbsp;</label>
            <label class="inline-flex relative items-center mb-2 cursor-pointer">
              <input type="checkbox" @change="toggleTransferRequired" class="sr-only peer" />
              <div
                class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
              </div>
              <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                <template v-if="formData.isFlightDetailsRequired">
                  {{ $t('admin.congressDetail.transferDetailRequired') }}
                </template>
                <template v-else>
                  {{ $t('admin.congressDetail.transferDetailNotRequired') }}
                </template>
              </span>
            </label>


          </div>
        </div>

        <div class="w-full mb-2" v-if="formData.isFlightDetailsRequired">
          <label for="transferDetails" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t("admin.congressDetail.transferDetail") }}
          </label>

          <select id="transferDetails" v-model="formData.isFlightDetailsOneWay"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option :value="null"> {{ $t("admin.congressDetail.transferDetailSelect") }} </option>
            <option :value="true">{{ $t("admin.congressDetail.transferOneWay") }}</option>
            <option :value="false"> {{ $t("admin.congressDetail.transferTwoWay") }} </option>
          </select>

        </div>

        <div class="w-full">
          <div class="flex-auto mb-6">
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.description") }}
            </label>
            <textarea id="description" v-model="formData.description" rows="4"
              class="block p-2.5 w-full text-sm resize-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
          </div>
        </div>
        <div class="w-full">
          <div class="flex-auto mb-6">
            <label for="descriptionEn" class="block mb-2 text-sm font-medium text-gray-900">
              {{ $t("admin.congressDetail.descriptionEn") }}
            </label>
            <textarea id="description" v-model="formData.descriptionEn" rows="4"
              class="block p-2.5 w-full text-sm resize-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button-with-loader :isLoading="isLoading" type="submit" @click="save"
        class="w-32 inline-flex gap-1 items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
        <CustomIcon name="save-icon" />
        {{ $t("shared.save") }}
      </button-with-loader>
    </div>
  </form>
</template>

<script>
import { useResponseToast, useToast, useMediaQuery } from "@/common/hooks";
import moment from "moment";
import { service } from "@/common/services";
import { ButtonWithLoader, CustomIcon } from "@/components";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Popper from "vue3-popper";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

export default {
  name: "CongressDetailEditForm",
  components: {
    ButtonWithLoader,
    DatePicker,
    CustomIcon,
    Popper,
    InformationCircleIcon,
  },
  setup() {
    const toast = useToast();
    const { downXl } = useMediaQuery();
    return { toast, downXl };
  },
  data() {
    return {
      moment: moment,
      formData: {
        productType: null,
        pricePerDay: Number,
        isFlightDetailsOneWay: null,
        isFlightDetailsRequired: false,
      },
      pricePerDayIsActive: Boolean,
      isLoading: false,
    };
  },
  props: {
    productType: {
      type: Number,
      required: false,
    },
    detailId: {
      type: Number,
      required: false,
    },
    dispatchCongressDetailListByFilter: Function,
    closeModal: Function,
    propFormData: {},
    payload: {},
    currency: Number,
  },
  watch: {
    propFormData: function (data) {
      this.pricePerDayIsActive =
        data.pricePerDay != undefined && data.pricePerDay > 0;
      return (this.formData = data);
    },
    productType: function (data) {
      return (this.formData.productType = data);
    },
  },
  updated() {
    if (this.formData.productType === undefined) {
      this.formData.productType = this.productType;
    }

    if (this.formData.isFlightDetailsOneWay === undefined) {
      this.formData.isFlightDetailsOneWay = null;
    }

    if (this.formData.isFlightDetailsRequired === undefined) {
      this.formData.isFlightDetailsRequired = false;
    }
  },
  methods: {
    save() {
      this.isLoading = true;

      if (this.formData.isFlightDetailsRequired == undefined) {
        this.formData.isFlightDetailsRequired = false;
      }

      if (
        this.formData.pricePerDay == undefined ||
        this.formData.pricePerDay == "" ||
        this.formData.pricePerDay == 0
      ) {
        this.formData.pricePerDay = null;
      }

      if (this.pricePerDayIsActive !== true) {
        this.formData.pricePerDay = null;
      }

      console.log(this.formData.mandatoryStartDate);
      if (this.formData.mandatoryStartDate !== undefined) {
        if (
          moment(this.formData.mandatoryStartDate).isBefore(
            moment(this.formData.startDate)
          )
        ) {
          this.toast.error(this.$t("admin.congressEdit.mandatoryStartDateError"));
          this.isLoading = false;
          event.preventDefault()
          return;
        }
      }

      let calculatedEndDateFormatted = moment(this.formData.startDate)
        .add(this.formData.totalDay, "days")
        .format("YYYY-MM-DD");

      let mandatoryEndDateFormatted = moment(this.formData.mandatoryEndDate).format("YYYY-MM-DD");

      if (this.formData.mandatoryEndDate !== undefined) {
        if (
          moment(calculatedEndDateFormatted).isBefore(
            moment(mandatoryEndDateFormatted)
          )
        ) {
          this.toast.error(this.$t("admin.congressEdit.mandatoryEndDateError"));
          this.isLoading = false;
          event.preventDefault()
          return;
        }
      }
      let _mandatoryStartDate = moment(this.formData.mandatoryStartDate).format("YYYY-MM-DD");
      let _mandatoryEndDate = moment(this.formData.mandatoryEndDate).format("YYYY-MM-DD");

      if (_mandatoryStartDate === "Invalid date") {
        _mandatoryStartDate = null;
      }

      if (_mandatoryEndDate === "Invalid date") {
        _mandatoryEndDate = null;
      }


      if (this.formData.id) {
        const payload = {
          updateCongressDetailInputModel: {
            ...this.formData,
            productType: parseInt(this.formData.productType),
            startDate: moment(this.formData.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.formData.endDate).format("YYYY-MM-DD"),
            mandatoryStartDate: _mandatoryStartDate,
            mandatoryEndDate: _mandatoryEndDate,
            lastRegistrationDate: moment(
              this.formData.lastRegistrationDate
            ).format("YYYY-MM-DD"),
            id: this.detailId,
          },
        };
        service
          .update("congress-detail", payload)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {
                this.dispatchCongressDetailListByFilter(this.payload);
                this.closeModal();
              },
              errorCallback: () => { },
              isSaveMessage: true,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        const payload = {
          createCongressDetailInputModel: {
            ...this.formData,
            productType: parseInt(this.formData.productType),
            startDate: moment(this.formData.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.formData.endDate).format("YYYY-MM-DD"),
            mandatoryStartDate: moment(this.formData.mandatoryStartDate).format("YYYY-MM-DD"),
            mandatoryEndDate: moment(this.formData.mandatoryEndDate).format("YYYY-MM-DD"),
            lastRegistrationDate: moment(
              this.formData.lastRegistrationDate
            ).format("YYYY-MM-DD"),
            congressId: this.$route.params.id,
          },
        };
        service
          .dispatch("congress-detail", payload)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {
                this.formData = {};
                this.dispatchCongressDetailListByFilter(this.payload);
                this.closeModal();
              },
              errorCallback: () => { },
              isSaveMessage: true,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    toggleTransferRequired() {
      console.log(this.formData.isFlightDetailsOneWay);
      console.log(this.formData.isFlightDetailsRequired);

      this.formData.isFlightDetailsRequired = !this.formData.isFlightDetailsRequired
    },

    validateEmail(email) {
      if (email !== null) {
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true;
        this.toast.error(this.$t("shared.validateEmail"));
        return false;
      }
    },
  },
};
</script>
