<template>
  <div
    class="sidebar"
  >
    <div
      v-if="accountCurrent"
      class="sidebar-inner relative overflow-auto"
    >
      <top-menu :accountCurrent="accountCurrent" :logout="logout"/>
    </div>
  </div>
</template>

<script>
import { authService, accountCurrent } from '@/common/services'
import TopMenu from './TopMenu'

export default {
  name: 'SidebarMenu',
  components: { TopMenu},
  data() {
    return {
      accountCurrent: null,
    }
  },
  created() {
    accountCurrent.subscribe((next) => {
      this.accountCurrent = next
    })
  },
  methods: {
    logout() {
      authService.logout()
    },
  },
}
</script>

<style lang="scss">
.sidebar {
  .router-link-active:not(.static)  {
    @apply bg-gray-50 before:absolute before:border-[#1da1f2] before:border-l-4 before:rounded-r-lg before:flex before:items-center before:h-8;
  }
}
</style>
