<template>
  <div class="flex flex-col w-full xl:w-1/3">
    <content-wrapper>
      <h2 class="text-lg font-medium mb-2">{{ $t('user.basket.basketSummary') }}</h2>

      <ul class="mb-4">
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t('user.basket.totalPrice') }}</span>
          <span class="text-lg font-bold">{{
            toCurrency(basket.totalPrice, "tr-TR", "currency", getCongressProductCurrency(basket.productCurrency))
            }}</span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t('user.basket.totalVat') }}</span>
          <span class="text-lg font-bold" v-if="basket.totalVat != 0">{{
            toCurrency(basket.totalVat, "tr-TR", "currency", getCongressProductCurrency(basket.productCurrency))
          }}</span>
        </li>
        <li class="flex justify-between border-b border-gray-200 p-4">
          <span>{{ $t('user.basket.salePrice') }}</span>
          <span class="text-lg font-bold">{{
            toCurrency(basket.salePrice, "tr-TR", "currency", getCongressProductCurrency(basket.productCurrency))
          }}</span>
        </li>
      </ul>
      <template v-if="basket.paymentPageOpenedAtIsValidUntil != null">
        <button-with-loader @click="goToPayment" :isLoading="isLoading" type="button"
          class="inline-flex gap-1 items-center justify-center text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-200 dark:focus:ring-yellow-900 font-medium rounded-lg text-sm px-5 py-2.5 w-full text-center">
          {{ $t('user.basket.continueToPayment') }}
          <ArrowLongRightIcon class="w-5 h-5" />
        </button-with-loader>
      </template>
      <template v-else>
        <button-with-loader @click="goToPayment" :isLoading="isLoading" type="button"
          class="inline-flex gap-1 items-center justify-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 w-full text-center">
          {{ $t('user.basket.buy') }}
          <ArrowLongRightIcon class="w-5 h-5" />
        </button-with-loader>
      </template>
    </content-wrapper>
  </div>
</template>

<script>
import router from '@/router'
import { ButtonWithLoader, ContentWrapper } from '@/components'
import { ArrowLongRightIcon } from '@heroicons/vue/24/outline'
import { toCurrency } from '@/common/utils'
export default {
  name: 'BasketSummary',
  components: { ButtonWithLoader, ContentWrapper, ArrowLongRightIcon },
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    basket: {},
  },
  methods: {
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    goToPayment() {
      this.isLoading = true
      setTimeout(() => {
        router.push({ name: 'payment' })
        this.isLoading = false
      }, 500)
    },
    toCurrency
  },
}
</script>
