<template>
  <div class="register">
    <div class="bg-gray-50 min-h-screen flex items-center justify-center">
      <content-wrapper
        externalClass="bg-white relative z-10 flex max-w-2xl w-full flex-col px-8 py-20 lg:py-20 lg:px-16  gap-8 rounded-lg shadow-2xl h-screen md:h-auto"
      >
        <div>
          <div>
            <CustomIcon name="logo" class="w-8 h-8" />
          </div>
          <div>
            <span class="text-3xl font-bold">
              {{ $t('user.register.registerLabel') }}
            </span>
          </div>
          <div class="flex my-5">
            <login-with-oid />
          </div>
        
        </div>
        <register-form />
      </content-wrapper>
    </div>
    <div class="bottom-0 left-0 absolute hidden lg:block">
      <img src="@/assets/images/bottom-bg.png" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import { ContentWrapper, CustomIcon } from '@/components'
import RegisterForm from './RegisterForm.vue'
// import LoginWithOid from '@/pages/Auth/Login/LoginWithOID.vue'

export default {
  name: 'RegisterPage',
  components: { RegisterForm, ContentWrapper, CustomIcon },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.register'),
      })),
    )
  },
}
</script>
