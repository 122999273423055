<template>
  <div class="flex py-4">
    <div class="w-full lg:w-1/2">
      <div class="flex-auto mb-6">
        <label
          for="ccOwner"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          {{ $t('user.payment.ccOwner') }}
        </label>
        <input
          type="text"
          id="ccOwner"
          v-model="formData.ccOwner"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
      </div>

      <div class="flex-auto mb-6">
        <label
          for="cardNumber"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          {{ $t('user.payment.cardNumber') }}
        </label>
        <input
          type="text"
          id="cardNumber"
          v-maska="'#### #### #### ####'"
          v-model="formData.cardNumber"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
      </div>

      <div class="flex justify-between flex-auto gap-4 mb-6">
        <div class="flex-auto">
          <label
            for="expiryMonth"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.payment.expiryMonth') }}
          </label>

          <select
            id="expiryMonth"
            v-model="formData.expiryMonth"
            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="null" selected disabled>
              {{ $t('user.payment.chooseMonth') }}
            </option>
            <option v-for="month in monthList" :key="month" :value="month">
              {{ month }}
            </option>
          </select>
        </div>
        <div class="flex-auto">
          <label
            for="expiryYear"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.payment.expiryYear') }}
          </label>
          <select
            id="expiryYear"
            v-model="formData.expiryYear"
            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="null" disabled>
              {{ $t('user.payment.chooseYear') }}
            </option>
            <option v-for="year in yearList" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="flex-auto w-[50px]">
          <label for="cvv" class="block mb-2 text-sm font-medium text-gray-900">
            {{ $t('user.payment.cvv') }}
          </label>
          <input
            type="text"
            id="cvv"
            v-maska="'###'"
            v-click-outside="focusoutCvv"
            @focus="focusCvv"
            v-model="formData.cvv"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>
    </div>
    
    <div
      class="hidden lg:flex justify-center items-center w-1/2 relative card-image"
    >
      <Transition name="card-front-transition">
        <div class="absolute" v-if="cardImage === 'front'">
          <img
            class="w-full h-auto max-h-[350px]"
            src="@/assets/images/card-front.png"
            alt="Card Front"
          />
          <div
            class="front bg-transparent text-lg w-full text-white px-12 absolute left-0 bottom-12"
          >
            <p class="number mb-5 sm:text-xl">{{ formData.cardNumber }}</p>
            <div class="flex flex-row justify-between">
              <p>{{ formData.ccOwner }}</p>
              <div class="">
                <span x-text="expired.month">{{ formData.expiryMonth }}</span>
                <span v-if="formData.expiryMonth !== null">/</span>
                <span>{{ formData.expiryYear }}</span>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="card-back-transition">
        <div class="absolute" v-if="cardImage === 'back'">
          <img
            class="w-full h-auto max-h-[350px]"
            src="@/assets/images/card-back.png"
            alt="Card Back"
          />
          <div
            class="bg-transparent text-black text-lg w-full flex justify-end absolute bottom-20 px-8 sm:bottom-24 right-0 sm:px-12"
          >
            <div
              class="bg-white rounded-md w-16 h-9 flex justify-center items-center"
            >
              <p>{{ formData.cvv }}</p>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
  <div class="flex items-center mb-4" style="display:none;">
        <input
          id="is3d"
          type="checkbox"
          @change="change3d"
          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          for="is3d"
          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {{ $t('user.payment.use3d') }}
        </label>
      </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'CreditCardTab',
  data() {
    return {
      moment: moment,
      monthList: [],
      yearList: [],
      cardImage: 'front',
      formData: {
        expiryMonth: null,
        expiryYear: null,
      },
    }
  },
  props:{
    setFormData:Function,
  },
  updated(){
    this.setFormData(this.formData)
  },
  mounted() {
    this.getMonthList()
    this.getYearList()
  },
  methods: {
    getMonthList() {
      for (let i = 1; i <= 12; i++) {
        if (i < 10) {
          this.monthList.push(`0${i}`)
        } else {
          this.monthList.push(i.toString())
        }
      }
    },
    getYearList() {
      for (let i = 0; i <= 5; i++) {
        this.yearList.push(moment().add(i, 'y').format('YYYY'))
      }
    },
    focusCvv() {
      this.cardImage = 'back'
    },
    focusoutCvv() {
      this.cardImage = 'front'
    },
    change3d($event){
      if($event.target.checked){
        this.$attrs.onChangePaymentType("credit-card-tab-3d")
      }else{
        this.$attrs.onChangePaymentType("credit-card-tab")
      }
    }
  },
}
</script>

<style>
.card-image {
  perspective: 1000px;
}
.card-front-transition-enter-active {
  transition: all 0.5s ease-out;
  transform-style: preserve-3d;
}

.card-front-transition-leave-active {
  transition: all 0.5s ease-out;
}

.card-front-transition-enter-from,
.card-front-transition-leave-to {
  transform: rotateY(180deg);
  opacity: 0;
}

.card-back-transition-enter-active {
  transition: all 0.5s ease-out;
  transform-style: preserve-3d;
}

.card-back-transition-leave-active {
  transition: all 0.5s ease-out;
}

.card-back-transition-enter-from,
.card-back-transition-leave-to {
  transform: rotateY(-180deg);
  opacity: 0;
}
</style>
