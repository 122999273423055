<template>
    <div id="basket-popup-modal" tabindex="-1"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full">
        <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div class="p-6 text-center">
                    <ExclamationCircleIcon class="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" />
                    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {{$t('user.congress.basketPopupDescription')}}</h3>
                    <div class="flex justify-center">
                        <button-with-loader :isLoading="isLoading" @click="addAnotherCongress" type="button"
                            class="w-24 flex gap-1 items-center justify-center text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">
                           <CheckCircleIcon class="w-5 h-5" />
                            {{ $t('user.congress.basketPopupYes')}}
                        </button-with-loader>
                        <button @click="noCurrentlyCongress" type="button"
                        class="inline-flex gap-1 items-center justify-center text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            <MinusCircleIcon class="w-5 h-5" />
                            {{ $t('user.congress.basketPopupNo')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ButtonWithLoader} from "@/components";
import {ExclamationCircleIcon,CheckCircleIcon, MinusCircleIcon} from '@heroicons/vue/24/outline'
export default {
    name: "BasketPopup",
    components: { ButtonWithLoader,ExclamationCircleIcon,CheckCircleIcon, MinusCircleIcon },
    props: {
        addAnotherCongress: Function,
        noCurrentlyCongress: Function,
        isLoading: Boolean
    }
}
</script>