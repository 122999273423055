import { createApp } from "vue";
import App from "./App.vue";
import store from "./common/store";
import router from "./router";
import { i18n } from "./common/locale";
import VueCookies from "vue3-cookies";
import Maska from "maska";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vClickOutside from "click-outside-vue3"
import "./assets/style/index.scss";
import 'flowbite/dist/flowbite.js'
import {setupInterceptors} from "@/common/services";
import { createMetaManager } from 'vue-meta'

setupInterceptors();
const app = createApp(App);
const toastOptions = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};
app.use(router);
router.app = app;
app.use(VueCookies).use(store).use(i18n).use(Maska).use(Toast,toastOptions).use(vClickOutside).use(createMetaManager()).mount("#app");
