<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead
      class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
    >
      <tr>
        <th scope="col" class="p-4">
          {{ $t("user.basket.productType") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.name") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.participantType") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.price") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.vat") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.vatRatio") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.startDate") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.endDate") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in order.items"
        :key="item.id"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td
          class="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t(`shared.${item.productType.toLowerCase()}`) }}

        </td>
        <td
          class="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          <span v-if="getLang === 'TR'">
            {{ item.congressesDetailName }}
          </span>
          <span v-if="getLang === 'EN'">
            {{ item.congressesDetailNameEn }}
          </span>
        </td>
        <td class="p-4">
          <span v-if="getLang === 'TR'">
            {{ item.participantType }}
          </span>
          <span v-if="getLang === 'EN'">
            {{ item.participantTypeEn }}
          </span>
        </td>
        <td class="p-4">
          {{
            toCurrency(
              item.price,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.orderBasketDetailCurrency)
            )
          }}
        </td>
        <td class="p-4">
          {{
            toCurrency(
              item.vat,
              "tr-TR",
              "currency",
              getCongressProductCurrency(item.orderBasketDetailCurrency)
            )
          }}
        </td>
        <td class="p-4">%{{ item.vatRatio }}</td>
        <td class="p-4">
          {{ moment(item.startDate).format("DD.MM.YYYY") }}
        </td>
        <td class="p-4">
          {{ moment(item.endDate).format("DD.MM.YYYY") }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { toCurrency } from "@/common/utils";

export default {
  name: "OrderCompletedTableList",
  data() {
    return {
      moment: moment,
    };
  },
  props: {
    order: Object,
  },
  methods: {
    toCurrency,
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
