<template>
  <div class="privacy-policy">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <content-wrapper>
        <div class="flex flex-col lg:flex-row">
          <div
            class="min-w-auto lg:min-w-[350px] mr-0 lg:mr-6 border rounded p-6 h-min mb-6 lg:mb-0"
          >
            <div class="text-base font-bold uppercase mb-6">
              {{ pageTitle }}
            </div>
            <ul class="sidebar-menu mb-6">
              <li>
                <router-link class="active" :to="{ name: 'privacyPolicy' }">
                  {{ $t('user.staticPages.privacyPolicy') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'termsOfUse' }">
                  {{ $t('user.staticPages.termsOfUse') }}
                </router-link>
              </li>
            </ul>
            <button
              @click="goToContact"
              type="button"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            >
              {{ $t('user.staticPages.contactUs') }}
            </button>
          </div>
          <div class="w-auto">
            <div v-if="getLang === 'EN'">
              <p class="text-sm mb-2">
                SERENAS Uluslararası Turizm Kongre Organizasyon A.Ş. (“Serenas
                Group”) believes in respecting your privacy and right to protect
                your personal data in all of our commercial activities. This
                platform owned by Serenas Group, is operated in compliance with
                the provisions set forth in Law No.6698 of Protection of
                Personal Data and within our privacy policy, we would like to
                inform you on our privacy principles and provide information on
                the processing activities of your personal data.
              </p>
              <p class="text-sm mb-2">
                Your personal data are processed for the purpose of our
                contractual obligations, to provide the services of access to
                the organisations and events you would like to attend to, to
                properly respond to your requests, demands and complaints and to
                improve your user experience within our platform. The personal
                data processed for our services include your identification
                data, communication data, data regarding information
                technologies, event attendance data and data to be filled out
                for membership.
              </p>
              <p class="text-sm mb-2">
                We carry out our data processing activities stated herein this
                Privacy Policy, due to your explicit consent and, pursuant to
                Article 5 paragraph 2, subclause (a), (c), (ç) and (f) which are
                bright-line provisions, to establish and/or conduct a contract
                requiring to process data of the contracting parties, mandatory
                to fulfil legal obligations, or due to our legitimate interest.
              </p>
              <p class="text-sm mb-2">
                With the exception of direct marketing purposes, the personal
                data collected to carry out a contract, are stored in accordance
                with contractual warranties, right to withdrawal or any other
                rights arising from consumer protection legislation, and legal
                terms set forth in consumer protection, commercial, taxation and
                other relevant regulations. At the end of this period, we store
                the necessary data concerning contracts to comply with
                commercial and taxation legislation for the term of ten years.
                Throughout this period, your data may be shared and/or
                reprocessed with authorised public institutions only if there is
                a dispute and/or a mandatory request, or third party consultants
                to carry out independent audit processes and/or an
                administrative audit was performed.
              </p>
              <p class="text-base font-bold mb-2">Membership System</p>
              <p class="text-sm mb-2">
                To use our Services and certain features or functionalities, may
                require you to register an account with us, or you may choose to
                register an account to improve your user experience and to
                benefit from a password-protected account. When you do, we may
                ask you to provide certain registration details or other
                information about yourself. All of your data and information are
                safely secured in our servers and does not require you to check
                into the system every time you are using our platform. You may
                change the data you shared from your membership account any time
                you want, update or delete your membership entirely.
              </p>
              <p class="text-sm mb-2">
                To register as a member, you must enter an e-mail address and a
                password. These data are stored and processed to provide you a
                safe access and keep your personal information safe and secure.
              </p>
              <p class="text-sm mb-2">
                If you subscribe to attend one of our conferences or other
                events, we will ask you for a minimal amount of personal data to
                enable us to process your booking, to keep you informed of any
                developments concerning your attendance at the conference or
                event and for administration purposes. Our legal basis for
                processing your personal data for these purposes is contractual.
              </p>
              <p class="text-base font-bold mb-2">Data Transfer</p>
              <p class="text-sm mb-2">
                Your data, in accordance with the processing purposes and
                categories of which are specified above, may be shared with
                authorized persons, institutions and organizations as required
                by law, legal obligations or upon request. In addition, it can
                be shared with our lawyers, consultants and other real or legal
                private legal persons from whom we receive other services for
                the purposes of conducting our activities in accordance with the
                legislation, following up legal affairs, strategic planning
                activities and ensuring information security.
              </p>
              <p class="text-base font-bold mb-2">Legal Rights</p>
              <p class="text-sm mb-2">
                Your requests regarding your rights listed in Article 11 of the
                Law on Protection of Personal Data No. 6698, data controller
                company SERENAS Uluslararası Turizm Kongre Organizasyon A.Ş.,
                can be send through the communication channels by duly applying.
                Accordingly, to forward your requests, you can send an e-mail to
                kvk@serenas.com.tr via your e-mail address registered in the
                system, Hilal Mah. Algeria Cad. No: 13 06550 Yıldız,
                Çankaya/ANKARA via registered mail or notary process.
              </p>
            </div>
            <div v-if="getLang === 'TR'">
              <p class="text-sm mb-2">
                SERENAS Uluslararası Turizm Kongre Organizasyon A.Ş. (“Serenas
                Group”) olarak tüm ticari faaliyetlerimizde gizliliğinize ve
                kişisel verilerinizi koruma haklarınıza özen gösteriyoruz.
                Serenas Group tarafından yürütülen işbu platform, 6698 sayılı
                Kişisel Verilerin Korunması Kanunu (“KVKK”) ve ilgili mevzuatına
                uyum içinde yürütülmekte olup, işbu politika kapsamında gizlilik
                ilkeleri ve işlenen kişisel verilerinize ilişkin
                bilgilendirmelerimiz açıklanmaktadır.
              </p>
              <p class="text-sm mb-2">
                Platformumuz vasıtasıyla vermekte olduğumuz hizmetler kapsamında
                kişisel verileriniz, sözleşme ifası gereği organizasyon ve
                etkinliklerimize erişiminizin sağlanması, istek, talep ve
                şikayetlerinizin alınması ve yanıtlanması ve kullanıcı
                deneyiminizin geliştirilmesi amaçlarıyla işlenmektedir. Bu
                doğrultuda işlenen kişisel verileriniz, kimlik verileriniz,
                iletişim verileriniz, işlem güvenliği verileriniz, katılım
                verileriniz, üyelik verilerinizden oluşmaktadır.
              </p>
              <p class="text-sm mb-2">
                İşbu gizlilik sözleşmesi nezdinde yer alan işleme
                faaliyetlerinin hukuki dayanağı açık rızanız gerektirmeyen
                hallerde 6698 sayılı Kişisel Verilerin Korunması Kanunu 5.
                maddesinin 2. fıkrasının (a), (c), (ç) ve (f) bentlerinde yer
                alan kanunlarda açıkça öngörülme, bir sözleşmenin kurulması veya
                ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
                taraflarına ait kişisel verilerin işlenmesinin gerekli olması,
                veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
                zorunlu olması ve ilgili kişinin temel hak ve özgürlüklerine
                zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
                için veri işlenmesinin zorunlu olması hukuki şartlarıdır.
              </p>
              <p class="text-sm mb-2">
                Doğrudan pazarlama amacıyla irtibat bilgilerinizin işlenmediği
                hallerde, sözleşmenin ifa edilmesi amacıyla toplanan veriler
                sözleşmesel garanti ve güvence ile cayma ve tüketici
                mevzuatından doğan diğer haklara yönelik süreler ile ticaret ve
                vergi kanunları düzenlemeleri dışında kalan mevzuat hükümlerinde
                öngörülen sürelerin sona ermesine dek saklanmaktadır. Bu sürenin
                sona ermesinin ardından, ticaret ve vergi kanunları uyarınca
                gerekli olan sözleşme ilişkisine ilişkin bilgileri ise mevzuat
                uyarınca öngörülen süre olan sözleşmenin sona erdiği tarihten
                itibaren on yıl boyunca saklanmaktadır. Bu süre içerisinde
                verileriniz yalnızca herhangi bir hukuki uyuşmazlık halinde
                ispat unsuru olarak ve/veya yetkili kamu kurum ve kuruluşlarının
                taleplerinin yerine getirilmesi, bağımsız idari otoritelerin
                denetim gerçekleştirmesi halinde, finansal ve vergi doğrulaması
                amaçlarıyla; ayrıca olası bir suç faaliyetini açıklığa
                kavuşturma amaçlarıyla tekrar işlenebilecek ve
                aktarılabilecektir.
              </p>
              <p class="text-base font-bold mb-2">Üyelik Sistemi</p>
              <p class="text-sm mb-2">
                Sitemiz üzerinden gerçekleştireceğiniz alışverişler ve
                faydalanacağınız diğer hizmetler çerçevesinde kullanıcı
                deneyiminizin geliştirilmesi ve kişisel verilerinizin sizin
                belirlediğiniz ve size ait bir şifreyle korunan bir müşteri
                hesabında saklanmasının sağlanması amacıyla üyelik seçeneği
                sunulmaktadır. Bir üyelik kaydı oluşturulduğunda gerekli
                bilgiler tekrar girilmek zorunda olmayıp hesabınız nezdinde
                güvenle saklanmaktadır. Üyelik kaydınızda yer alan kişisel
                verilerinizi dilediğiniz zaman değiştirebilir, güncelleyebilir
                veya üyelik kaydınızı silebilirsiniz.
              </p>
              <p class="text-sm mb-2">
                Üyelik sistemine giriş için e-posta adresi ve şifre belirlemeniz
                gerekmektedir. Bu verileriniz üyelik sistemine erişiminizin
                sağlanması ve bilgilerinizin güvenle saklanması amacıyla
                kullanılmakta ve işlenmektedir.
              </p>
              <p class="text-sm mb-2">
                Konferanslarımızdan veya diğer etkinliklerimizden birine
                katılmak için üye olursanız, rezervasyonunuzu işleme
                koyabilmemiz, konferans veya etkinliğe katılımınızla ilgili
                gelişmelerden sizi haberdar edebilmemiz ve yönetim süreçlerinin
                yürütülmesi amaçlarıyla kişisel verileriniz sözleşmenin ifasına
                dayanarak işlenmektedir.
              </p>
              <p class="text-base font-bold mb-2">
                Kişisel Verilerinizin Aktarılması
              </p>
              <p class="text-sm mb-2">
                Yukarıda işleme amaçları ve kategorileri belirtilen verileriniz,
                kanunlarda öngörülmesi, hukuki yükümlülükler gereği veya talep
                halinde yetkili kişi kurum ve kuruluşlar ile paylaşılabilir.
                Bununla birlikte, faaliyetlerimizin mevzuata uygun yürütülmesi,
                hukuk işlerinin takibi, stratejik planlama faaliyetleri ve bilgi
                güvenliğinin sağlanması amaçlarıyla avukatlarımız,
                danışmanlarımız ve diğer hizmet alınan gerçek veya tüzel özel
                hukuk kişileri ile paylaşılabilir.
              </p>
              <p class="text-sm mb-2">
                İlaveten, SERENAS ID platformunun sunucuları için hizmet alınan
                Amazon Web Services sunucularının yurt dışında konumlu olması
                nedeniyle, kişisel verileriniz yalnızca veri saklama amaçları
                ile açık rızanız dahilinde aktarılacaktır.
              </p>
              <p class="text-base font-bold mb-2">İlgili Kişi Hakları</p>
              <p class="text-sm mb-2">
                6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11.
                maddesinde sayılan haklarınıza ilişkin taleplerinizi, veri
                sorumlusu şirket SERENAS Uluslararası Turizm Kongre Organizasyon
                A.Ş. iletişim kanalları üzerinden usulüne uygun başvuru yaparak
                iletebilirsiniz. Buna göre, taleplerinizi iletmek için sistemde
                kayıtlı e-posta adresiniz üzerinden kvk@serenas.com.tr adresine
                e-posta gönderebilir, Hilal Mah. Cezayir Cad. No:13 06550
                Yıldız, Çankaya/ANKARA adresine iadeli taahhütlü mektup veya
                noter kanalıyla ulaştırabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </content-wrapper>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import router from '@/router'

import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  ContentWrapper,
} from '@/components'

export default {
  name: 'PrivacyPolicyPage',
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    ContentWrapper,
  },
  data() {
    return {
      breadcrumbList: [{ name: this.$t('sidebar.privacyPolicy'), key:'sidebar.privacyPolicy' }],
      pageTitle: this.$t('sidebar.privacyPolicy'),
      isLoading: true,
    }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.privacyPolicy'),
      })),
    )
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    goToContact() {
      router.push({ name: 'contact' })
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.privacy-policy {
  @apply h-full;
  .sidebar-menu li {
    @apply mb-4;

    a {
      &.active {
        @apply text-blue-500;
      }
      @apply text-base font-medium text-gray-500;

      &:hover {
        @apply text-blue-500;
      }
    }
  }

  .content-list li {
    @apply list-disc mb-4 ml-4;

    a {
      @apply text-blue-500;
    }
  }
}
</style>
