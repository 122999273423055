<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
/* eslint-disable */
import DefaultLayout from './DefaultLayout.vue'
import AuthorizeLayout from './AuthorizeLayout.vue'
import PublicLayout from './PublicLayout.vue'
export default {
  name: 'AppLayout',
  components: { DefaultLayout, PublicLayout, AuthorizeLayout },
  data: () => ({
    layout: DefaultLayout,
  }),
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (Object.keys(route.meta).length) {
          this.layout = route.meta.layout
        }
      },
    },
  },
}
</script>
