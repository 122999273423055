<template>
  <div class="wrapper flex flex-col">
    <header-menu />
    <sidebar-menu />
    <div class="grow sm:ml-64">
      <div class="main-inner bg-gray-50">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { SidebarMenu, HeaderMenu } from '@/components'
import { service } from '@/common/services'
import { mapActions, mapGetters } from 'vuex'
import { useResponseToast } from '@/common/hooks'
import { i18n } from '@/common/locale'

export default {
  name: 'AuthorizeLayout',
  components: { SidebarMenu, HeaderMenu },
  created() {
    this.fetchAccountCurrent()
    this.fetchBasket()
    if (this.getLang !== localStorage.getItem('lang')) {
      this.updateAccountLang()
    } else {
      i18n.global.locale = this.getLang
    }
    if (i18n.global.locale === "EN") {
      document.querySelector("html").setAttribute("lang", "en");
    } else {
      document.querySelector("html").setAttribute("lang", "tr");
    }
  },
  mounted() { },
  methods: {
    ...mapActions(['fetchAccountCurrent', 'fetchBasket', 'setLang']),
    updateAccountLang() {
      const payload = {
        langCode: this.getLang,
      }
      service
        .update('account/lang', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              i18n.global.locale = this.getLang
              this.setLang(this.getLang)
              localStorage.setItem('lang', this.getLang)
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .catch((err) => {
          console.debug('DEBUG ERR: ' + err)
        })
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>
