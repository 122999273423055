<template>
  <div class="p-6 space-y-6">
    <div class="flex flex-col gap-2">
      <span class="text-sm">{{ $t('user.login.email') }}</span>
      <input
        type="email"
        required
        v-model="email"
        class="border-gray-300 border-2 bg-gray-100 rounded-md px-4 py-2 font-light text-black"
        :placeholder="$t('user.login.email')"
      />
    </div>
    <div class="flex my-5">
      <button-with-loader
        @click="dispatchAccountForgotPassword"
        :isLoading="isLoading"
        type="button"
        class="p-4 bg-blue-600 text-white w-full rounded-xl"
      >
        {{ $t('shared.submit') }}
      </button-with-loader>
    </div>
  </div>
</template>

<script>
import { useResponseToast, useToast } from '@/common/hooks'
import { service } from '@/common/services'
import { ButtonWithLoader } from '@/components'
export default {
  name: 'ForgotPasswordForm',
  components: { ButtonWithLoader },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      email: null,
      isLoading: false,
    }
  },
  props: {
    closeModal: Function,
    propEmail: String,
  },
  watch: {
    propEmail: function (data) {
      this.email = data
      return data
    },
  },
  mounted() {
    this.email = this.propEmail
  },
  methods: {
    dispatchAccountForgotPassword() {
      if (this.validateEmail(this.email)) {
        this.isLoading = true
        const payload = { email: this.email }
        service
          .dispatch('account/forgot-password', payload)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {
                this.toast.success(this.$t('user.login.forgotPasswordSuccess'))
                this.closeModal()
              },
              errorCallback: () => {},
              isSaveMessage: false,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    validateEmail(email) {
      if (email !== null) {
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true
        this.toast.error(this.$t('shared.validateEmail'))
        return false
      }
    },
  },
}
</script>
