<template v-if="source != null">
    <div id="credit-card-response-modal" tabindex="-1" aria-hidden="true"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
                    <button type="button" @click="closeModal"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <XMarkIcon class="w-5 h-5" />
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-6 space-y-6">
                    <iframe id="iframe" class="w-full h-[500px]"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { API_BASE_URL } from '@/environment/environment'
export default {
    name: 'IyzicoResponseModal',
    components: { XMarkIcon },
    props: {
        closeModal: Function,
        source: String,
        iyzicoPaymentResult: Function,
    },
    updated() {
        let element = document.getElementById('iframe')
        element.src = this.source;
        window.addEventListener('message', this.listenerEvent)
    },
    methods: {
        listenerEvent(listener) {
            if (listener.origin === API_BASE_URL) {
                this.iyzicoPaymentResult(listener.data)
                window.removeEventListener('message', this.listenerEvent, false)
            }
        },
    },
}
</script>