<template>
    <div class="overflow-x-auto relative py-4">
      IYZICO
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { ref } from "vue";
  
  export default {
    name: "IyzicoTab",
    components: {
    },
    computed: {
      ...mapGetters(["getBasket", "getLang"]),
    },
    data() {
      return {
        activeTab: ref("wire.0"),
      };
    },
    methods: {},
  };
  </script>
  