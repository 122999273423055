<template>
  <table
    v-if="wireInformation != undefined"
    class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
  >
    <tbody>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.primaryIban") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.PrimaryIban"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.PrimaryIban }}</p>
        </td>
      </tr>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.bankAccountName") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.AccountName"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.AccountName }}</p>
        </td>
      </tr>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.bankName") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.BankName"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.BankName }}</p>
        </td>
      </tr>

      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.bankSwiftCode") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.SwitftCode"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.SwitftCode }}</p>
        </td>
      </tr>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.bankEftCode") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.EFTCode"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.EFTCode }}</p>
        </td>
      </tr>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.bankBranchName") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.BranchName"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.BranchName }}</p>
        </td>
      </tr>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.sortCode") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.BranchCode"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.BranchCode }}</p>
        </td>
      </tr>
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          class="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {{ $t("user.payment.transferTab.bankAddress") }}
        </th>
        <td class="py-4">
          <input
            v-if="isEditable"
            v-model="wireData.BankAddress"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          />
          <p v-else>{{ wireData.BankAddress }}</p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "CongressWireInformation",
  props: {
    wireInformation: undefined,
    isEditable: Boolean,
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.wireInformation) {
        this.wireData = this.wireInformation;
      }
    }
  },
  data() {
    return {
      wireData: {},
    }
  },
  watch: {
    wireInformation: function (data) {
      this.wireData = data
    },
  },
};
</script>
