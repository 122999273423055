<template>
  <div role="status">
    <CustomIcon name="spinner-icon" />
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
  import CustomIcon from '@/components/Icon/CustomIcon.vue'
export default {
  name: 'SpinnerLoader',
  components: {CustomIcon}
}
</script>