<template>
  <div class="order">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <template v-if="order.items.length">
        <content-wrapper>
          <order-action :search="search" :payload="payload" />
          <table-wrapper-with-loader :isLoading="isTableLoading">
            <template v-if="downXl">
              <order-table-list
                :order="order"
                :payload="payload"
                :pageSize="payload.pageSize"
              />
            </template>
            <template v-else>
              <order-accordion-list
                :order="order"
                :payload="payload"
                :pageSize="payload.pageSize"
              />
            </template>
          </table-wrapper-with-loader>
          <order-pagination
            v-if="order.items"
            :order="order"
            :changePage="changePage"
          />
        </content-wrapper>
      </template>
      <template v-else>
        <content-wrapper>
          <order-action :search="search" :payload="payload" />
        </content-wrapper>
        <no-data externalClass="relative pt-12">
          <div class="text-2xl font-bold mb-2 text-gray-900">
            {{ $t('shared.noData') }}
          </div>
          <div class="text-sm font-normal text-gray-500">
            {{ $t('shared.noDataDescription') }}
          </div>
        </no-data>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import { useMediaQuery, useToast, useResponseToast } from '@/common/hooks'
import { service } from '@/common/services'
import moment from 'moment'
import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  TableWrapperWithLoader,
  ContentWrapper,
  NoData,
} from '@/components'
import OrderAction from './OrderAction.vue'
import OrderTableList from './OrderTableList.vue'
import OrderAccordionList from './OrderAccordionList.vue'
import OrderPagination from './OrderPagination.vue'

export default {
  name: 'OrderPage',
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    TableWrapperWithLoader,
    ContentWrapper,
    NoData,
    OrderAction,
    OrderTableList,
    OrderAccordionList,
    OrderPagination,
  },
  data() {
    return {
      moment: moment,
      breadcrumbList: [{ name: this.$t('sidebar.myOrder'), key: 'sidebar.myOrder' }],
      pageTitle: this.$t('sidebar.myOrder'),
      order: Object,
      isLoading: true,
      isTableLoading: false,
      payload: {
        searchText: '',
        pageNumber: 1,
        pageSize: 10,
        sortColumn: '',
        sortDescending: true,
      },
    }
  },
  setup() {
    const { downXl } = useMediaQuery()
    const toast = useToast()
    return { toast, downXl }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.myOrder'),
      })),
    )
  },
  mounted() {
    this.dispatchOrderListByCurrentUser(this.payload)
  },
  watch: {
    order: function (data) {
      return (this.order = data)
    },
  },
  methods: {
    dispatchOrderListByCurrentUser(payload) {
      this.isTableLoading = true
      service
        .dispatch('order/list-by-current-user', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              if (response.items.length) {
                this.order = response
              } else {
                this.order = []
              }
            },
            errorCallback: () => {
              response.messages.map((message) => {
                if (message.code === 'Data is empty') {
                  this.payload = { ...payload }
                  this.order.items = []
                }
              })
            },
            isSaveMessage: false,
            noToastMessageCodes: ['Data is empty'],
          })
        })
        .finally(() => {
          this.isLoading = false
          this.isTableLoading = false
        })
    },
    changePage(pageNumber) {
      this.payload = { ...this.payload, pageNumber }
      this.dispatchOrderListByCurrentUser(this.payload)
    },
    search(searchText) {
      this.payload = { ...this.payload, searchText }
      this.dispatchOrderListByCurrentUser(this.payload)
    },
  },
}
</script>

<style scoped lang="scss">
.order {
  @apply h-full;
}
</style>
