<template>
  <div class="flex flex-col gap-2 items-center mb-4">
    <template v-if="!order.isPaymentApproved">
      <ExclamationCircleIcon class="w-16 h-16 text-yellow-500" />
      <h2 class="text-2xl mb-4" v-if="order.documentPath !== ''"> {{ $t('user.order.approvedDescription') }} </h2>
      <h2 class="text-2xl mb-4" v-if="order.documentPath == ''"> {{ $t('user.order.approvedMissingReceiptDescription') }} </h2>
      <button-with-loader :isLoading="isLoading" type="button" @click="updateOrderApprovePayment"
        class="w-[220px] inline-flex gap-1 justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        <CheckCircleIcon class="w-5 h-5 text-white" />


        <span>{{ $t('user.order.approve') }}</span>
      </button-with-loader>
    </template>
    <template v-if="order.isPaymentApproved">
      <CheckCircleIcon class="w-16 h-16 text-green-500" />
      <h2 class="text-2xl mb-4">
        {{ $t('user.order.approvedSuccess') }}
        {{ moment(order.paymentApproveDate).format('DD.MM.YYYY') }}
      </h2>
    </template>
  </div>
</template>

<script>
import { service } from '@/common/services'
import { useResponseToast, useToast } from '@/common/hooks'
import { ButtonWithLoader } from '@/components'
import moment from 'moment'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'OrderDetailAction',
  components: {
    ExclamationCircleIcon,
    CheckCircleIcon,
    ButtonWithLoader,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      moment: moment,
      documentPath: null,
      fileName: null,
      isLoading: false,
      orderId: this.$route.params.id,
    }
  },
  props: {
    fetchOrderById: Function,
    order: {},
  },
  methods: {
    updateOrderApprovePayment() {
      this.isLoading = true
      const payload = { orderId: this.orderId }
      service
        .update('order/approve-payment', payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.fetchOrderById()
            },
            errorCallback: () => { },
            isSaveMessage: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.file-upload {
  &::-webkit-file-upload-button {
    @apply bg-blue-700;
  }
}
</style>
