<template>
  <form>
    <div v-if="downXl" class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-1/2 lg:pr-2">
        <div class="flex mb-6">
          <div class="w-36 pr-4">
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              {{ $t('user.profile.socialTitle') }}
            </label>
            <input
              type="text"
              id="title"
              v-model="formData.title"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div class="flex-auto">
            <label
              for="firstName"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              {{ $t('user.profile.firstName') }}
            </label>
            <input
              type="text"
              id="firstName"
              v-model="formData.firstName"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="address"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.address') }}
          </label>
          <input
            type="text"
            id="address"
            v-model="formData.address"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div class="flex-auto mb-6">
          <label
            for="countryId"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.country') }}
          </label>
          <select
            id="countryId"
            v-model="formData.countryId"
            @change="fetchStateById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseCountry') }}
            </option>
            <option v-for="item in countryList" :value="item.id" :key="item">
              {{ getLang === 'TR' ? item.translations.tr : item.name }}
            </option>
          </select>
        </div>
        <div class="flex-auto mb-6">
          <template v-if="countryCode === 'TR'">
            <label
              for="stateId"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              {{ $t('user.profile.city') }}
            </label>
          </template>
          <template v-else>
            <label
              for="stateId"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              {{ $t('user.profile.state') }}
            </label>
          </template>
          <select
            id="stateId"
            v-model="formData.stateId"
            @change="fetchCityById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="null" selected disabled>
              {{
                countryCode !== 'TR'
                  ? $t('user.profile.chooseState')
                  : $t('user.profile.chooseCity')
              }}
            </option>
            <option v-for="item in stateList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div v-if="countryCode !== 'TR'" class="flex-auto mb-6">
          <label
            for="cityId"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.city') }}
          </label>
          <select
            id="cityId"
            v-model="formData.cityId"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseCity') }}
            </option>
            <option v-for="item in cityList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-1/2 lg:pl-2">
        <div class="flex-auto mb-6">
          <label
            for="lastName"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.lastName') }}
          </label>
          <input
            type="text"
            id="lastName"
            v-model="formData.lastName"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div class="flex-auto mb-6">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.email') }}
          </label>
          <input
            type="text"
            id="email"
            v-model="formData.email"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div class="flex-auto mb-6">
          <label
            for="phoneNumber"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.phoneNumber') }}
          </label>
          <input
            type="text"
            id="phoneNumber"
            v-maska="'0(5##) ### ## ##'"
            v-model="formData.phoneNumber"
            placeholder="0(599) 999 99 99"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div class="flex-auto mb-6">
          <label
            for="zipCode"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.postalCode') }}
          </label>
          <input
            type="text"
            id="zipCode"
            v-model="formData.zipCode"
            v-maska="'#####'"
            placeholder="34000"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col lg:flex-row">
      <div class="w-full">
        <div class="flex mb-6">
          <div class="w-36 pr-4">
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              {{ $t('user.profile.socialTitle') }}
            </label>
            <input
              type="text"
              id="title"
              v-model="formData.title"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div class="flex-auto">
            <label
              for="firstName"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              {{ $t('user.profile.firstName') }}
            </label>
            <input
              type="text"
              id="firstName"
              v-model="formData.firstName"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/2 lg:pl-2">
        <div class="flex-auto mb-6">
          <label
            for="lastName"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.lastName') }}
          </label>
          <input
            type="text"
            id="lastName"
            v-model="formData.lastName"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div class="flex-auto mb-6">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.email') }}
          </label>
          <input
            type="text"
            id="email"
            v-model="formData.email"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div class="flex-auto mb-6">
          <label
            for="phoneNumber"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.phoneNumber') }}
          </label>
          <input
            type="text"
            id="phoneNumber"
            v-maska="'0(5##) ### ## ##'"
            v-model="formData.phoneNumber"
            placeholder="0(599) 999 99 99"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>
      <div class="w-full">
        <div class="flex-auto mb-6">
          <label
            for="address"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.address') }}
          </label>
          <input
            type="text"
            id="address"
            v-model="formData.address"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div class="flex-auto mb-6">
          <label
            for="countryId"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.country') }}
          </label>
          <select
            id="countryId"
            v-model="formData.countryId"
            @change="fetchStateById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseCountry') }}
            </option>
            <option v-for="item in countryList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="stateId"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.state') }}
          </label>
          <select
            id="stateId"
            v-model="formData.stateId"
            @change="fetchCityById"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseState') }}
            </option>
            <option v-for="item in stateList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="cityId"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.city') }}
          </label>
          <select
            id="cityId"
            v-model="formData.cityId"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="null" selected disabled>
              {{ $t('user.profile.chooseCity') }}
            </option>
            <option v-for="item in cityList" :value="item.id" :key="item">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="flex-auto mb-6">
          <label
            for="zipCode"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            {{ $t('user.profile.postalCode') }}
          </label>
          <input
            type="text"
            id="zipCode"
            v-model="formData.zipCode"
            v-maska="'#####'"
            placeholder="34000"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button-with-loader
        :isLoading="isLoading"
        type="button"
        @click="dispatchAccountSub"
        class="w-32 inline-flex gap-1 items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        <CustomIcon name="save-icon" />
        {{ $t('shared.save') }}
      </button-with-loader>
    </div>
  </form>
</template>

<script>
import { useResponseToast, useToast, useMediaQuery } from '@/common/hooks'
import { service } from '@/common/services'
import { mapGetters } from 'vuex'
import { ButtonWithLoader, CustomIcon } from '@/components'

export default {
  name: 'AccountSubForm',
  components: { ButtonWithLoader, CustomIcon },
  setup() {
    const { downXl } = useMediaQuery()
    const toast = useToast()
    return { toast, downXl }
  },
  data() {
    return {
      formData: {
        invoiceCountryId: null,
        invoiceCityId: null,
        invoiceStateId: null,
        cityId: null,
        countryId: null,
        stateId: null,
      },
      countryList: [],
      cityList: [],
      stateList: [],
      isAgreement: false,
      isLoading: false,
      countryCode: '',
    }
  },
  props: {
    fetchAccountSubCurrentUser: Function,
    selectAccount: Function,
    closeModal: Function,
  },
  mounted() {
    this.fetchCountry()
  },
  methods: {
    dispatchAccountSub() {
      if (this.validateEmail(this.formData.email)) {
        this.isLoading = true
        const payload = {
          inputModel: {
            ...this.formData,
            accountId: this.getAccountCurrent.item.id,
          },
        }
        service
          .dispatch('accountSub', payload)
          .then((response) => {
            useResponseToast({
              response: response,
              successCallback: () => {
                this.fetchAccountSubCurrentUser()
                this.selectAccount(response.id).then(() => {
                  this.closeModal()
                })
              },
              errorCallback: () => {},
              isSaveMessage: true,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    fetchCountry() {
      service.fetch('country').then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.countryList =
              this.getLang === 'TR'
                ? response.items.sort((a, b) =>
                    a.translations.tr.localeCompare(b.translations.tr),
                  )
                : response.items
            this.formData.stateId && this.fetchStateById()
            this.formData.cityId && this.fetchCityById()
          },
          errorCallback: () => {},
          isSaveMessage: false,
        })
      })
    },
    fetchStateById() {
      service.fetch(`state/${this.formData.countryId}`).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.stateList = response.items

            if (response.items.length > 0) {
              this.countryCode = response.items[0].countryCode
              this.formData.stateId = response.items[0].id
            }

            this.formData.stateId && this.fetchCityById()
          },
          errorCallback: () => {},
          isSaveMessage: false,
        })
      })
    },
    fetchCityById() {
      service
        .fetch(`city/${this.formData.stateId}/by-state`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.cityList = response.items
              if (response.items.length > 0) {
                this.formData.cityId = response.items[0].id
              }
            },
            errorCallback: () => {},
            isSaveMessage: false,
          })
        })
    },
    validateEmail(email) {
      if (email !== null) {
        /* eslint-disable-next-line */
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true
        this.toast.error(this.$t('shared.validateEmail'))
        return false
      }
    },
  },
  computed: {
    ...mapGetters(['getAccountCurrent', 'getLang']),
  },
}
</script>
