<template>
    <wrapper-with-loader :isLoading="!this.isLoaded" :style="!this.isLoaded ? ' left: 14ch; height: 300px;' : ''">
        <!--acountId : {{ accountId }}-->
        <div v-if="getBasket && this.filteredByAccount.length > 0">
            <div class="flex gap-4 flex-wrap my-7" v-if="this.filteredByAccount != []">
                <content-wrapper v-for="item in this.filteredByAccount" :key="item">
                    <div class="flex flex-col">
                        <div class="text-2xl font-semibold truncate text-gray-500 mb-6">
                            <span v-if="item.productType === 1">
                                {{ $t("admin.congressDetail.inside") }}
                            </span>
                            <span v-if="item.productType === 2">
                                {{ $t("admin.congressDetail.outside") }}
                            </span>
                            <span v-if="item.productType === 3">
                                {{ $t("admin.congressDetail.accommodation") }}
                            </span>
                            <span v-if="item.productType === 4">
                                {{ $t("admin.congressDetail.transfer") }}
                            </span>
                            <span v-if="item.productType === 5">
                                {{ $t("admin.congressDetail.course") }}
                            </span>
                        </div>
                        <div class="flex flex-col mb-4">
                            <label
                                class="relative border-2 border-blue-600 rounded-xl p-4 lg:p-6 flex flex-col lg:flex-row items-center justify-between mb-4 last:mb-0">
                                <div class="flex flex-col flex-1 ml-4 font-normal truncate text-gray-500"><span
                                        class="text-base lg:text-lg">{{ (getLang === 'TR') ? item.name : item.nameEn
                                        }}</span>
                                    <div class="text-xl font-extrabold text-gray-900">
                                        {{ toCurrency(item.price, "tr-TR", "currency",
                                            getCongressProductCurrency(congress.productCurrency)) }}
                                    </div>
                                    <div class="text-base">
                                        <span>{{ $t('user.basket.salePrice') }} </span>
                                        <span class="text-lg font-bold pl-2">
                                            {{ toCurrency(item.salePrice, "tr-TR", "currency",
                                                getCongressProductCurrency(congress.productCurrency)) }}</span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div>
                        <button @click="setCurrentStepFromTypeId(item.productType)" type="button"
                            class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-yellow-400 hover:bg-yellow-700 focus:ring-blue-300 disabled:bg-gray-200">
                            <PencilIcon class="h-5 w-5 text-white font-bold" />
                            {{ $t('wizard.order.changePlan') }}
                        </button>
                    </div>

                </content-wrapper>
            </div>

            <div class="flex flex-col p-4">
                <div></div>
            </div>

            <step-final-navigation :navigateBefore="navigateBefore"
                :navigateStart="this.newStep"></step-final-navigation>

        </div>
        <div v-else>
            <content-wrapper>
                <div class="flex flex-col items-center justify-center h-96">
                    <div class="text-2xl font-semibold text-gray-500 mb-6">
                        {{ $t('user.basket.emptyBasket') }}
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <button @click="setCurrentStepFromTypeId(0)" type="button"
                            class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white bg-yellow-400 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-gray-200">
                            <PencilIcon class="h-5 w-5 text-white font-bold" />
                            {{ $t('wizard.order.navigation.final.before') }}
                        </button>
                    </div>
                </div>
            </content-wrapper>
        </div>
    </wrapper-with-loader>
</template>
<script>

import {
    ContentWrapper,
    WrapperWithLoader,
} from '@/components'

import { mapGetters, mapActions } from 'vuex'
import { toCurrency } from "@/common/utils";
import StepFinalNavigation from "./StepFinalNavigation";
import { PencilIcon } from '@heroicons/vue/24/outline'
import { service } from '@/common/services'
import { useResponseToast } from '@/common/hooks'

export default {
    name: "StepFinal",
    components: {
        ContentWrapper,
        StepFinalNavigation,
        WrapperWithLoader,
        PencilIcon,
    },
    props: {
        step: Object,
        navigateBefore: Function,
        setCurrentStepFromTypeId: Function,
        accountId: String,
        congress: {},
    },
    data() {
        return {
            currentUserEmail: '',
            currentUsers: [],
            filteredByAccount: [],
            isLoaded: false,
        }
    },
    watch: {
        getBasket: function (data) {
            this.isWatchGetBasketLoaded = false
            return data
        },
    },
    computed: {
        ...mapGetters(['getBasket', 'getLang']),
    },
    methods: {
        ...mapActions(['fetchBasket']),
        filterByAccount() {
            if (this.currentUserEmail != '') {
                this.filteredByAccount = this.getBasket.items.filter(item => item.email === this.currentUserEmail)
            }
            else {
                let currentUsersMailArray = this.currentUsers.map(item => item.email);
                let filterNotCurrentUser = this.getBasket.items.filter(item => !currentUsersMailArray.includes(item.email));
                this.filteredByAccount = filterNotCurrentUser;
            }
        },
        newStep() {
            this.setCurrentStepFromTypeId(0)
        },
        fetchAccountSubCurrentUser() {
            service
                .fetch('accountSub/current-user')
                .then((response) => {
                    useResponseToast({
                        response: response,
                        successCallback: () => {
                            this.currentUsers = response.items;
                            if (this.accountId != null) {
                                this.currentUserEmail = this.currentUsers.find(item => item.id === this.accountId).email;
                            }
                        },
                        errorCallback: () => { },
                        isSaveMessage: false,
                    })
                })
                .finally(() => {
                    this.filterByAccount();
                    this.isLoaded = true;
                })
        },
        toCurrency,
        getCongressProductCurrency(productCurrency) {
            let _currency;
            switch (productCurrency) {
                case 1:
                    _currency = "EUR";
                    break;
                case 2:
                    _currency = "USD";
                    break;
                default:
                    _currency = "TRY";
                    break;
            }
            return _currency;
        },
    },
    created() {
        this.fetchBasket().then(() => {
            this.fetchAccountSubCurrentUser();
        });
    },
}
</script>