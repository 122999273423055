<template>
    <div v-if="isLoading" class="flex justify-center items-center absolute w-full h-full">
      <spinner-loader></spinner-loader>
    </div>
    <template v-else>
      <slot></slot>
    </template>
  </template>
  
  <script>
  import SpinnerLoader from "@/components/Loader/SpinnerLoader.vue";
  export default {
    name: 'WrapperWithLoader',
    components: {
      SpinnerLoader
    },
    props:{
      isLoading:Boolean
    }
  }
  </script>