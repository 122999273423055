<template>
  <div
    :class="externalClass"
    class="plan-group p-2 lg:p-4 relative overflow-x-auto bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'ContentWrapper',
    props:{
      externalClass:{}
    }
  }
  </script>
