<template>
  <div class="bg-gray-100 p-5 flex justify-between items-center text-xs">
    <div class="min-w-[100px]">
      {{ $t("admin.congressDetail.productType") }}
    </div>
    <div class="flex-1 truncate">
      {{ $t("admin.congressDetail.name") }} -
      {{ $t("admin.congressDetail.participantType") }}
    </div>
    <div class="w-5"></div>
  </div>
  <div v-for="item in congressDetail" :key="item">
    <div
      :id="`accordion-${item.id}-header`"
      class="accordion-header flex border-b-2 p-5 bg-white dark:bg-gray-800"
    >
      <div :aria-controls="`accordion-${item.id}-header`" class="w-full">
        <div class="flex justify-between items-center text-xs">
          <div class="w-[100px] min-w-[100px] sm:w-auto truncate">
            <span v-if="item.productType === 1">
              {{ $t("admin.congressDetail.inside") }}
            </span>
            <span v-if="item.productType === 2">
              {{ $t("admin.congressDetail.outside") }}
            </span>
            <span v-if="item.productType === 3">
              {{ $t("admin.congressDetail.accommodation") }}
            </span>
            <span v-if="item.productType === 4">
              {{ $t("admin.congressDetail.transfer") }}
            </span>
            <span v-if="item.productType === 5">
              {{ $t("admin.congressDetail.course") }}
            </span>
          </div>
          <div class="flex-1 truncate">
            <span v-if="getLang === 'TR'">
              {{ item.name }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.nameEn }}
            </span>
            <span> - </span>
            <span v-if="getLang === 'TR'">
              {{ item.participantType }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.participantTypeEn }}
            </span>
          </div>
          <div class="w-5">
            <div class="up-icon">
              <ChevronUpIcon class="h-6 w-6 text-blue-500" />
            </div>
            <div class="down-icon">
              <ChevronDownIcon class="h-6 w-6 text-blue-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="`accordion-${item.id}-body`"
      class="hidden"
      :aria-labelledby="`accordion-${item.id}-body`"
    >
      <div
        class="p-5 text-sm bg-gray-200 border-gray-200 dark:border-gray-700 dark:bg-gray-900"
      >
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t("admin.congressDetail.price") }}:
          </span>
          <span class="text-gray-900">
            {{
              toCurrency(
                item.price,
                "tr-TR",
                "currency",
                getCongressProductCurrency(item.productCurrency)
              )
            }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t("admin.congressDetail.vatRate") }}:
          </span>
          <span class="text-gray-900">%{{ item.vatRate }}</span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t("admin.congressDetail.startDate") }}:
          </span>
          <span class="text-gray-900">
            {{ moment(item.startDate).format("YYYY-MM-DD") }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t("admin.congressDetail.endDate") }}:
          </span>
          <span class="text-gray-900">
            {{ moment(item.endDate).format("YYYY-MM-DD") }}
          </span>
        </div>
        <div class="mb-2">
          <span class="pr-1 font-medium text-gray-900">
            {{ $t("admin.congressDetail.lastRegistrationDate") }}:
          </span>
          <span class="text-gray-900">
            {{ moment(item.lastRegistrationDate).format("YYYY-MM-DD") }}
          </span>
        </div>
        <div class="flex mb-2">
          <a
            @click="duplicateItem(item.id)"
            class="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t("shared.duplicate") }}
          </a>
          <span class="px-2">|</span>
          <a
            @click="openModal(item.id)"
            class="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t("shared.edit") }}
          </a>
          <span class="px-2">|</span>
          <a
            @click="deleteCongressDetail(item.id)"
            class="cursor-pointer flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {{ $t("shared.delete") }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    id="edit-congress-detail-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
  >
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <!-- Modal content -->
      <div
        class="modal-scroll relative bg-white rounded-lg shadow h-full md:h-[650px] overflow-auto dark:bg-gray-700"
      >
        <!-- Modal header -->
        <div
          class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600"
        >
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ $t("admin.congressDetail.editCongressDetail") }}
          </h3>
          <button
            type="button"
            @click="closeModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <XMarkIcon class="w-5 h-5" />
          </button>
        </div>
        <!-- Modal body -->
        <div v-if="formData" class="p-6 space-y-6">
          <congress-detail-edit-form
            :propFormData="formData"
            :dispatchCongressDetailListByFilter="
              dispatchCongressDetailListByFilter
            "
            :detailId="detailId"
            :closeModal="closeModal"
            :payload="payload"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import moment from "moment";
import { useResponseToast, useToast } from "@/common/hooks";
import { service } from "@/common/services";
import CongressDetailEditForm from "./CongressDetailEditForm";
import { toCurrency } from "@/common/utils";

export default {
  name: "CongressDetailAccordionList",
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    XMarkIcon,
    CongressDetailEditForm,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      moment: moment,
      modal: null,
      formData: {},
      detailId: null,
      accordion: null,
    };
  },
  mounted() {
    this.initModal();
    this.initAccordion(this.congressDetail);
  },
  props: {
    congressDetail: Object,
    dispatchCongressDetailListByFilter: Function,
    payload: {},
  },
  methods: {
    deleteCongressDetail(id) {
      service.delete("congres-detail", id).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.dispatchCongressDetailListByFilter(this.payload);
          },
          errorCallback: () => {},
          isSaveMessage: true,
        });
      });
    },
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    duplicateItem(id) {
      const payload = {
        congressId: this.$route.params.id,
        congressDetailId: id,
      };
      service
        .dispatch("congress-detail/duplicate", payload)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.dispatchCongressDetailListByFilter(this.payload);
            },
            errorCallback: () => {},
            isSaveMessage: true,
          });
        });
    },
    fetchCongressDetailById(id) {
      service
        .fetch(`congress-detail/${id}/detail`)
        .then((response) => {
          this.detailId = id;
          this.formData = response;
        })
        .catch((err) => {
          console.debug("ERROR: ", err);
        });
    },
    openModal(id) {
      this.fetchCongressDetailById(id);
      this.modal.show();
    },
    closeModal() {
      this.modal.hide();
    },
    initAccordion(items) {
      let accordionItems = [];
      if (
        items !== undefined &&
        document.querySelector(".accordion-header") !== null
      ) {
        items.map((item, index) => {
          if (index === 0) {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: true,
            });
          } else {
            accordionItems.push({
              id: `accordion-${item.id}`,
              triggerEl: document.querySelector(`#accordion-${item.id}-header`),
              targetEl: document.querySelector(`#accordion-${item.id}-body`),
              active: false,
            });
          }
        });
        const options = {
          alwaysOpen: false,
          activeClasses: "text-gray-900 dark:text-white",
          inactiveClasses: "text-gray-900 dark:text-white",
        };
        // eslint-disable-next-line
        this.accordion = new Accordion(accordionItems, options);
      }
    },
    initModal() {
      const modalEl = document.getElementById("edit-congress-detail-modal");
      const modalOptions = {
        placement: "center-center",
        backdropClasses:
          "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        onShow: () => {
          document.querySelector(
            "#edit-congress-detail-modal .modal-scroll"
          ).scrollTop = 0;
        },
      };
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions);
      }
    },
    toCurrency,
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped lang="scss">
.accordion-header[aria-expanded="true"] {
  .up-icon {
    @apply block;
  }

  .down-icon {
    @apply hidden;
  }
}

.accordion-header[aria-expanded="false"] {
  .up-icon {
    @apply hidden;
  }

  .down-icon {
    @apply block;
  }
}
</style>
