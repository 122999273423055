<template>
    <component v-bind:is="`${step.tag}`" :participantTypes="participantTypes" :congress="congress"
        :dispatchBasket="dispatchBasket" :selectedAccount="selectedAccount"></component>

    <!-- <content-wrapper>
        <p>Step Key : {{ this.step.key }}</p>
        <p>Step Value : {{ this.step.value }}</p>
        <p>Step Tag : {{ this.step.tag }}</p>
    </content-wrapper> -->
</template>

<script>
import Accommodation from "@/pages/Congress/Accommodation.vue";

import {
    ContentWrapper,
} from '@/components'

export default {
    name: "StepAccommodation",
    components: {
        ContentWrapper,
        Accommodation,
    },
    props: {
        step: Object,
        dispatchBasket: Function,
        participantTypes: Array,
        congress: Object,
        selectedAccount: Object,
    }
}
</script>